import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(value: number[], curentPage: number, paginationLength: number): number[] {
    --paginationLength;
    const data = [];
    let start = 1;
    let end = value.length;
    if (paginationLength < value.length) {
      if (curentPage + Math.ceil(paginationLength / 2) < value.length) {
        start = curentPage - Math.ceil(paginationLength / 2) < 1 ? 1 :  curentPage - Math.ceil(paginationLength / 2);
        end = start + paginationLength;
      } else {
        start = end - paginationLength;
      }
    }

    for (let i = start; i <= end; i++) {
      data.push(i);
    }
    return data;
  }

}
