import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EsignModalService {
  public triggerEsignModal = new Subject<any>();

  constructor() {
  }

}
