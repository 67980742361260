import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'trim'})
export class TrimPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    if (value && value.length > +args) {
      value = value.toString().trim().slice(0, +args) + '...';
    }
    return value;
  }
}
