import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { filter, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { IFormInfo } from 'src/app/providers/_models/entity.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() isEnabled: boolean;

  public options = [];

  constructor(private dictionaryService: DictionaryService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formsInfo && this.formsInfo && changes.key && this.key) {
      const info = this.formsInfo.controlsInfo[this.key];
      const dictKey = info.dictKey || this.key;

      if (this.dictionaryService[dictKey]) {
        this.dictionaryService[dictKey]
          .pipe(
            filter(x => !!x),
            takeUntil(componentDestroyed(this)),
          )
          .subscribe(res => {
            this.options = (res || []).slice();

            if (info.selectClearItem) {
              this.options.unshift({
                ...info.selectClearItem,
              });
            }

            if (info && info.initFilter) {
              const fGroup = this.fGroup || this.formsInfo.formGroup;
              this.options = this.options.filter(opt => info.initFilter(fGroup, opt));
            }

            this.cdr.detectChanges();
          });
      }
    }
  }
}
