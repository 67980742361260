import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingSpinnerService } from './loading.spinner.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading.spinner.component.html',
  styleUrls: ['./loading.spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  public loading = false;

  constructor(private loadingSpinnerService: LoadingSpinnerService) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.loadingSpinnerService.loadingStatus$.pipe(takeUntil(componentDestroyed(this))).subscribe(data => (this.loading = data));
  }
}
