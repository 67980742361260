import { BreadcrumbsComponentModule } from 'src/app/modules/breadcrumbs/breadcrumbs.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponentModule } from 'src/app/modules/footer/footer.component.module';
import { HeaderComponentModule } from 'src/app/modules/header/header.component.module';
import { OperatorsComponent } from './operators.component';
import { OperatorsPageComponentModule } from 'src/app/modules/operators.page/operators.page.component.module';

@NgModule({
  declarations: [
    OperatorsComponent
  ],
  imports: [
    CommonModule,
    FooterComponentModule,
    HeaderComponentModule,
    BreadcrumbsComponentModule,
    OperatorsPageComponentModule
  ],
  exports: [
    OperatorsComponent
  ]
})
export class OperatorsComponentModule {
}
