import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ITokenInfo, ERoles } from '../../providers/_models/TokenInfo';
import { tap, finalize, catchError, switchMap, filter, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { IRegistrationRequest, IRegistrationResponse } from '../pages/registration/registration.component';
import { resetStores } from '@datorama/akita';
import { IUserInfo } from 'src/app/interfaces/user/user.interface';
import { CookieService } from 'ngx-cookie-service';

const userInfoStorageKey = 'user_info';
const adminEmail = 'admin@localhost';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  ERoles = ERoles;
  globalContainer = [];

  public changeEditMode = new BehaviorSubject(false);

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) { }

  public login(email: string, password: string): Observable<any> {
    return this.http.post<ITokenInfo>('/api/auth/login', { email, password });
  }

  public logout(esiaLogout?: boolean): Observable<any> {
    return this.http.post(`/api/auth/logout${esiaLogout ? '?with_esia=true' : ''}`, {}).pipe(
      tap((res: any) => {
        if (res.redirect) {
          window.open(res.redirect, '_blank');
        }
        localStorage.removeItem(userInfoStorageKey);
        localStorage.removeItem('current_org');
        resetStores();
      }),
      finalize(() => this.router.navigate(['/login'])),
    );
  }

  public getInfoMe(): Observable<any> {
    return this.http.post('/api/auth/me', null).pipe(tap(res => !!res && localStorage.setItem(userInfoStorageKey, JSON.stringify(res))));
  }

  public registration(data: IRegistrationRequest): Observable<IRegistrationResponse> {
    return this.http.post<IRegistrationResponse>('/api/auth/register', data);
  }

  public addOrgInProfile(data: any): Observable<any> {
    return this.http.post<any>('/api/auth/me/org', data).pipe(
      map(({ organization }) => organization),
      tap(res => !!res && localStorage.setItem('current_org', JSON.stringify(res))),
    );
  }

  public getMeOrg(pagination?): Observable<any> {
    let params = new HttpParams();
    if (pagination && Object.keys(pagination).length) {
      for (const filterName in pagination) {
        if (pagination.hasOwnProperty(filterName)) {
          params = params.append(filterName, pagination[filterName]);
        }
      }
    }
    console.log(environment.isPTC);
    if (environment.isPTC === 1) {
      params = params.append('scope[isTerritoryOrg]', 'true');
    }
    return this.http.get('/api/auth/me/orgs', { params });
  }

  public selectOrg(id): Observable<any> {
    return this.http.post('/api/auth/select_org', { id });
  }

  public resetPassword(email): Observable<any> {
    return this.http.post('/api/auth/reset', { email });
  }

  public changePassword(obj): Observable<any> {
    return this.http.post('/api/auth/reset/change', obj);
  }

  getUserInfo(): IUserInfo {
    let userInfo: IUserInfo;

    try {
      userInfo = JSON.parse(localStorage.getItem(userInfoStorageKey));
    } catch (err) { }

    return userInfo;
  }

  isAdmin(): boolean {
    const user = this.getUserInfo();
    return user && (user.email === adminEmail || (user.role_names || []).includes('admin'));
  }
}
