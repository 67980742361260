import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toUTC' })
export class ToUTCPipe implements PipeTransform {
  transform(item: any): any {
    try {
      let value = item
        .replace(/\s/g, 'T')
        .replace('+0000', '')
        .replace('+0300', '')
        .replace('+00', '')
        .replace('+03', '');
      if (value[value.length - 1] !== 'Z') {
        value += 'Z';
      }
      return value;
    } catch (e) { }
    return item;
  }
}
