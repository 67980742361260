import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EClassSize, IControlsInfo, LabelPositionType} from '../../../../providers/_models/entity.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-text-control',
  templateUrl: './text-control.component.html',
  styleUrls: ['./text-control.component.scss']
})
export class TextControlComponent implements OnInit, OnChanges {

  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;
  @Input() fieldSizeClass = 'rpn-input-group__field_md';

  get control() {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  get canEdit(): boolean {

    if (!this.isEnabled) {
      return false;
    }

    if (this.info && this.info.isDisabled && this.fGroup) {
      return !this.info.isDisabled(this.fGroup);
    }

    return true;
  }

  get showError() {
    return this.control && this.control.invalid && this.canEdit;
  }

  public random = Math.random();
  public sizeClass = EClassSize.full;
  public labelPosition = LabelPositionType.top;

  public LabelPositionType = LabelPositionType;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {

    if (simpleChanges.info) {

      this.sizeClass = this.info && this.info.sizeClass || EClassSize.full;
      this.labelPosition = this.info && this.info.labelPosition || LabelPositionType.top;
    }

  }

}
