import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatSelectModule,
  MatInputModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { DatePickerAdapter } from '../providers/_utils/datepicker.adapter';
import { ApplicantAttachComponent } from './applicant.attach/applicant.attach.component';
import { BidStatusComponent } from './bid.status/bid.status.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxControlComponent } from './checkbox/components/checkbox-control/checkbox-control.component';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { ContexMenuHeaderComponent } from './contex-menu-header/contex.menu.header.component';
import { ContextMenuFilterComponent } from './context-menu-filter/context-menu-filter.component';
import { DadataAutocompleteControlComponent } from './dadata.autocomplete/components/dadata-autocomplete-control/dadata-autocomplete-control.component';
import { DadataAutocompleteComponent } from './dadata.autocomplete/dadata.autocomplete.component';
import { DateMonthYearInputComponent } from './date.month.year.input/date.month.year.input.component';
import { DatePickerControlComponent } from './date.picker/components/date-picker-control/date-picker-control.component';
import { DatePickerComponent } from './date.picker/date.picker.component';
import { DialogControlComponent } from './dialog/components/dialog-control/dialog-control.component';
import { DialogComponent } from './dialog/dialog.component';
import { DictionaryAutocompleteComponent } from './dictionary-autocomplete/dictionary-autocomplete.component';
import { DocumentExpirationComponent } from './document.expiration/document.expiration.component';
import { FileUploadComponent } from './file.upload/file.upload.component';
import { FuzzysortComponent } from './fuzzysort/fuzzysort.component';
import { InputControlComponent } from './input/components/input-control/input-control.component';
import { InputComponent } from './input/input.component';
import { ModalComponent } from './modal/modal.component';
import { RadioboxComponent } from './radiobox/radiobox.component';
import { RequisitesComponent } from './requisites/requisites.component';
import { SectionCustomFormComponent } from './section-custom-form/section-custom-form.component';
import { SelectControlComponent } from './select/components/select-control/select-control.component';
import { SelectListFormComponent } from './select/components/select-list-form/select-list-form.component';
import { SelectListComponent } from './select/components/select-list/select-list.component';
import { SelectComponent } from './select/select.component';
import { TextControlComponent } from './text/components/text-control/text-control.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { PipesModule } from '../providers/_pipes/pipes.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { RadioboxControlComponent } from './radiobox/radiobox-control/radiobox-control.component';
import { LabelComponent } from 'src/app/components/label/label.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    CheckboxComponent,
    BidStatusComponent,
    SelectComponent,
    DatePickerComponent,
    TextComponent,
    LabelComponent,
    TextareaComponent,
    ApplicantAttachComponent,
    DadataAutocompleteComponent,
    InputComponent,
    RadioboxComponent,
    RadioboxControlComponent,
    DateMonthYearInputComponent,
    DocumentExpirationComponent,
    FuzzysortComponent,
    ContexMenuHeaderComponent,
    RequisitesComponent,
    InputControlComponent,
    SelectControlComponent,
    TextControlComponent,
    DadataAutocompleteControlComponent,
    CheckboxControlComponent,
    SelectListComponent,
    SelectListFormComponent,
    ClickOutsideDirective,
    DialogComponent,
    DialogControlComponent,
    DatePickerControlComponent,
    DictionaryAutocompleteComponent,
    SectionCustomFormComponent,
    ContextMenuFilterComponent,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatNativeDateModule,
    PipesModule,
    NgxMaskModule,
    NgxUpperCaseDirectiveModule,
  ],
  exports: [
    FileUploadComponent,
    CheckboxComponent,
    BidStatusComponent,
    SelectComponent,
    DatePickerComponent,
    TextComponent,
    LabelComponent,
    TextareaComponent,
    ApplicantAttachComponent,
    DadataAutocompleteComponent,
    InputComponent,
    RadioboxComponent,
    DateMonthYearInputComponent,
    DocumentExpirationComponent,
    FuzzysortComponent,
    ContexMenuHeaderComponent,
    RequisitesComponent,
    SelectControlComponent,
    TextControlComponent,
    InputControlComponent,
    DadataAutocompleteControlComponent,
    CheckboxControlComponent,
    SelectListComponent,
    SelectListFormComponent,
    ClickOutsideDirective,
    DialogComponent,
    DialogControlComponent,
    DatePickerControlComponent,
    DictionaryAutocompleteComponent,
    ContextMenuFilterComponent,
    ModalComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: DateAdapter, useClass: DatePickerAdapter },
  ],
})
export class ComponentsModule {}
