import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PasswordConfirmValidator } from '../../services/pwd_confirm.validator';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-change-password',
    templateUrl: './change.password.component.html',
    styleUrls: ['./change.password.component.scss']
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
  userForm: FormGroup = new FormGroup({
    email: new FormControl('', [
        Validators.required,
        Validators.email
    ]),
    token: new FormControl('', [
      Validators.required,
    ]),
    password: new FormControl('', [
        Validators.required,
    ]),
    password_confirmation: new FormControl('', [
        Validators.required,
    ])
  }, {
      validators: PasswordConfirmValidator
  });

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(componentDestroyed(this))
    ).subscribe(params => {
      this.userForm.controls.email.setValue(params.email);
      this.userForm.controls.token.setValue(params.token);
    });
  }

  public saveNewPassword(): void {
    this.auth.changePassword(this.userForm.value).pipe(
      takeUntil(componentDestroyed(this))
    ).subscribe(
      (res) => {
        if (res && res.message) {
          this.toastService.info('', res.message, { timeOut: 10000 });
          setTimeout(() => this.router.navigate(['/']), 500);
        }
      }
    );
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('user_info');
    localStorage.removeItem('current_org');
  }

  public returnToLogin(): void {
    this.clearLocalStorage();
    this.auth.logout().pipe(takeUntil(componentDestroyed(this))).subscribe();
  }

  public gotoRegistration() {
    this.clearLocalStorage();
    this.router.navigate(['/registration']);
  }
}
