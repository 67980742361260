import {Component, OnInit} from '@angular/core';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  public version = '';

  constructor(
    private svcRestService: SvcRestService
  ) {
  }
  ngOnInit() {
    this.svcRestService.applicationVersion().subscribe(
      (data: any) => {
        this.version = data.version;
      }
    );
  }
}
