import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output, TemplateRef
} from '@angular/core';
import {EKeyCode} from '../../../../interfaces/key-code.interface';
import {IListItem} from '../../../../interfaces/common.interface';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectListComponent implements OnInit, OnChanges {

  @Input() value: number | string;
  @Input() options: IListItem[];
  @Input() selectNgClass: string[];
  @Input() templateOptions: TemplateRef<any>[];

  @Output() changeValue: EventEmitter<IListItem> = new EventEmitter<IListItem>();

  showList: boolean;
  hoverItem = -1;

  get selected(): IListItem {

    if (this.options && this.options.find) {

      return this.options.find(o => '' + o.id === '' + this.value);
    }

    return null;
  }

  get selectClass(): string[] {
    return [
      'rpn-select',
      ...(this.selectNgClass || ['rpn-select_light']),
    ];
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.hoverItem = -1;
  }

  select(item: IListItem) {
    this.changeValue.emit(item);
  }

  hideList() {
    this.showList = false;
    this.hoverItem = -1;
  }

  toggleList(event) {
    this.showList = !this.showList;
    this.hoverItem = -1;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(e) {

    if (!this.showList) {
      return;
    }

    if (e.keyCode === EKeyCode.ENTER && this.options
      && this.hoverItem > -1 && this.hoverItem < this.options.length) {

      this.select(this.options[this.hoverItem]);
      this.hideList();

    } else if (e.keyCode === EKeyCode.ESCAPE) {

      this.hideList();

    } else if (e.keyCode === EKeyCode.DOWN_ARROw && this.options) {

      this.hoverItem++;

      if (this.hoverItem > this.options.length - 1) {
        this.hoverItem = 0;
      }

    } else if (e.keyCode === EKeyCode.UP_ARROW && this.options) {

      this.hoverItem--;

      if (this.hoverItem < 0) {
        this.hoverItem = this.options.length - 1;
      }
    }

  }

}
