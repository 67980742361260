import {Component, OnInit} from '@angular/core';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
})

export class LogOutComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.authenticationService.logout().subscribe();
  }

}
