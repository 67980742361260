import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoadingSpinnerService } from 'src/app/modules/loading-spinner/loading.spinner.service';
import { SignatureService } from 'src/app/providers/_services/signature.service';
import * as CryptoJS from 'crypto-js';

const XContentIdName = '\u0058\u002D\u0043\u006F\u006E\u0074\u0065\u006E\u0074\u002D\u0049\u0064';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private loadingService: LoadingSpinnerService,
    private signatureService: SignatureService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(`${environment.ADDRESS_SUGGEST_URL}`) + 1) {
      return next.handle(
        request.clone({ headers: request.headers.set('Authorization', `Token ${environment.ADDRESS_SUGGEST_Authorization}`) }),
      );
    } else if (request.url.indexOf('map') + 1 || request.url.indexOf('notifications') + 1) {
      return next.handle(request.clone());
    } else {
      this.loadingService.createSpinner();
      return this.nextHandle(request, next);
    }
  }

  private nextHandle(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const needSign = request && request.body ? request.body.needSign : false;
    if (needSign) {
      delete request.body.needSign; // TODO Delete always
    }

    console.log(request.body);

    return ((request.method === 'POST' || request.method === 'PATCH') && needSign
      ? this.signatureService.signByCertificate(request.body).pipe(
          catchError(({ title, message }) => {
            this.toastr.error(message || '', title || '', { timeOut: 6000 });
            return throwError({ title, message });
          }),
        )
      : of(false)
    ).pipe(
      switchMap(sign => {
        const setHeaders = {};
        let body = request.body;

        if (sign) {
          if (!request.headers.has('Content-Type')) {
            if (request.body instanceof FormData) {
              setHeaders['Content-Type'] = 'multipart/form-data-signed';
            } else {
              setHeaders['Content-Type'] = 'application/json-signed';
            }
          } else {
            setHeaders['Content-Type'] = request.headers.get('Content-Type') + '-signed';
          }
          body = sign;
        }

        const timestamp = Date.now();
        const urlWithParams = request.urlWithParams;
        const stringBody = body ? (needSign ? body : JSON.stringify(body)) : '';
        const bodyStringWithSalt: string = stringBody + navigator.userAgent + decodeURI(urlWithParams) + timestamp;
        const sha1 = CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(bodyStringWithSalt));

        setHeaders[XContentIdName] = '' + timestamp + 'a' + sha1;

        const requestJSON: { setHeaders: any; body: any; withCredentials: boolean; setParams?: any } = {
          setHeaders,
          body,
          withCredentials: true,
        };

        return next.handle(request.clone(requestJSON));
      }),
      catchError((error: HttpErrorResponse) => {
        this.loadingService.dismissSpinnerAll();
        if (error.status === 401) {
          localStorage.removeItem('user_info');
          localStorage.removeItem('current_org');
          setTimeout(() => this.router.navigate(['/login']), 500);
          return of(null);
        } else if (error && error instanceof HttpErrorResponse) {
          if (error && error.error) {
            if (error.error.message) {
              this.toastr.error('', error.error.message || '', { timeOut: 6000 });
            }
            // if (error.status === 503) {
            //   setTimeout(() => this.router.navigate(['/page-maintenance']), 500);
            //   return of(null);
            // }
          }
        }
        return throwError(error);
      }),
      finalize(() => {
        this.loadingService.dismissSpinner();
      }),
    );
  }
}
