import { ToastrService } from 'ngx-toastr';
import { SignatureService } from './../../providers/_services/signature.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EsignSelectModalService } from './esign.select.modal.service';

@Component({
  selector: 'app-esign-select-modal',
  templateUrl: './esign.select.modal.component.html',
  styleUrls: ['./esign.select.modal.component.scss']
})
export class EsignSelectModalComponent implements OnInit, AfterViewInit {
  public certificates = [];
  public select;
  public user: any;
  public org: any;
  public loading = true;

  constructor(
    private esignSelectModalService: EsignSelectModalService,
    private signatureService: SignatureService,
    private toastService: ToastrService
  ) {
  }

  ngOnInit(): void {
    try {this.user = JSON.parse(localStorage.getItem('user_info')); } catch (err) {}
    try {this.org = JSON.parse(localStorage.getItem('current_org')); } catch (err) {}
  }

  ngAfterViewInit(): void {
    this.signatureService.selectCertificate = null;
    setTimeout(() => {
      this.signatureService.fetchCertList().then(
        (res: {value: string, label: string}[]) => this.certificates = res
      ).catch(err => this.toastService.error('', err || '', { timeOut: 3000 })).finally(() => this.loading = false);
    }, 2000);
  }

  closeEsignModal(): void {
    this.esignSelectModalService.triggerEsignSelectModal.next(false);
  }

  selectCert(cert) {
    this.select = cert.value;
    this.signatureService.selectCertificate = cert;
  }

  sign() {
    this.esignSelectModalService.triggerEsignSelectModal.next('signed');
  }

}
