import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EClassSize, IFormInfo, LabelPositionType } from 'src/app/providers/_models/entity.model';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { componentDestroyed } from '../../providers/_utils/utils';
import { IDictionaryItem } from 'src/app/interfaces/dictionary.interface';

@Component({
  selector: 'app-fuzzysort',
  templateUrl: './fuzzysort.component.html',
  styleUrls: ['./fuzzysort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuzzysortComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() isEnabled: boolean;
  @Input() inTable: boolean;
  @Input() fGroup: FormGroup;
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public labelPosition = LabelPositionType.top; // left / top / inside /doNot
  public sizeClass = EClassSize.full; // full / half / third
  public select = '';
  public self = this;
  public results: any = [];
  public highlightedArray = [];
  public searchKey;
  public sendKey;
  public autocompleteValue$: Observable<any[]>;
  public dictionary$: Observable<any[]>;
  public searchInput = new FormControl('');
  private sub: Subscription = null;

  constructor(
    private dictionaryService: DictionaryService
  ) { }

  get dictKey() {
    return this.formsInfo.controlsInfo[this.key].dictKey || this.key;
  }

  get formGroup() {
    return this.inTable ? this.fGroup : (this.formsInfo && this.formsInfo.formGroup || null);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.dictionary$ = this.dictionaryService[this.dictKey];

    if (this.formsInfo.controlsInfo[this.key].sizeClass) {
      this.sizeClass = this.formsInfo.controlsInfo[this.key].sizeClass;
    }

    if (this.formsInfo.controlsInfo[this.key].labelPosition) {
      this.labelPosition = this.formsInfo.controlsInfo[this.key].labelPosition;
      if (this.labelPosition === LabelPositionType.inside) {
        this.select = this.formsInfo.controlsInfo[this.key].labelValue;
      }
    }

    if (this.formsInfo.controlsInfo[this.key].searchKey) {
      this.searchKey = this.formsInfo.controlsInfo[this.key].searchKey;
    } else {
      this.sub = this.dictionary$.subscribe(
        dict => {
          if (dict && dict[0]) {
            this.searchKey = dict[0].description
              ? 'description' : dict[0].full_name
                ? 'full_name' : dict[0].name
                  ? 'name' : 'id';
          } else {
            this.searchKey = 'id';
          }
        }
      )
    }

    this.sendKey = this.formsInfo.controlsInfo[this.key].sendKey || 'id';

    this.autocompleteValue$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (value && value[this.searchKey]) {
          this.searchInput.setValue(value[this.searchKey], { emitEvent: false });
          return value[this.searchKey];
        } else {
          return value || '';
        }
      }),
      debounceTime(500),
      distinctUntilChanged(),
      withLatestFrom(this.dictionary$),
      map(([value, dict]) => {
        const items = (dict as any[] || []).filter(
          (i: any) => i[this.searchKey].toLocaleLowerCase().includes(('' + value).toLocaleLowerCase())
        );
        return items.map(i => ({ value: i, highlight: this.highlightText(i[this.searchKey], value) }));
      })
    );

    of(this.formGroup.controls[this.key].value || '').pipe(
      withLatestFrom(this.dictionary$),
    ).subscribe(
      ([value, dict]) => {
        const a = (dict as any[] || []).find(element => value === element[this.sendKey]) || {};
        this.searchInput.setValue(a[this.searchKey] || '');
      }
    )
  }

  public selectItemValue(e: any, dict): void {
    const value = e.option.value;
    const a = dict.find(element => value[this.searchKey] === element[this.searchKey]);
    this.formGroup.controls[this.key].setValue(a ? this.sendKey === 'id' ? +a[this.sendKey] : a[this.sendKey] : null);

    if (this.formsInfo.controlsInfo[this.key].onSelectAutocomplete) {
      this.formsInfo.controlsInfo[this.key].onSelectAutocomplete(this.formGroup, value);
    }
  }

  public resetSelect(): void {
    this.searchInput.setValue('');
    this.formGroup.controls[this.key].setValue('');
    this.formGroup.controls[this.key].markAsDirty();
  }

  /**
   * обрамляет текст тэгом <b>
  */
  private highlightText(text: string, highlight: string): string {
    if (text && text.length) {
      const index = text.toLocaleLowerCase().indexOf(highlight.toLocaleLowerCase());
      const last = index + highlight.length;
      return `<span>${text.slice(0, index)} <b>${text.slice(index, last)}</b> ${text.slice(last, text.length)}</span>`;
    }
  }

  resetValueFromControl(dict): void {
    const value = this.formGroup.controls[this.key].value;
    const a = dict.find(element => value === element[this.sendKey]);
    const b = a && a[this.searchKey] || '';
    this.searchInput.setValue(b);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }

  getValueName(dict: any[]): string {
    const dicItem = dict && dict.length && dict.find(x => x[this.sendKey] === this.formGroup.controls[this.key].value);
    return dicItem && this.searchKey && dicItem[this.searchKey] || '-';
  }
}
