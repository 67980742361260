import { Component, OnInit, OnDestroy, SimpleChanges, OnChanges, HostListener } from '@angular/core';
import { HeaderService } from 'src/app/providers/_services/header.service';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { ButtonOnBottom } from 'src/app/providers/_models/entity.model';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { CitesSectionName } from 'src/app/sections/bid/cites.edit/cites.form.models';

@Component({
  selector: 'app-contex-menu-header',
  templateUrl: './contex.menu.header.component.html',
  styleUrls: ['./contex.menu.header.component.scss']
})
export class ContexMenuHeaderComponent implements OnInit, OnChanges, OnDestroy {
  SectionName = CitesSectionName;
  isEditabled: boolean;
  select = '';
  public buttonOnBottom = [];
  // @HostListener('document:scroll', ['$event']) doSomething(event) {
  //   this.select = '';
  // }
  constructor(
    private headerService: HeaderService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnDestroy(): void {}

  ngOnInit(): void {
   this.authenticationService.changeEditMode.pipe(takeUntil(componentDestroyed(this))).subscribe(
     (res) =>  this.isEditabled = res
   );
   this.headerService.buttonOnBottom.pipe(takeUntil(componentDestroyed(this))).subscribe(
     (res) => this.buttonOnBottom = res
   );
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  scrollToElement(section: string): void {
    this.select = section;
    switch (section) {
      case ('main') : {
        window.scrollTo(0, 80);
        break;
      }
      case (this.SectionName.applicantInformation) : {}
      // tslint:disable-next-line: no-switch-case-fall-through
      case (this.SectionName.serviceReceivingInformation) : {}
      // tslint:disable-next-line: no-switch-case-fall-through
      case (this.SectionName.serviceReceivingDocumentMethod) : {
        this.headerService.openSection.next(section);
        this.scrollTo(section, -150);
        break;
      }
      case ('importer') : {
        this.headerService.openSection.next(this.SectionName.serviceReceivingInformation);
        this.scrollTo(this.SectionName.serviceReceivingInformation, -50);
        break;
      }
      case ('exporter') : {
        this.headerService.openSection.next(this.SectionName.serviceReceivingInformation);
        this.scrollTo(this.SectionName.serviceReceivingInformation, 450 + (this.isEditabled ? 40 : 0));
        break;
      }
      case ('conditions') : {
        this.headerService.openSection.next(this.SectionName.serviceReceivingInformation);
        this.scrollTo(this.SectionName.serviceReceivingInformation, 970 + (this.isEditabled ? 110 : 0));
        break;
      }
      case ('objects') : {
        this.headerService.openSection.next(this.SectionName.serviceReceivingInformation);
        this.scrollTo(this.SectionName.serviceReceivingInformation, 1150 + (this.isEditabled ? 170 : 0));
        break;
      }
      case ('appendix') : {
        this.headerService.openSection.next(this.SectionName.serviceReceivingInformation);
        this.scrollTo(this.SectionName.serviceReceivingInformation, 1550 + (this.isEditabled ? 160 : 0));
        break;
      }
      default: {
        this.headerService.openButtonOnBottom.next(section);
        this.scrollTo(section, 0);
        break;
      }

    }

  }

  scrollTo(section: string, manualScrollBack: number) {
    if (!window.scrollY) {
      window.scrollBy(0, 1);
    }
    setTimeout( () => {
      const a = document.getElementById(section);
      a.scrollIntoView();
      window.scrollBy(0, manualScrollBack);
    }, 250);
  }
}
