import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { IFormInfo, LabelPositionType, EClassSize } from 'src/app/providers/_models/entity.model';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-document-expiration',
  templateUrl: './document.expiration.component.html',
  styleUrls: ['./document.expiration.component.scss']
})
export class DocumentExpirationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() isEnabled: boolean;
  public options = [];
  public select = '';
  public selectValue = '';
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public sizeClass = EClassSize.full; // full / half / third
  public labelPosition = LabelPositionType.left; // left / top / inside /doNot


  constructor(
    private dictionaryService: DictionaryService
  ) { }

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formsInfo && this.formsInfo && changes.key && this.key) {

      if (this.formsInfo.controlsInfo[this.key].sizeClass) {
        this.sizeClass = this.formsInfo.controlsInfo[this.key].sizeClass;
      }
      if (this.formsInfo.controlsInfo[this.key].labelPosition) {
        this.labelPosition = this.formsInfo.controlsInfo[this.key].labelPosition;
      }

      const dictKey = this.formsInfo.controlsInfo[this.key].dictKey || this.key;
      this.selectValue = ('' +  this.formsInfo.formGroup.controls[this.key].value);
      if (this.dictionaryService[dictKey]) {
        this.dictionaryService[dictKey].pipe(takeUntil(componentDestroyed(this))).subscribe(
          (res) => {
            this.options = res;
            const item = this.options.find((it) => ('' + it.id) === this.selectValue);
            this.select = item ? item.description ? item.description : item.full_name ? item.full_name : item.name : '';
          }
        );
      }
      this.formsInfo.formGroup.controls[this.key].valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe(
        (res) => {
          if (res && res.toString() === 'true') {
            this.formsInfo.formGroup.controls.expiration_date.setValidators([Validators.required]);
          } else {
            this.formsInfo.formGroup.controls.expiration_date.clearValidators();
          }
          this.selectValue = this.formsInfo.formGroup.controls[this.key].value.toString();
          this.formsInfo.formGroup.controls.expiration_date.updateValueAndValidity();
        }
      );

    }
  }
}
