import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import { IPagination } from 'src/app/providers/_models/pagination.model';
const widthOfPagination = 5;
const PerPageOptionList = [
  {value: 5, label: '5'},
  {value: 10, label: '10'},
  {value: 25, label: '25'},
  {value: 50, label: '50'},
  {value: 100, label: '100'},
  {value: 200, label: '200'},
];

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit, OnChanges {
  @Input() showContent = false;
  @Input() pagination: IPagination;
  @Input() widthOfPagination = widthOfPagination;
  @Output() changePage = new EventEmitter<IPagination>();
  public pages = [];
  public PerPageOptionList = PerPageOptionList;
  public initPagination: IPagination = {
    per_page: 25,
    current_page: 1,
    last_page: 1,
    total: 0
  };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pagination.currentValue) {
      this.initPagination = {...this.pagination};

      this.pages = [];
      for (let i = 1; i <= this.initPagination.last_page; i++) {
        this.pages.push(i);
      }
      if (this.initPagination.current_page > this.initPagination.last_page) {
        this.initPagination.current_page = this.initPagination.last_page;
        this.emitNewPage();
      }
    }
  }

  ngOnInit(): void {
  }

  public increment(length: number = 1): void {
    this.initPagination.current_page =
      this.initPagination.current_page  + length > this.pages.length ? 1 : this.initPagination.current_page + length;
    this.emitNewPage();
  }

  public decriment(length: number = 1): void {
    this.initPagination.current_page = this.initPagination.current_page - length < 1 ? this.pages.length : this.initPagination.current_page - length;
    this.emitNewPage();
  }

  public selectPage(page: number): void {
    this.initPagination.current_page = page;
    this.emitNewPage();
  }

  private emitNewPage() {
    this.changePage.emit(this.initPagination);
  }

}
