import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public selectItem = new BehaviorSubject(null);
  public openSection = new BehaviorSubject(null);
  public buttonOnBottom = new BehaviorSubject(null);
  public openButtonOnBottom = new BehaviorSubject(null);
  public changeStatus: BehaviorSubject<string> = new BehaviorSubject(null);
  public currentOpenEntity = new BehaviorSubject(null);
  public changeNotifications = new BehaviorSubject(null);
  public currentVersion = new BehaviorSubject(undefined);

  constructor() { }
}
