import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-operators-login',
    templateUrl: './operators.login.component.html',
    styleUrls: ['./operators.login.component.scss']
})

export class OperatorsLoginComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
