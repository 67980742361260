import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IFormInfo} from '../../providers/_models/entity.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {

  @Input() formsInfo: IFormInfo;
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() isEnabled: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
