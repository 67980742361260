import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { switchMap, filter, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, OnDestroy {
  name = '!!!';
  viewMode = 'tab1';
  private returnUrl: string;
  userForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  public user;
  private subscriptions: Subscription[] = [];
  get currentDate() {
    return new Date();
  }
  get alertDateStop() {
    return environment.ALERT_STOP_TIME ? new Date(environment.ALERT_STOP_TIME) : null;
  }
  public alertText = environment.ALERT_MESSAGE;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.http.post<any>('/api/auth/me', null).subscribe());
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.returnUrl =
      this.returnUrl !== '/login' && this.returnUrl !== '/logout' && this.returnUrl !== '/company' && this.returnUrl !== '/registration'
        ? this.returnUrl
        : '/';

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        if (localStorage.getItem('user_info')) {
          if (!localStorage.getItem('current_org')) {
            this.router.navigate(['/company']);
          } else {
            this.router.navigate([this.returnUrl]);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x && x.unsubscribe());
  }

  public login(): void {
    this.clearLocalStorage();
    this.authenticationService
      .login(this.userForm.controls.email.value, this.userForm.controls.password.value)
      .pipe(
        switchMap(() => this.authenticationService.getInfoMe()),
        switchMap(res => {
          this.user = res;
          return this.authenticationService.getMeOrg();
        }),
      )
      .subscribe(res => {
        if (this.user) {
          if (!this.user.esia_authorized && !(res.data && res.data.length)) {
            this.router.navigate(['/registration'], { queryParams: { returnUrl: this.returnUrl } });
          } else {
            this.router.navigate(['/company'], { queryParams: { returnUrl: this.returnUrl } });
          }
        }
      });
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('user_info');
    localStorage.removeItem('current_org');
  }

  public gotoESIALogin() {
    this.clearLocalStorage();
    const a = document.createElement('a');
    a.href = `/esia/login`;
    a.click();
  }

  public gotoRegistration() {
    this.clearLocalStorage();
    this.router.navigate(['/registration']);
  }

  public gotoResetPassword(): void {
    this.router.navigate(['/reset'], { queryParams: { email: this.userForm.controls.email.value } });
  }
}
