import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UploadService } from 'src/app/providers/_services/upload.service';
import { IFormInfo, LabelPositionType, EClassSize } from 'src/app/providers/_models/entity.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file.upload.component.html',
  styleUrls: ['./file.upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() fileObject;
  @Input() requestId;
  public formUpload: FormGroup;
  public error: any;
  public selectFileName: string;
  public filePath = '';
  public uploadResponse = { status: '', message: '', filePath: '' };
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public sizeClass = EClassSize.full; // full / half / third
  public labelPosition = LabelPositionType.left; // left / top / inside /doNot

  constructor(private fb: FormBuilder, private uploadService: UploadService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.formUpload = this.fb.group({
      fileUpload: [],
      fileName: [this.fileObject && this.fileObject.file_name ? this.fileObject.file_name : ''],
    });
    if (this.fileObject && this.fileObject.id) {
      this.filePath = this.fileObject.thumb_link ? this.fileObject.thumb_link : '/assets/images/icon_file.png';
    } else {
      this.filePath = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileObject && this.fileObject && this.formUpload) {
      if (this.formsInfo.controlsInfo[this.key].sizeClass) {
        this.sizeClass = this.formsInfo.controlsInfo[this.key].sizeClass;
      }
      if (this.formsInfo.controlsInfo[this.key].labelPosition) {
        this.labelPosition = this.formsInfo.controlsInfo[this.key].labelPosition;
      }

      this.formUpload.controls.fileName.setValue(this.fileObject.file_name);

      if (this.fileObject && this.fileObject.id) {
        this.filePath = this.fileObject.thumb_link ? this.fileObject.thumb_link : '/assets/images/icon_file.png';
      } else {
        this.filePath = '';
      }
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.formUpload.controls.fileUpload.setValue(event.target.files[0]);
      this.formUpload.controls.fileName.setValue(this.formUpload.controls.fileUpload.value.name);
      this.upload();
    }
  }

  upload() {
    const formData = new FormData();
    formData.append('file', this.formUpload.controls.fileUpload.value);

    this.uploadService.uploadFile(formData).subscribe(
      res => {
        this.uploadResponse = res;
        if (res && res.id) {
          this.fileObject = this.fileObject || {};
          for (const key in res) {
            if (res.hasOwnProperty(key)) {
              this.fileObject[key] = res[key];
            }
          }
          this.filePath = res.thumb_link || '/assets/images/icon_file.png';
          const a = this.formsInfo.formGroup.controls[this.key].value || [];
          a.push(res.id);
          this.formsInfo.formGroup.controls[this.key].setValue(a);
          this.formsInfo.formGroup.controls[this.key].markAsDirty();

          if (this.formsInfo.formGroup.controls[`${this.key}_files`]) {
            const b = this.formsInfo.formGroup.controls[`${this.key}_files`].value || [];
            b[a.length - 1] = res;
            this.formsInfo.formGroup.controls[`${this.key}_files`].setValue(b);
          }
          this.cdr.detectChanges();
        }
      },
      err => (this.error = err),
    );
  }

  ngOnDestroy(): void {}
}
