import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset.password.component.html',
    styleUrls: ['./reset.password.component.scss']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
  userForm: FormGroup = new FormGroup({
    email: new FormControl('', [
        Validators.required,
        Validators.email
    ])
  });

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(componentDestroyed(this))
    ).subscribe(params => {
      this.userForm.controls.email.setValue(params.email);
    });
  }

  public resetPassword(): void {
    this.auth.resetPassword(this.userForm.controls.email.value).subscribe(
      (res) => {
        if (res && res.message) {
          this.toastService.info('', res.message, { timeOut: 10000 });
          setTimeout(() => this.router.navigate(['/']), 500);
        }
      }
    );
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('user_info');
    localStorage.removeItem('current_org');
  }

  public returnToLogin(): void {
    this.clearLocalStorage();
    this.auth.logout().pipe(takeUntil(componentDestroyed(this))).subscribe();
  }

  public gotoRegistration() {
    this.clearLocalStorage();
    this.router.navigate(['/registration']);
  }
}
