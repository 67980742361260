import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { EClassSize, IControlsInfo, LabelPositionType } from '../../../../providers/_models/entity.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { toInputDateTimeFormat, toSQLDateTimeFormat } from 'src/app/providers/_utils/utils';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
  selector: 'app-date-picker-control',
  templateUrl: './date-picker-control.component.html',
  styleUrls: ['./date-picker-control.component.scss'],
})
export class DatePickerControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;
  @Input() fieldSizeClass = 'rpn-input-group__field_md';

  public random = Math.random();
  public sizeClass = EClassSize.full;
  public labelPosition = LabelPositionType.top;
  public LabelPositionType = LabelPositionType;
  public self = this;

  dateControl: FormControl = new FormControl(null);

  controlSubscription: Subscription;

  get control(): AbstractControl {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  get canEdit(): boolean {
    if (!this.isEnabled) {
      return false;
    }

    if (this.info && this.info.isDisabled && this.fGroup) {
      return !this.info.isDisabled(this.fGroup);
    }

    return true;
  }

  get showError() {
    return this.control && this.control.invalid;
  }

  constructor(public router: Router, public route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.info) {
      this.sizeClass = (this.info && this.info.sizeClass) || EClassSize.full;
      this.labelPosition = (this.info && this.info.labelPosition) || LabelPositionType.top;
      this.bindControls();
    }

    if (simpleChanges.isEnabled && this.control) {
      if (this.isEnabled) {
        this.control.enable({ emitEvent: false });
      } else {
        this.control.disable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    this.controlUnsubscribe();
  }

  bindControls() {
    this.controlUnsubscribe();

    if (!this.control) {
      this.dateControl.setValue(null);
      return;
    }

    const controlToDate = (val: string, emitEvent = false): void => {
      let date: Date;

      if (this.info && this.info.controlToDate) {
        date = this.info.controlToDate(val);
      } else {
        if (this.info && !this.info.withTime) {
          // Fix for old date values with time in UTC from mat datepicker
          if (val && !val.indexOf && (val as any).toISOString()) {
            val = (val as any).toISOString();
          }

          if (val && val.indexOf('T') > -1 && val.indexOf('T00') < 0) {
            const subdate = new Date(Date.parse(val.substr(0, 10)));
            subdate.setDate(subdate.getDate() + 1);
            val = toSQLDateTimeFormat(subdate).substr(0, 10);
          }
        }

        date = (val && toInputDateTimeFormat(val)) || null;
      }

      console.log(`controlToDate, ${this.key}, (${val}) => ${date}`);

      this.dateControl.setValue(date, { emitEvent });
    };

    controlToDate(this.control.value);

    this.controlSubscription = this.control.valueChanges.subscribe(val => {
      controlToDate(val);
      this.cdr.detectChanges();
    });
  }

  dateToControl(event: MatDatepickerInputEvent<Date>) {
    const date = event.target.value;
    let val: string;

    if (this.info && this.info.dateToControl) {
      val = this.info.dateToControl(date);
    } else {
      if (this.info && this.info.withTime) {
        val = (date && date.toISOString().replace('.000Z', '+0000')) || null;
      } else {
        val = toSQLDateTimeFormat(date).substr(0, 10);
      }
    }

    console.log(`dateToControl, ${this.key}, (${date}) => ${val}`);

    this.control.setValue(val);
  }

  private controlUnsubscribe() {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }
}
