import {Component, OnInit} from '@angular/core';
import {fadeInAnimation} from 'src/app/providers/_animations/fade-in.animation';


@Component({
    selector: 'app-operators-page',
    templateUrl: './operators.page.component.html',
    styleUrls: ['./operators.page.component.scss'],
    animations: [fadeInAnimation]
})
export class OperatorsPageComponent implements OnInit {

  ngOnInit(): void {
  }

}
