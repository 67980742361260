import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NsiRestService {

  constructor(private http: HttpClient) { }

  private setHttpParams(parameters: {[key: string]: string}): HttpParams {
    let params = new HttpParams();
    if (parameters && Object.keys(parameters).length) {
      for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
          params = params.append(key, parameters[key]);
        }
      }
    }

    return params;
  }

  public fetchDictionary(essence: string, filters?: {[key: string]: string}): Observable<any> {
    return this.http.get(`/api/nsi/${essence}`, {params: this.setHttpParams(filters)});
  }

}
