import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page.not.found/page.not.found.component';
import { environment } from 'src/environments/environment';
import { PageMaintenanceComponent } from './pages/page.maintenance/page.maintenance.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'rpn', pathMatch: 'full' },
  { path: 'page-maintenance', component: PageMaintenanceComponent },
  { path: 'page-not-found', component: PageNotFoundComponent }
];

@NgModule({
  imports:
    [
      RouterModule.forRoot(appRoutes, {
        // enableTracing: !environment.production,
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'top'
      })
    ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
