export const environment = {
  production: false,
  isPTC: 0,
  DOMEN_V2: 'https://lk.develop.rpn.big3.ru',
  ADDRESS_SUGGEST_URL: 'https://suggestions.dadata.ru',
  ADDRESS_DADATA_STAT_URL: 'https://dadata.ru',
  CLEANER_DADATA_URL: 'https://cleaner.dadata.ru',
  ADDRESS_SUGGEST_Authorization: '355f93ce71c996490531a51e9b6d924a54399dd4',
  DADATA_SECRET_KEY: '5856651842bf5936b0f3993943f05844a647a3b8',
  ALERT_MESSAGE: null,
  ALERT_STOP_TIME: null, // '2020-02-20T12:30:00.000Z'
};
