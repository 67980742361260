import { EsignModalService } from 'src/app/modules/esign.modal/esign.modal.service';
import { EsignSelectModalService } from './modules/esign.select.modal/esign.select.modal.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from './providers/_utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public showEsignModal;
  public showEsignSelectModal;

  constructor(
    private esignModalService: EsignModalService,
    private esignSelectModalService: EsignSelectModalService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon('draft', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom-icons/draft.svg'));
    this.matIconRegistry.addSvgIcon('signed', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom-icons/signed.svg'));
    this.matIconRegistry.addSvgIcon('sent', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom-icons/sent.svg'));
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.esignModalService.triggerEsignModal.pipe(takeUntil(componentDestroyed(this))).subscribe((res: { [key: string]: any }) => {
      this.showEsignModal = res;
    });

    this.esignSelectModalService.triggerEsignSelectModal
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((res: boolean | string) => (this.showEsignSelectModal = res && res !== 'signed'));
  }

  ngOnDestroy(): void { }
}
