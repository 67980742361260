import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorsPageComponent } from './operators.page.component';

@NgModule({
    declarations: [
      OperatorsPageComponent
    ],
    imports: [
      CommonModule,
    ],
    exports: [
      OperatorsPageComponent
    ]
})
export class OperatorsPageComponentModule {
}
