import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { SigninComponent } from './pages/signin/signin.component';
import { ResetPasswordComponent } from './pages/resetpassword/reset.password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { CompanyComponent } from './pages/company/company.component';
import { LogOutComponent } from './pages/logout/logout.component';
import { ComponentsModule } from '../components/components.module';
import { OperatorsLoginComponent } from './pages/operators.login.page/operators.login.component';
import { OperatorsPageComponentModule } from '../modules/operators.page/operators.page.component.module';
import { InstructionsComponent } from './pages/instructions.page/instructions.component';
import { ChangePasswordComponent } from './pages/changepassword/change.password.component';
import { PaginationComponentModule } from '../modules/pagination/pagination.component.module';

@NgModule({
    declarations: [
      SigninComponent,
      ResetPasswordComponent,
      RegistrationComponent,
      LogOutComponent,
      CompanyComponent,
      OperatorsLoginComponent,
      InstructionsComponent,
      ChangePasswordComponent
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      FormsModule,
      AuthRoutingModule,
      ComponentsModule,
      OperatorsPageComponentModule,
      PaginationComponentModule
    ],
    providers: []
})
export class AuthModule { }
