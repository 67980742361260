import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EsignSelectModalService {
  public triggerEsignSelectModal = new Subject<boolean | string>();

  constructor() {
  }

}
