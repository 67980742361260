import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { SigninComponent } from './pages/signin/signin.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPasswordComponent } from './pages/resetpassword/reset.password.component';
import { CompanyComponent } from './pages/company/company.component';
import { LogOutComponent } from './pages/logout/logout.component';
import { OperatorsLoginComponent } from './pages/operators.login.page/operators.login.component';
import { InstructionsComponent } from './pages/instructions.page/instructions.component';
import { ChangePasswordComponent } from './pages/changepassword/change.password.component';

const routes: Routes = [
  {path: 'login', component: SigninComponent},
  {path: 'registration', component: RegistrationComponent},
  {path: 'reset', component: ResetPasswordComponent},
  {path: 'change', component: ChangePasswordComponent},
  {path: 'company', component: CompanyComponent},
  {path: 'operators-page', component: OperatorsLoginComponent},
  {path: 'instructions', component: InstructionsComponent},
  {path: 'logout', component: LogOutComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
