import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IListItem } from '../../../../interfaces/common.interface';

@Component({
  selector: 'app-select-list-form',
  templateUrl: './select-list-form.component.html',
  styleUrls: ['./select-list-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectListFormComponent implements OnInit {

  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() options: IListItem[];
  @Input() selectNgClass: string[];
  @Input() templateOptions: TemplateRef<any>[];

  get control() {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
  }

  changeValue(item: IListItem) {
    this.control.setValue(item.id);

    if (item.onSelect) {
      item.onSelect(this.fGroup, item);
    }

    this.cdr.markForCheck();
  }

}
