import { LoadingSpinnerModule } from './../loading-spinner/loading.spinner.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { EsignSelectModalComponent } from './esign.select.modal.component';

@NgModule({
  declarations: [
    EsignSelectModalComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    LoadingSpinnerModule,
    ReactiveFormsModule
  ],
  exports: [
    EsignSelectModalComponent
  ]
})
export class EsignSelectModalComponentModule { }
