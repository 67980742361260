import { OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export function JSON_stringify(s: any, emitUnicode: boolean): string {
  const json = JSON.stringify(s);
  return emitUnicode ? json : json.replace(/[\u007f-\uffff]/g, c => '\\u' + ('0000' + c.charCodeAt(0).toString(16)).slice(-4));
}

export function getDateFromInputValue(item: string): Date {
  try {
    let value = item
      .replace(/\s/g, 'T')
      .replace('+0000', '')
      .replace('+0300', '');
    if (value[value.length - 1] !== 'Z') {
      value += 'Z';
    }
    const a = new Date(value);
    return a && '' + a !== 'Invalid Date' ? a : null;
  } catch {
    return null;
  }
}

export function getValueFromObject(obj, ...keys) {
  if (keys.length > 1) {
    return obj && getValueFromObject(obj[keys.shift()], ...keys);
  }

  return obj && obj[keys.shift()];
}

export function createImageFromBlob(image: Blob): Observable<string | ArrayBuffer> {
  const reader = new FileReader();
  const imageToShowChange$ = new BehaviorSubject(null);
  reader.addEventListener(
    'load',
    () => {
      imageToShowChange$.next(reader.result);
      imageToShowChange$.complete();
    },
    false,
  );
  reader.addEventListener('error', err => imageToShowChange$.error(err));
  reader.addEventListener('abort', () => imageToShowChange$.complete());

  if (image) {
    reader.readAsDataURL(image);
  } else {
    imageToShowChange$.complete();
  }

  return imageToShowChange$.pipe(filter(x => !!x));
}

export function scrollToElementHelper(element) {
  if (element) {
    const distance = window.pageYOffset - Math.abs(element.getBoundingClientRect().y);

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.getBoundingClientRect().top + window.scrollY - 150,
    });

    setTimeout(() => {
      element.focus();
      element.blur(); // Trigger error messages
      element.focus();
    }, distance);
  }
}

export function componentDestroyed(component: OnDestroy) {
  const oldNgOnDestroy = component.ngOnDestroy;
  const destroyed$ = new ReplaySubject<void>(1);
  component.ngOnDestroy = () => {
    oldNgOnDestroy.apply(component);
    destroyed$.next(undefined);
    destroyed$.complete();
  };
  return destroyed$;
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function toSQLDateTimeFormat(x: Date): string {
  if (x) {
    x = new Date(x);
    const date = x.getDate();
    const month = x.getMonth() + 1;
    const year = x.getFullYear();
    let res = '';
    if (!!date && !!month && !!year) {
      res = `${year}-${to2digit(month)}-${to2digit(date)}T00:00:00+0000`;
    }
    return res;
  }
  return '';
}

function to2digit(n: number) {
  return ('00' + n).slice(-2);
}

export function endOfDay(date: Date): Date {
  date.setDate(date.getDate() + 1);
  date.setSeconds(date.getSeconds() - 1);
  return date;
}

export function toInputDateTimeFormat(x: string): Date {
  return new Date(Date.parse(x));
}

export function assignObjects(a, b): any {
  const fieldsTypeDate = ['sent_at', 'payed_at', 'registered_at', 'expiration_date', 'issue_date', 'control_date'];
  const fieldsTypeBoolean = ['from_epgu', 'payed', 'expiration_dateless', 'legal_agent', 'hasSigned', 'get_personally'];
  const fieldsTypeNumber = [
    'doer_id',
    'signer_name',
    'status_id',
    'user_id',
    'territory_org_id',
    'region_id',
    'result_document_letter_template_id',
    'template_id',
    'letter_template_id',
  ];

  for (const keyOfJson in b) {
    if (
      b.hasOwnProperty(keyOfJson) &&
      b[keyOfJson] !== null &&
      b[keyOfJson] !== 'null' &&
      b[keyOfJson] !== '' &&
      !(keyOfJson.indexOf('_files') + 1) &&
      keyOfJson !== 'section_name' &&
      keyOfJson !== 'service_request_type'
    ) {
      if (fieldsTypeDate.indexOf(keyOfJson) + 1) {
        a[keyOfJson] = toSQLDateTimeFormat(b[keyOfJson]);
      } else if (fieldsTypeBoolean.indexOf(keyOfJson) + 1) {
        a[keyOfJson] = `${b[keyOfJson]}` === 'true';
      } else if (fieldsTypeNumber.indexOf(keyOfJson) + 1) {
        a[keyOfJson] = +b[keyOfJson];
      } else {
        a[keyOfJson] = b[keyOfJson];
      }
    }
  }
  return a;
}

export function isEqual(obj1, obj2): boolean {
  if (obj1 === obj2) {
    return true;
  } else {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const propName in obj1) {
      if (obj2.hasOwnProperty(propName)) {
        if (obj1[propName] !== obj2[propName]) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  return true;
}

export function decimalRound(value: number | string, decimalPlaces?) {
  const roundConstant = Math.pow(10, decimalPlaces || 9);
  const num = Math.round((+value + Number.EPSILON) * roundConstant) / roundConstant;
  return num;
}
