import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {
  public config = {
    organiztionName: 'РОСПРИРОДНАДЗОР',
    systemName: 'Программно – технологический комплекс «Госконтроль»',
    tabs: []
  };

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
