import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { takeUntil, delay } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { of } from 'rxjs';

@Component({
  selector: 'app-page-maintenance',
  templateUrl: './page.maintenance.component.html',
  styleUrls: ['./page.maintenance.component.scss']
})
export class PageMaintenanceComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    of(null).pipe(
      delay(30000),
      takeUntil(componentDestroyed(this))
    ).subscribe(
      () => this.returnToMain()
    );
  }

  public returnToMain(): void {
    this.router.navigate([`/`]);
  }

  public returnToPreview(): void {
    this.location.back();
  }

}
