import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageMaintenanceComponent } from './page.maintenance.component';

@NgModule({
  declarations: [
    PageMaintenanceComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PageMaintenanceComponent
  ],
})
export class PageMaintenanceComponentModule { }
