import { Pipe, PipeTransform } from '@angular/core';
import { IFileFields } from '../_models/model.cites.files';

@Pipe({
    name: 'keysByOrder',
    pure: false
})
export class KeysByOrder implements PipeTransform {
    transform(items: IFileFields): any {
      const keys = [];
      for (const key in items) {
        if (items.hasOwnProperty(key)) {
          keys[items[key].order] = key;
        }
      }
      return keys.filter( (x) => !!x );
    }
}
