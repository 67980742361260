import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { IDictionaryItem } from '../../interfaces/dictionary.interface';
import { EClassSize, IControlsInfo, LabelPositionType } from '../../providers/_models/entity.model';
import { SvcRestService } from '../../providers/_services/svc.rest.service';
import { componentDestroyed } from '../../providers/_utils/utils';
import { isNumber } from 'util';

@Component({
  selector: '[app-dictionary-autocomplete]',
  templateUrl: './dictionary-autocomplete.component.html',
  styleUrls: ['./dictionary-autocomplete.component.scss'],
})
export class DictionaryAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;
  @Input() fieldSizeClass = 'rpn-input-group__field_md';
  @Input() templateOptions: TemplateRef<any>[];
  @Input() excludeItems = [];

  @Output() changeSelectItem = new EventEmitter();

  getItems$: Subject<string> = new Subject<string>();

  get control(): AbstractControl {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  get canEdit(): boolean {
    if (!this.isEnabled) {
      return false;
    }

    if (this.info && this.info.isDisabled && this.fGroup) {
      return !this.info.isDisabled(this.fGroup);
    }

    return true;
  }

  get showError() {
    return this.control && this.control.invalid && this.canEdit;
  }

  random = Math.random();
  sizeClass = EClassSize.full;
  labelPosition = LabelPositionType.top;
  LabelPositionType = LabelPositionType;

  suggestItems: IDictionaryItem[];
  suggestToggle = false;

  get minChars() {
    if (this.info && !!this.info.minSearchChars) {
      return this.info.minSearchChars;
    }

    return 3;
  }

  searchText = '';
  searchEvent$: Subject<string> = new Subject<string>();

  // tslint:disable-next-line:variable-name
  private _subs: Subscription[] = [];
  set subs(sub) {
    this._subs.push(sub);
  }

  changeSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef, private svcRestService: SvcRestService) {}

  ngOnInit() {
    this.subs = this.searchEvent$.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(componentDestroyed(this))).subscribe(value => {
      this.getItems$.next(value);
    });

    this.subs = this.getItems$
      .pipe(
        distinctUntilChanged(),
        filter(val => val && val.length >= this.minChars),
        switchMap(value => this.searchItems(value)),
        takeUntil(componentDestroyed(this)),
      )
      .subscribe(suggestions => {
        this.suggestItems = suggestions;
        this.suggestToggle = (this.templateOptions && !!this.templateOptions.length) || !!suggestions.length;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.info) {
      this.sizeClass = (this.info && this.info.sizeClass) || EClassSize.full;
      this.labelPosition = (this.info && this.info.labelPosition) || LabelPositionType.top;
    }

    if ((simpleChanges.fGroup || simpleChanges.key) && this.control && this.info) {
      if (this.changeSubscription) {
        this.changeSubscription.unsubscribe();
      }

      this.changeSubscription = this.control.valueChanges.pipe(filter(value => !value)).subscribe(value => {
        this.searchText = '';
      });

      this.setSearchText();
    }
  }

  ngOnDestroy() {
    this._subs.forEach(s => s.unsubscribe());

    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    if (this.suggestToggle) {
      this.suggestToggle = false;
      this.setSearchText();
    }
  }

  private setSearchText() {
    this.searchText = '';

    if (this.control.value) {
      const jsonKey = (this.info && this.info.uniqueKeyForSaveJSON) || this.key.replace('_id', '');
      const jsonItem = this.fGroup.controls[jsonKey];

      if ((jsonKey === this.key || !jsonItem || !jsonItem.value) && isNumber(this.control.value)) {
        this.searchById(+this.control.value)
          .pipe(takeUntil(componentDestroyed(this)))
          .subscribe(suggestions => {
            if (suggestions && suggestions.length) {
              this.selectItem(null, suggestions[0]);
              this.changeDetectorRef.detectChanges();
            }
          });
        return;
      }

      try {
        this.searchText = jsonItem ? this.formatItem(JSON.parse(jsonItem.value)) : '';
      } catch (e) {}
    } else {
      const nameKey = (this.info && this.info.uniqueKeyForSaveName) || this.key.replace('_id', '_name');
      const nameControl = this.fGroup.controls[nameKey];

      if (nameControl) {
        this.searchText = nameControl.value || '';
      }
    }
  }

  formatItem(item: IDictionaryItem): string {
    if (this.info && this.info.dictionaryFormatItem) {
      return this.info.dictionaryFormatItem(item, this.fGroup);
    }

    return this.defaultFormatItem(item);
  }

  selectItem(event, item: IDictionaryItem): void {
    if (this.info.checkBeforeSelectItem) {
      const success = this.info.checkBeforeSelectItem(this.fGroup, item);

      if (!success) {
        return;
      }
    }

    this.searchText = this.formatItem(item);
    this.control.setValue(+item.id, { emitEvent: false });
    this.control.markAsDirty();

    if ((this.info && this.info.uniqueKeyForSaveJSON) || this.key.indexOf('_id') > -1) {
      const jsonKey = (this.info && this.info.uniqueKeyForSaveJSON) || this.key.replace('_id', '');
      const jsonItem = this.fGroup.controls[jsonKey];

      if (jsonItem) {
        jsonItem.setValue(JSON.stringify(item), { emitEvent: false });
      }
    }

    if ((this.info && this.info.uniqueKeyForSaveName) || this.key.indexOf('_id') > -1) {
      const nameKey = (this.info && this.info.uniqueKeyForSaveName) || this.key.replace('_id', '_name');
      const nameControl = this.fGroup.controls[nameKey];

      if (nameControl) {
        nameControl.setValue(item.name, { emitEvent: false });
      }
    }

    this.changeToggle(event, false);

    if (this.info.onSelectAutocomplete) {
      this.info.onSelectAutocomplete(this.fGroup, item);
    }

    this.changeSelectItem.emit(item);
    this.changeDetectorRef.markForCheck();
  }

  changeToggle(event, toggle: boolean): void {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.suggestToggle && toggle && !this.suggestItems) {
      this.getItems$.next(this.searchText);
    }

    this.suggestToggle = toggle;
  }

  private defaultFormatItem(item: IDictionaryItem) {
    return item.name;
  }

  private filterItems(items: IDictionaryItem[]) {
    return items.filter(v => {
      if (this.excludeItems && this.excludeItems.length) {
        // tslint:disable-next-line:triple-equals
        if (this.control.value != null && v.id == this.control.value) {
          return true;
        }

        // tslint:disable-next-line:triple-equals
        return this.excludeItems.findIndex(item => item[this.key] == v.id) === -1;
      }

      return true;
    });
  }

  private searchItems(value: string): Observable<IDictionaryItem[]> {
    return this.svcRestService
      .fetchDictionaryItems(this.info && this.info.dictKey, {
        pagination: {
          per_page: 50,
          page: 1,
        },
        filters: {
          search: `${value}`,
        },
      })
      .pipe(
        map(items => this.filterItems(items)),
        catchError(e => of([])),
      );
  }

  private searchById(value: number): Observable<IDictionaryItem[]> {
    return this.svcRestService
      .fetchDictionaryItems(this.info && this.info.dictKey, {
        pagination: {
          per_page: 50,
          page: 1,
        },
        filters: {
          'filter[id]': value,
        },
      })
      .pipe(catchError(e => of([])));
  }
}
