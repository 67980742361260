import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private loadingCounter = 0;
  private loading: boolean;
  public loadingStatus$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  public createSpinner(): void {
    if (!this.loading && !this.loadingCounter) {
      this.loading = true;
      this.loadingStatus$.next(this.loading);
    }
    this.loadingCounter++;
  }

  public dismissSpinner(): void {
    this.loadingCounter--;
    if (this.loading && this.loadingCounter <= 0) {
      this.loading = false;
      this.loadingStatus$.next(this.loading);
      this.loadingCounter = 0;
    }
  }

  public dismissSpinnerAll(): void {
    this.loading = false;
    this.loadingStatus$.next(this.loading);
  }
}
