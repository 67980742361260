import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFormInfo} from 'src/app/providers/_models/entity.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dadata-autocomplete',
  templateUrl: './dadata.autocomplete.component.html',
  styleUrls: ['./dadata.autocomplete.component.scss']
})
export class DadataAutocompleteComponent implements OnInit {
  @Input() type = {type: 'address', label: 'Адрес', subType: null};
  @Input() formsInfo: IFormInfo;
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() isEnabled: boolean;
  @Input() debounceTime = 2000;
  @Output() changeSelectItem = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {

  }
}
