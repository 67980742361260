import {IListItem} from './common.interface';

export interface IReportBuilderTemplate extends IListItem {
  sourceId: number | string;
  sourceTypeId: number | string;
  filters: IReportBuilderTemplateFilter[];
}

export interface IReportBuilderTemplateFilter {
  operator: EReportBuilderOperator;
  fieldId: number | string;
  condition: EReportBuilderNumberCondition | EReportBuilderStringCondition;
  value: string;
}

export interface IReportBuilderField extends IListItem {
  type: EReportBuilderFieldType;
  options?: IListItem[];
}

export enum EReportBuilderOperator {
  AND = 'AND',
  OR = 'OR',
}

export const REPORT_BUILDER_OPERATORS: IListItem[] = [
  {id: EReportBuilderOperator.AND, name: 'И'},
  {id: EReportBuilderOperator.OR, name: 'Или'},
];

export enum EReportBuilderNumberCondition {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER = 'GREATER',
  LESS = 'LESS',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
}

export const REPORT_BUILDER_BOOLEAN_CONDITIONS: IListItem[] = [
  {id: EReportBuilderNumberCondition.EQUAL, name: 'Равно'},
];

export const REPORT_BUILDER_NUMBER_CONDITIONS: IListItem[] = [
  {id: EReportBuilderNumberCondition.EQUAL, name: 'Равно'},
  {id: EReportBuilderNumberCondition.NOT_EQUAL, name: 'Не равно'},
  {id: EReportBuilderNumberCondition.GREATER, name: 'Больше'},
  {id: EReportBuilderNumberCondition.LESS, name: 'Меньше'},
  {id: EReportBuilderNumberCondition.GREATER_OR_EQUAL, name: 'Больше или равно'},
  {id: EReportBuilderNumberCondition.LESS_OR_EQUAL, name: 'Меньше или равно'},
];

export const REPORT_BUILDER_SELECT_CONDITIONS: IListItem[] = [
  {id: EReportBuilderNumberCondition.EQUAL, name: 'Равно'},
  {id: EReportBuilderNumberCondition.NOT_EQUAL, name: 'Не равно'},
];

export enum EReportBuilderStringCondition {
  EQUAL = 'EQUAL',
  CONTAINS = 'CONTAINS',
  START_WITH = 'START_WITH',
  END_WITH = 'END_WITH',
}

export const REPORT_BUILDER_STRING_CONDITIONS: IListItem[] = [
  {id: EReportBuilderStringCondition.EQUAL, name: 'Равно'},
  {id: EReportBuilderStringCondition.CONTAINS, name: 'Содержит'},
  {id: EReportBuilderStringCondition.START_WITH, name: 'Начинается на'},
  {id: EReportBuilderStringCondition.END_WITH, name: 'Заканчивается на'},
];

export enum EReportBuilderFieldType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
}

export const REPORT_BUILDER_FIELD_BOOLEAN_VALUES: IListItem[] = [
  {id: 0, name: 'Нет'},
  {id: 1, name: 'Да'},
];
