import { PipesModule } from 'src/app/providers/_pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { EsignModalComponent } from './esign.modal.component';

@NgModule({
  declarations: [
    EsignModalComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [
    EsignModalComponent
  ]
})
export class EsignModalComponentModule { }
