import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EClassSize, EInputType, IControlsInfo, LabelPositionType } from '../../../../providers/_models/entity.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.scss'],
})
export class InputControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;
  @Input() fieldSizeClass = 'rpn-input-group__field_md';

  get control(): AbstractControl {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  get canEdit(): boolean {
    if (!this.isEnabled) {
      return false;
    }

    if (this.info && this.info.isDisabled && this.fGroup) {
      return !this.info.isDisabled(this.fGroup);
    }

    return true;
  }

  get showError() {
    return this.control && this.control.invalid && this.canEdit;
  }

  get upperCase() {
    if (this.info && this.info.upperCase === 'input') {
      return '';
    } else if (this.info && this.info.upperCase === 'blur') {
      return 'blur';
    }
    return 'none';
  }

  public random = Math.random();
  public sizeClass = EClassSize.full;
  public labelPosition = LabelPositionType.top;
  public LabelPositionType = LabelPositionType;
  public self = this;

  // tslint:disable-next-line:variable-name
  private _subs: Subscription[] = [];
  set subs(sub) {
    this._subs.push(sub);
  }

  constructor(public router: Router, public route: ActivatedRoute) {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.info) {
      this.sizeClass = (this.info && this.info.sizeClass) || EClassSize.full;
      this.labelPosition = (this.info && this.info.labelPosition) || LabelPositionType.top;
    }

    if ((simpleChanges.fGroup || simpleChanges.key) && this.control) {
      if (this.getMin() !== null) {
        this.subs = this.control.valueChanges.subscribe(val => {
          if (this.info && this.info.inputType === EInputType.optionalNumber) {
            const strVal = '' + val;

            if (strVal.indexOf('-') >= 0) {
              this.control.setValue('-', { emitEvent: false });
              return;
            }

            const parseVal = parseFloat(strVal);

            if (isNaN(parseVal)) {
              this.control.setValue(0);
            } else if (this.getMin() !== null && parseVal < this.getMin()) {
              this.control.setValue(this.getMin());
            }
          }

          if (val === '' || (val === '-' && this.info && this.info.inputType === EInputType.numberAndDash)) {
            return;
          }

          const strVal = '' + val;
          const parseVal = parseFloat(strVal);

          if (isNaN(parseVal)) {
            this.control.setValue(0);
          } else if (this.getMin() !== null && parseVal < this.getMin()) {
            this.control.setValue(this.getMin());
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this._subs.forEach(s => s.unsubscribe());
  }

  getMin() {
    return this.info &&
      (this.info.inputType === EInputType.number ||
        this.info.inputType === EInputType.numberAndDash ||
        this.info.inputType === EInputType.optionalNumber) &&
      this.info.minValue !== null &&
      this.info.minValue !== undefined
      ? this.info.minValue
      : null;
  }

  getDecimalPlaces() {
    return this.info &&
      (this.info.inputType === EInputType.number ||
        this.info.inputType === EInputType.numberAndDash ||
        this.info.inputType === EInputType.optionalNumber) &&
      this.info.decimalPlaces !== null &&
      this.info.decimalPlaces !== undefined
      ? this.info.decimalPlaces
      : null;
  }

  leaveFocus(event) {
    if (this.info && this.info.inputType === EInputType.optionalNumber) {
      if ('' + event.target.value === '0') {
        this.control.setValue('-', { emitEvent: false });
      }
    }
  }

  inputKeyDown(event: KeyboardEvent) {
    if (isNaN(+event.key) || this.getDecimalPlaces() === null) {
      return event;
    }

    const strVal = ('' + (event.target as HTMLInputElement).value).replace(',', '.');
    const val = +strVal;

    const decimalPlaces = !!(val % 1) ? strVal.length - strVal.indexOf('.') - 1 : 0;

    if (decimalPlaces >= this.getDecimalPlaces()) {
      event.preventDefault();
    }
  }

  inputPaste(event: ClipboardEvent) {
    const text = event.clipboardData.getData('text');

    const strVal = ('' + text).replace(',', '.');
    const val = +strVal;

    if (isNaN(val) || this.getDecimalPlaces() === null) {
      return event;
    }

    const decimalPlaces = !!(val % 1) ? strVal.length - strVal.indexOf('.') - 1 : 0;

    if (decimalPlaces > this.getDecimalPlaces()) {
      const cutVal = +val.toFixed(this.getDecimalPlaces());

      this.control.setValue(cutVal);
      event.preventDefault();
    }
  }
}
