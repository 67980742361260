import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GosUslugiType } from '../_models/entity.model';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private httpClient: HttpClient
  ) {
  }


  public fetchUploadFile(type: string, formId: string, key: string): Observable<any> {
    if (formId && key) {
      let uploadURL = '';
      if (type === GosUslugiType.cites) {
        uploadURL = `/api/svc/${type}/${formId}/${key}`;
      } else {
        uploadURL = `/api/file/thumb/${key}/${formId}`;
      }
      return this.fetch(uploadURL);
    }
    return of(null);
  }

  private fetch(uploadURL: string): Observable<any> {
    return this.httpClient.get<any>(uploadURL);
  }

  public deleteUploadFile(formId: string, field: string): Observable<any> {
    const uploadURL = `/api/svc/cites/${formId}/${field}`;
    return this.httpClient.delete(uploadURL);
  }

  public uploadFile(file: any): Observable<any> {
    // let uploadURL = '';
    // if (type === GosUslugiType.cites) {
    //   if (field === 'payed_bills_attachments') {
    //     uploadURL = `/api/svc/${type}/${formId}/${field}`;
    //   } else {
    //     uploadURL = `/api/svc/${type}/${formId}/main_data/files/${field}`;
    //   }
    //   return this.upload(uploadURL, file);
    // } else {
    return this.uploadForPrototype(file);
    // }
  }

  private upload(uploadURL: string, file: any): Observable<any> {
    return this.httpClient.post<any>(uploadURL, file, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return `Uploading file "${file.name}" of size ${file.size}.`;
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }

  private uploadForPrototype( formData: any): Observable<any> {

    return this.httpClient.post<any>('/api/file/upload', formData,
    {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return `Uploading file "${formData.name}" of size ${formData.size}.`;
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }


}
