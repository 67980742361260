import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import * as Raven from 'raven-js';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthTokenInterceptor } from './auth/services/auth.token.interceptor';
import { EsignModalComponentModule } from './modules/esign.modal/esign.modal.component.module';
import { EsignSelectModalComponentModule } from './modules/esign.select.modal/esign.select.modal.module';
import { LoadingSpinnerModule } from './modules/loading-spinner/loading.spinner.module';
import { MainComponentModule } from './pages/main.component.module';
import { PageMaintenanceComponentModule } from './pages/page.maintenance/page.maintenance.component.module';
import { PageNotFoundComponentModule } from './pages/page.not.found/page.not.found.component.module';
import { TooltipDirective } from './providers/directives/tooltip/tooltip.directive';
import { AuthGuard } from './providers/_guards/auth.guard';
import { PtcAuthGuard } from './providers/_guards/ptc.auth.guard';
import { AutocompleteService } from './providers/_services/autocomplete.service';
import { NsiRestService } from './providers/_services/nsi.rest.service';
import { UploadService } from './providers/_services/upload.service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { CookieService } from 'ngx-cookie-service';

Raven.config('https://225845053339469bb97225d1e0e1234c@sentry.rpn.big3.ru/5').install();

export class RavenErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: any): void {
    if (environment.production) {
      const exception = error.originalError || error.error || error;
      Raven.captureException(exception);
    } else {
      console.error(error);
    }
  }
}

const providers: any[] = [
  AuthGuard,
  PtcAuthGuard,
  NsiRestService,
  AutocompleteService,
  UploadService,
  CookieService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true,
  },
];

if (environment.production) {
  providers.push({
    provide: ErrorHandler,
    useClass: RavenErrorHandler,
  });
}

@NgModule({
  declarations: [AppComponent, TooltipDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    AuthModule,
    PageNotFoundComponentModule,
    PageMaintenanceComponentModule,
    MainComponentModule,
    LoadingSpinnerModule,
    EsignModalComponentModule,
    EsignSelectModalComponentModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools,
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
