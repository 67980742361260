import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IFormInfo, LabelPositionType, EClassSize } from 'src/app/providers/_models/entity.model';

@Component({
  selector: 'app-date-month-year-input',
  templateUrl: './date.month.year.input.component.html',
  styleUrls: ['./date.month.year.input.component.scss']
})
export class DateMonthYearInputComponent implements OnInit, OnChanges {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() isEnabled = false;
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public sizeClass = EClassSize.full; // full / half / third
  public labelPosition = LabelPositionType.left; // left / top / inside /doNot

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formsInfo && changes.key) {
      if (this.formsInfo.controlsInfo[this.key].sizeClass) {
        this.sizeClass = this.formsInfo.controlsInfo[this.key].sizeClass;
      }
      if (this.formsInfo.controlsInfo[this.key].labelPosition) {
        this.labelPosition = this.formsInfo.controlsInfo[this.key].labelPosition;
      }
    }
  }

}
