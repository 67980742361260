import { IBid } from 'src/app/providers/_models/entity.model';

export const CitesSectionName = {
  applicantInformation: 'applicantInformation',
  serviceReceivingInformation: 'serviceReceivingInformation',
  serviceReceivingDocumentMethod: 'serviceReceivingDocumentMethod',
};

export const CitesSectionPosition = [
  CitesSectionName.applicantInformation,
  CitesSectionName.serviceReceivingInformation,
  CitesSectionName.serviceReceivingDocumentMethod,
];

export class CitesBid implements IBid {
    'id' = null;
    'region_id' = null;
    'territory_org_id' = null;
    'user_id' = null;
    'doer_id' = null;
    'status_id' = null;
    'next_statuses' = null;
    'status_code' = '';
    'reject_text' = '';
    'number' = '';
    // 'form_type_id' = null;
    'term_days' = null;
    'control_date' = null;
    'cost' = null;
    'payed' = false;
    'payed_bills_attachments' = null;
    'payed_bills_attachments_files' = null;
    'payed_at' = null;
    'from_epgu' = true;
    'sent_at' = null;
    'registered_at' = null;
    'done_at' = null;
    'result_comment' = '';
    'created_at' = null;
    'updated_at' = null;
    'cites_objects_count' = 0;
    'complete' = false;
    'signed_request' = null;
    'doer' = {
        'id': null,
        'full_name': '',
        'surname': '',
        'name': '',
        'patronymic': ''
    };
    'user' = {
        'id': null,
        'full_name': '',
        'surname': '',
        'name': '',
        'patronymic': '',
        'passport': null,
        'inn': '',
        'photo': null,
        'photos': null,
        'email': '',
        'created_at': null,
        'updated_at': null
    };
    'territory_org' = null;
    'status' = null;
    'form_type' = null;
    'applicant_information' = {
        'id': null,
        'service_request_id': null,
        'full_name': '',
        'surname': '',
        'name': '',
        'patronymic': '',
        'passport': '',
        'inn': '',
        'citizen_country_id': null,
        'country_id': null,
        'address': '',
        'zip_code': '',
        'legal_agent': false,
        'created_at': null,
        'updated_at': null,
        'country': null
    };
    'main_data': any;
    'document_receiving_info' = {
        'id': null,
        'service_request_id': null,
        'get_personally': false,
        'country_id': null,
        'address': '',
        'zip_code': '',
        'legal_agent': false,
        'created_at': null,
        'updated_at': null,
        'country': null
    };
    'result_document' = {
        'id': null,
        'service_request_id': null,
        // 'form_type_id': null,
        'number': null,
        'safety_mark': null,
        'status_id': null,
        'status_code': null,
        'expiration_dateless': null,
        'expiration_date': null,
        'territory_org_id': null,
        'issuer_name': null,
        'issue_date': null,
        'importer': null,
        'exporter': null,
        'special_conditions': null,
        'signer_name': null,
        'file': null,
        'created_at': null,
        'updated_at': null
    };

    constructor(item: IBid) {
        if (!!item) {
            for (const key in item) {
                if (item.hasOwnProperty(key) && item[key] !== undefined) {
                    this[key] = item[key];
                }
            }
        }
    }


}
