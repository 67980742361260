import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { IControlsInfo, LabelPositionType } from '../../../../providers/_models/entity.model';

@Component({
  selector: 'app-dialog-control',
  templateUrl: './dialog-control.component.html',
  styleUrls: ['./dialog-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogControlComponent implements OnInit {

  get control(): AbstractControl {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() isEnabled: boolean;
  @Input() inTable: boolean;

  promptMessage: string = null;
  private _confirmValue: boolean = null;

  public LabelPositionType = LabelPositionType;

  ngOnInit() {
  }

  setValue(value: boolean) {
    if (this.info.dialog && this.info.dialog.showPrompt) {
      const promptMessage = this.info.dialog.showPrompt(this.fGroup, value);

      if (promptMessage) {
        this._confirmValue = value;
        this.promptMessage = promptMessage;
        return;
      }
    }

    this.control.setValue(value);
    this.control.markAsDirty();
    this.cdr.markForCheck();
  }

  closeModal(event?) {
    this.promptMessage = null;
    this._confirmValue = null;
  }

  confirmValue() {
    this.control.setValue(this._confirmValue);
    this.control.markAsDirty();
    this.promptMessage = null;
    this._confirmValue = null;
    this.cdr.markForCheck();
  }
}
