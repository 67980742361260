import { Router } from '@angular/router';
import { componentDestroyed, getDateFromInputValue, toSQLDateTimeFormat } from 'src/app/providers/_utils/utils';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { debounceTime, distinctUntilChanged, filter, takeUntil, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { IDADATASuggestion } from 'src/app/providers/_models/entity.model';
import { PasswordConfirmValidator } from '../../services/pwd_confirm.validator';

export interface IRegistrationResponse {
    'id': number;
    'esia_oid': number;
    'full_name': string;
    'surname': string;
    'name': string;
    'patronymic': string;
    'passport': string;
    'inn': string;
    'email': string;
    'created_at': string;
    'updated_at': string;
    'actions': any;
    'actions_count': number;
    'esia_authorized': string;
    'is_editable': boolean;
    'organization': number;
    'messages_received': any;
    'messages_received_count': number;
    'messages_received_unread': any;
    'messages_received_unread_count': number;
    'messages_sent': number;
    'messages_sent_count': number;
    'notifications_count': number;
    'organizations': any;
    'organizations_count': number;
    'permissions_count': number;
    'roles_count': number;
    'system_logs': any;
    'system_logs_count': number;
}

export interface IRegistrationRequest {
    'full_name': string;
    'surname': string;
    'name': string;
    'patronymic': string;
    'inn': string;
    'email': string;
    'password': string;
}

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit, OnDestroy {

    constructor(
        private auth: AuthenticationService,
        private rest: SvcRestService,
        private router: Router
    ) {
    }
    userForm: FormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.email
        ]),
        password: new FormControl('', [
            Validators.required,
        ]),
        password_confirmation: new FormControl('', [
            Validators.required,
        ])
    }, {
        validators: PasswordConfirmValidator
    });

    userFormOrg: FormGroup = new FormGroup({
        query: new FormControl('', [
            Validators.required,
        ]),
        inn: new FormControl({
            value: '',
            disabled: true
        }),
        name: new FormControl({
            value: '',
            disabled: true
        }),
        ogrn: new FormControl({
            value: '',
            disabled: true
        }),
        kpp: new FormControl({
            value: '',
            disabled: true
        }),
        egrul_number: new FormControl('', [
            Validators.required,
        ]),
        egrul_registration_date: new FormControl('', [
            Validators.required,
        ])

    });

    public mayAdd = false;

    public mode: 'registration' | 'organization' = 'registration';
    public body: { [name: string]: any } = {};
    public user = null;

    ngOnInit(): void {
        try {
            this.user = JSON.parse(localStorage.getItem('user_info'));
        } catch (err) { }
        if (!!this.user) {
            this.mode = 'organization';
        }
    }

    public registration(): void {
        if (this.userForm.valid) {
            this.auth.registration(this.userForm.value).pipe(
                takeUntil(componentDestroyed(this))
            ).subscribe(data => {
                this.mode = 'organization';
            });
        } else {
            // console.error('для регистрации недостаточно данных, либо данные заполнены с ошибкой');
        }
    }

    public organizationAdd(): void {
        this.body.egrul_number = this.userFormOrg.value.egrul_number;
        this.body.egrul_registration_date = toSQLDateTimeFormat(this.userFormOrg.value.egrul_registration_date);

        this.auth.addOrgInProfile(this.body).pipe(
            takeUntil(componentDestroyed(this))
        ).subscribe(data => this.router.navigate(['/']));
    }

    private serializerDadata(data: IDADATASuggestion): void {

        if (data && data.data) {
            const d = data.data;
            if (d.name && d.name.full_with_opf) {
                this.body.full_name = d.name.full_with_opf;
            }
            if (d.name && d.name.short_with_opf) {
                this.body.short_name = d.name.short_with_opf;
                this.userFormOrg.get('name').setValue(d.name.short_with_opf);
            }
            if (d.name && d.name.full) {
                this.body.name = d.name.full;
            }
            if (d.state && d.state.registration_date) {
                this.body.registration_date = moment(d.state.registration_date).format('YYYY-MM-DD');
            }
            if (d.inn) {
                this.body.inn = d.inn;
                this.userFormOrg.get('inn').setValue(d.inn);
            }
            if (d.kpp) {
                this.body.kpp = d.kpp;
                this.userFormOrg.get('kpp').setValue(d.kpp);
            }
            if (d.ogrn) {
                this.body.ogrn = d.ogrn;
                this.userFormOrg.get('ogrn').setValue(d.ogrn);
            }
            if (d.okpo) { this.body.okpo = d.okpo; }
            if (d.okato) { this.body.okato = d.okato; }
            if (d.okved) { this.body.okved = d.okved; }
            if (d.address && d.address.data && d.address.data.postal_code) {
                this.body.legal_address_post_index = d.address.data.postal_code;
            }
            if (d.address && d.address.data && d.address.data.city) {
                this.body.legal_address_city = d.address.data.city;
            }
            if (d.address && d.address.data && d.address.data.oktmo) {
                this.body.legal_address_oktmo = d.address.data.oktmo;
            }
            if (d.address && d.address.value) {
                this.body.actual_address_address = d.address.value;
            }
            if (d.management && d.management.name) {
                this.body.director_name = d.management.name;
                this.body.director_post = d.management.post;
                const surname = d.management.name.split(' ')[0] || '';
                const patronymic = d.management.name.split(' ')[2] || '';
                this.body.director_surname = surname;
                this.body.director_patronymic = patronymic;
            }
        }

        this.mayAdd = true;
    }

    ngOnDestroy(): void {
    }

    public returnToLogin(): void {
        localStorage.removeItem('user_info');
        localStorage.removeItem('current_org');
        if (this.user && this.user.esia_authorized && confirm('Выйти из учетной записи ESIA?')) {
            this.auth.logout(true).pipe(takeUntil(componentDestroyed(this))).subscribe();
        } else {
            this.auth.logout().pipe(takeUntil(componentDestroyed(this))).subscribe();
        }
    }

    public suggestCompany(data) {
        this.serializerDadata(data);
    }
}
