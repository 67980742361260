import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EClassSize, EInputType, IControlsInfo, LabelPositionType } from '../../../../providers/_models/entity.model';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: '[app-select-control]',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss'],
})
export class SelectControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;
  @Input() options: any[];
  @Input() fieldSizeClass = 'rpn-input-group__field_md';

  get shownOptions() {
    if (this.info && this.info.filters) {
      const newOptions = (this.options || []).filter(i => this.info.filters(this.fGroup, i));

      if (this.options && newOptions.length !== this.options.length) {
        return newOptions;
      }
    }

    return this.options;
  }

  get control() {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  get canEdit(): boolean {
    if (!this.isEnabled) {
      return false;
    }

    if (this.info && this.info.isDisabled && this.fGroup) {
      return !this.info.isDisabled(this.fGroup);
    }

    return true;
  }

  get showError() {
    return this.control && this.control.invalid && this.canEdit;
  }

  public random = Math.random();
  public sizeClass = EClassSize.full;
  public labelPosition = LabelPositionType.top;
  public select = '-';

  public LabelPositionType = LabelPositionType;

  private options$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  private control$: BehaviorSubject<any>;

  // tslint:disable-next-line:variable-name
  private _subs: Subscription[] = [];
  set subs(sub) {
    this._subs.push(sub);
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.control) {
      return;
    }

    this.subs = combineLatest(this.control$, this.options$)
      .pipe(
        debounceTime(150),
        filter(([value, options]) => !!options),
      )
      .subscribe(([value, options]) => {
        const item = options.find(x => '' + x.id === '' + value);
        if (item) {
          this.select = item.description ? item.description : item.full_name ? item.full_name : item.name;
        } else {
          this.select = '-';
        }
        this.cdr.detectChanges();
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.info) {
      this.sizeClass = (this.info && this.info.sizeClass) || EClassSize.full;
      this.labelPosition = (this.info && this.info.labelPosition) || LabelPositionType.top;
    }

    if (simpleChanges.options) {
      this.options$.next(this.options);
    }

    if (simpleChanges.fGroup) {
      const a = this.control.value;
      this.control.setValue('' + a === 'true' ? true : '' + a === 'false' ? false : a, { emitEvent: false });
      this.control$ = new BehaviorSubject<any>(this.control.value);
      this.subs = this.control.valueChanges.subscribe(res => {
        if (this.info && this.info.inputType === EInputType.boolean) {
          this.control.setValue(res === 'true', { emitEvent: false });
        }

        if (!isNaN(res) && res !== null && '' + res !== 'false' && '' + res !== 'true') {
          this.control.setValue(+res, { emitEvent: false });
        }

        this.control$.next(res);

        if (this.info && this.info.onClick) {
          this.info.onClick(this.fGroup);
        }

        // TODO Remove for Postgres START
        if (this.info && this.info.saveJSON && this.key.indexOf('_id') > -1) {
          const jsonItem = this.fGroup.controls[this.key.replace('_id', '')];

          if (jsonItem) {
            jsonItem.setValue(JSON.stringify(this.getSelectedItem()), { emitEvent: false });
          }
        }
        // TODO Remove for Postgres END
      });
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(s => s.unsubscribe());
  }

  public getSelectedItem(): any {
    if (!this.control || !this.options) {
      return null;
    }

    const a = this.options.find(item => '' + item.id === '' + this.control.value);
    return a;
  }

  public defaultFormatItem(item: any): string {
    return item.description ? item.description : item.full_name ? item.full_name : item.name;
  }
}
