export interface IDictionaryItem {
  id: string | number | boolean;
  name: string;
  full_name?: string;
  code?: string;
}

export enum EDictionaries {
  air_pollutants_with_taxes = 'air_pollutants_with_taxes',
  pollutants_with_taxes = 'pollutants_with_taxes',
  coefficient_kot = 'coefficient_kot',
  coefficient_k = 'coefficient_k',
  coefficient_kvo = 'coefficient_kvo',
  fkkos = 'fkkos',
  wasteHazardClasses_declaration = 'wasteHazardClasses_declaration',
  wasteHazardClasses_env_air = 'wasteHazardClasses_env_air',
  wasteHazardClasses_env = 'wasteHazardClasses_env',
  coefficient_kisp = 'coefficient_kisp',
  coefficient_kod = 'coefficient_kod',
  coefficient_kpo = 'coefficient_kpo',
  coefficient_kst = 'coefficient_kst',
  wasteHazardClasses = 'wasteHazardClasses',
}
