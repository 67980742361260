import { Pipe, PipeTransform } from '@angular/core';
import { EStatement, STATEMENT_NAMES } from '../../interfaces/statement/statement.interface';

@Pipe({
  name: 'statementName',
})
export class StatementNamePipe implements PipeTransform {
  transform(value: EStatement, ...args: any[]): any {
    return STATEMENT_NAMES[value] || '';
  }
}
