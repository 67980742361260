import { NgModule } from '@angular/core';
import { KeysByOrder } from './keys.by.order';
import { TrimPipe } from './trim.pipe';
import { PaddingPipe } from './padding.pipe';
import { ToUTCPipe } from './utc.date';
import { StatementNamePipe } from './statement-name.pipe';
import { StatusNamePipe } from './status-name.pipe';

@NgModule({
  declarations: [KeysByOrder, TrimPipe, PaddingPipe, ToUTCPipe, StatementNamePipe, StatusNamePipe],
  exports: [KeysByOrder, TrimPipe, PaddingPipe, ToUTCPipe, StatementNamePipe, StatusNamePipe],
})
export class PipesModule {}
