import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { OperatorsComponentModule } from './operators/operators.component.module';


@NgModule({
  declarations: [
    MainComponent,
  ],
  imports: [
    MainRoutingModule,
    OperatorsComponentModule
  ]
})
export class MainComponentModule {
}
