import { Pipe, PipeTransform } from '@angular/core';
import { EStatementStatusCode } from '../../interfaces/statement/statement.interface';
import { STATUS_NAMES } from 'src/app/constants/request-statuses.constants';

@Pipe({
  name: 'statusName',
})
export class StatusNamePipe implements PipeTransform {
  transform(value: EStatementStatusCode, ...args: any[]): any {
    return STATUS_NAMES[value] || '';
  }
}
