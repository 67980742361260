import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

export class PtcAuthGuard implements CanActivate {
  constructor(private router: Router, private toastr: ToastrService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    let user = null;
    let org = null;
    try {
      user = JSON.parse(localStorage.getItem('user_info'));
    } catch (err) { }
    try {
      org = JSON.parse(localStorage.getItem('current_org'));
    } catch (err) { }

    const res = user.esia_authorized && user.profile && user.profile.organization && user.profile.organization.territory_org;
    if (!res) {
      this.toastr.error('', 'Нет доступа', { timeOut: 6000 });
      this.router.navigate(['/logout']);
    }
    return !!res;
  }
}
