import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { EsignModalService } from './esign.modal.service';

@Component({
  selector: 'app-esign-modal',
  templateUrl: './esign.modal.component.html',
  styleUrls: ['./esign.modal.component.scss']
})
export class EsignModalComponent implements OnInit, OnChanges {
  @Input()  eSign: {[key: string]: any};

  constructor(
    private esignModalService: EsignModalService
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  closeEsignModal(): void {
    this.esignModalService.triggerEsignModal.next(false);
  }

}
