import { AuthenticationService } from './../../auth/services/authentication.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService, private toastr: ToastrService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    let user = null;
    let org = null;
    try {
      user = JSON.parse(localStorage.getItem('user_info'));
    } catch (err) { }
    try {
      org = JSON.parse(localStorage.getItem('current_org'));
    } catch (err) { }

    if (!user) {
      return this.authenticationService.getInfoMe().pipe(
        map(res => {
          if (!res) {
            localStorage.removeItem('user_info');
            localStorage.removeItem('current_org');
            this.router.navigate(['/login']);
          } else {
            this.router.navigate(['/']);
          }
          return !!res;
        }),
      );
    } else {
      if (environment.isPTC) {
        const isAdmin = user.email === 'admin@localhost';

        if (!isAdmin && org && !org.territory_org) {
          localStorage.removeItem('user_info');
          localStorage.removeItem('current_org');
          this.router.navigate(['/login']);
          this.toastr.error('', 'Попытка входа под организацией, не являющейся территориальным органом', { timeOut: 6000 });
          return false;
        }
      }

      if (!org) {
        return this.authenticationService.getMeOrg().pipe(
          map(res => {
            if (!((user && user.esia_authorized) || (res.data && res.data.length))) {
              this.router.navigate(['/registration']);
            } else {
              this.router.navigate(['/company']);
            }
            return false;
          }),
        );
      } else {
        return true;
      }
    }
  }
}
