import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { first, mergeAll, pluck, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  public getAddressSuggest(query, type: string): Observable<any> {
    return this.http.post(
      `${environment.ADDRESS_SUGGEST_URL}/suggestions/api/4_1/rs/suggest/${type}`,
      JSON.stringify({ query }),
      this.httpOptions,
    );
  }

  public getDadataStat(): Observable<any> {
    const d = new Date();
    const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(`${environment.ADDRESS_DADATA_STAT_URL}/api/v2/stat/daily?date=${date}`, httpOptions);
  }

  public setFormControlValue(formGroup: FormGroup, key: string, item: { [key: string]: any }): void {
    formGroup.controls[key].setValue(item.value);
    formGroup.controls[key].markAsDirty();
    formGroup.controls.zip_code.setValue(item.data.postal_code);
    formGroup.controls.zip_code.markAsDirty();
  }

  public initFormValue(formGroup: FormGroup, key: string): void {
    if (formGroup.controls[key].value) {
      formGroup.controls[key].setValue(formGroup.controls[key].value);
    }
  }
}
