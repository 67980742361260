export interface ITokenInfo {
  email: string;
  role: string;
  access_token: string;
  expires_in: number;
  token_type: string;
}

export enum ERoles {
  employee = 'Сотрудник',
  user = 'Пользователь'
}

export interface IInformationMe {
  created_at: string;
  email: string;
  esia_authorized: boolean;
  esia_oid: number;
  full_name: string;
  id: number;
  inn: any;
  name: string;
  passport: any;
  patronymic: string;
  photo: any;
  photos: any;
  surname: string;
  updated_at: string;
  messages_received_unread_count: number;
  notifications_count: number;
}

export interface IMessage {
  reply_user_message_id?: number;
  rpn_service_request_id?: number;
  user_id: number;
  to_user_id: number;
  subject?: string;
  message: string;
  message_attachments?: any[];
  read_at?: string;
}
