import {Component, Input, OnInit} from '@angular/core';
import {IFormInfo} from 'src/app/providers/_models/entity.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() formsInfo: IFormInfo;
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() isEnabled: boolean;

  constructor() {
  }

  ngOnInit() {
  }
}
