import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page.not.found.component.html',
  styleUrls: ['./page.not.found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }

  public returnToMain(): void {
    this.router.navigate([`/`]);
  }

  public returnToPreview(): void {
    this.location.back();
  }

}
