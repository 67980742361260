import { ENameOfSystem } from 'src/app/providers/_models/entity.model';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { IPagination } from 'src/app/providers/_models/pagination.model';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
  @ViewChild('orgList', { static: false }) orgList: ElementRef;
  user;
  orgs;
  returnUrl;

  public pagination: IPagination = {
    current_page: 1,
    last_page: 1,
    per_page: 25,
    total: 0,
  };

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnDestroy(): void { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.returnUrl =
      this.returnUrl !== '/logout' && this.returnUrl !== '/company' && this.returnUrl !== '/registration' ? this.returnUrl : '/';

    try {
      this.user = JSON.parse(localStorage.getItem('user_info'));
    } catch (err) {
      // console.log(err);
    }
    if (this.user) {
      this.fetchOrgs();
    }
  }

  selectOrg(org): void {
    this.authenticationService
      .selectOrg(org.id)
      .pipe(map(({ organization }) => organization))
      .subscribe(res => {
        if (environment.isPTC) {
          localStorage.setItem('current_org', JSON.stringify(res));

          this.authenticationService.getInfoMe().subscribe(() => {
            this.router.navigate([this.returnUrl]);
          });

          return;
        }

        localStorage.setItem('current_org', JSON.stringify(res));
        this.router.navigate([this.returnUrl]);
      });
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('user_info');
    localStorage.removeItem('current_org');
  }

  public returnToLogin(): void {
    this.clearLocalStorage();
    if (this.user && this.user.esia_authorized && confirm('Выйти из учетной записи ESIA?')) {
      this.authenticationService
        .logout(true)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();
    } else {
      this.authenticationService
        .logout()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();
    }
  }

  private fetchOrgs(data?): void {
    this.authenticationService
      .getMeOrg(data ? { per_page: data.per_page, page: data.current_page } : null)
      .pipe()
      .subscribe(res => {
        if (!this.user.esia_authorized && !(res.data && res.data.length)) {
          this.router.navigate(['/registration']);
        } else {
          this.orgs = res.data || [];
          this.pagination = { ...{}, ...res };
        }
      });
  }

  public gotoRegistration() {
    this.clearLocalStorage();
    this.router.navigate(['/registration']);
  }

  public gotoCompanyRegistration() {
    localStorage.removeItem('current_org');
    this.router.navigate(['/registration']);
  }

  public paginationChange(data): void {
    this.fetchOrgs(data);
    this.scrollOrgListToTop();
  }

  private scrollOrgListToTop() {
    this.orgList.nativeElement.scrollTo(0, 0);
  }
}
