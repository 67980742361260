import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() header: string;
  // Закрывает модалку при клике в любое место
  @Input() closeOnClick = false;
  @Input() skim = false;

  @Output() closeModal = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  close() {
    this.closeModal.emit(true);
  }
}
