import { IDictionaryStateService } from 'src/app/interfaces/dictionary/dictionary-state-service';
import { IJobStatus } from 'src/app/interfaces/job/job.interface';

export enum EStatement {
  REQUEST = 'request',
  REPORT = 'report',
}

export const STATEMENT_NAMES = {
  [EStatement.REQUEST]: 'заявление',
  [EStatement.REPORT]: 'отчет',
};

export enum EStatementType {
  PAYMENT_NVOS_REQUEST = 'payment_nvos_request',
  PAYMENT_NVOS_REQUEST_CORRECTION = 'payment_nvos_request_correction',
  PAYMENT_NVOS_REQUEST_KSV = 'payment_nvos_request_ksv',
  PAYMENT_NVOS_REQUEST_KSV_TOTAL = 'payment_nvos_request_ksv_total',
}

export enum EStatementModel {
  PAYMENT_NVOS_REQUEST = 'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequest',
  PAYMENT_NVOS_REQUEST_CORRECTION = 'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequestCorrection',
  PAYMENT_NVOS_REQUEST_KSV = 'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequestKsv',
  PAYMENT_NVOS_REQUEST_KSV_TOTAL = 'Rpn\\Services\\PaymentNvos\\Models\\PaymentNvosRequestKsvTotal',
}

export enum EStatementServiceId {
  ONV_REQUEST_INCLUSION = 21,
  PAYMENT_NVOS_REQUEST = 61,
  PAYMENT_NVOS_REQUEST_CORRECTION = 72,
  PAYMENT_NVOS_REQUEST_KSV = 73,
  PAYMENT_NVOS_REQUEST_KSV_TOTAL = 74,
  KER = 55,
}

export function getStatementTypeByModel(model: EStatementModel): EStatementType {
  const modelKey = Object.keys(EStatementModel).find(key => EStatementModel[key] === model);
  return EStatementType[modelKey] || null;
}

export interface IStatementCallCreateRequest {
  territory_org_id?: number;
}

export interface IStatementCreateRequest extends IStatementCallCreateRequest {
  cost: number;
  status_code: EStatementStatusCode;
  term_days: number;
}

export interface IStatementCreateResponse {
  id: number;
}

export enum EStatementStatusCode {
  DRAFT = 'draft',
  FROM_EPGU = 'from_epgu',
  SELECT_DOER = 'select_doer',
  REVIEW = 'review',
  IN_APPROVAL = 'in_approval',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
}

interface IStatementBase {
  // status_id: number; NOT USED
  advanced_documents_comment: string;
  applicant: IStatementApplicant;
  applicant_id: number;
  applicant_information: IStatementApplicantInformation;
  applicant_organization_id: number;
  application_method_id: number;
  child_id: number; // TODO Reply ask to realized
  control_date: string;
  cost: number;
  created_at: string;
  date: string;
  doc_type: number;
  document_attachment: null;
  document_permission_attachments: any[];
  doer: any; // TODO
  doer_id: number;
  done_at: string;
  id: number;
  is_complete: boolean;
  is_editable: boolean; // TODO Ask?
  is_imported: boolean;
  municipality_id: number;
  next_statuses: IStatementNextStatus[];
  needSign?: boolean; // internal frontend option for request http handler - TODO Rewrite
  number: string;
  onv_id: number;
  parent_id: number;
  payed: boolean;
  payed_at: string;
  payed_bills_attachments: any[];
  payed_bills_attachments_files: any[]; // TODO
  region_id: number;
  registered_at: string;
  report_explanations_attachments: any[];
  report_year: number;
  result_comment: string;
  mg: {
    collection: string; // requests | reports
    id: string; // mongo_Id
    service: string; // payment_negative_impact
    url: string; // /api/prototype/requests/payment_negative_impact/${mongo_Id}
  };
  sent_at: string;
  signed_http_request_id: number;
  signed_request: IStatementSignedRequest;
  state_service: IDictionaryStateService;
  state_service_id: number;
  status_code: EStatementStatusCode;
  term_days: number;
  territory_org_id: number;
  totals: any[]; // TODO What?
  type: EStatementModel;
  updated_at: string;
}

export interface IStatementBackend extends IStatementBase {
  advanced_documents_attachments: number[];
}

export interface IStatement extends IStatementBase {
  advanced_documents_attachments: null;
}

export interface IStatementApplicant {
  full_name: string;
  id: number;
  inn: string;
  name: string;
  patronymic: string;
  surname: string;
}

export interface IStatementApplicantInformation {
  account: string;
  accountant_details: string;
  accountant_email: string;
  accountant_name: string;
  accountant_patronymic: string;
  accountant_phone: string;
  accountant_position: string;
  accountant_surname: string;
  address: any; // TODO
  bank: string;
  bik: string;
  brand: string;
  ceo_details: string;
  ceo_email: string;
  ceo_name: string;
  ceo_patronymic: string;
  ceo_phone: string;
  ceo_position: number;
  ceo_surname: string;
  citizen_country_id: number;
  code_okpo: string;
  correspondent_account: string;
  country_id: number;
  created_at: string;
  egrul_egrip_date: string;
  egrul_egrip_number: string;
  email: string;
  full_name: string;
  full_name_organization: string;
  id: number;
  inn: string;
  is_complete: boolean;
  is_importer_of_goods: boolean;
  is_legal_agent: boolean;
  is_non_resident: boolean;
  is_producer_of_goods: boolean;
  is_separate: boolean;
  kpp: string;
  legal_address_city: string;
  legal_address_oktmo: string;
  legal_address_post: string;
  legal_address_street: string;
  name: string;
  ogrn: string;
  okved: string;
  okveds: any[]; // TODO
  part_of_association: string;
  passport: string;
  patronymic: string;
  phone: string;
  post_address_city: string;
  post_address_oktmo: string;
  post_address_post: string;
  post_address_street: string;
  register_date: string;
  service_request_id: number;
  short_name_organization: string;
  surname: string;
  tax_info: string;
  updated_at: string;
  work_position: string;
  zip_code: any; // TODO
}

export interface IStatementNextStatus {
  code: EStatementStatusCode;
  id: number;
  name: string;
  state_service_id: number;
}

export interface IStatementSignedRequest {
  // TODO
  signed_info: {
    signatureTypeSN: string;
    subject: {
      CN: string;
      O: string;
    };
    validFrom_time_t: string;
    validTo_time_t: string;
  };
}

export interface IStatementSection {
  created_at: string;
  id: number;
  is_enabled: boolean;
  name: EStatementSectionName;
  number: number;
  service_request_id: number;
  updated_at: string;
}

export enum EStatementSectionName {
  air_stationary = 'air_stationary',
  air_torch_underlimit = 'air_torch_underlimit',
  air_torch_overlimit = 'air_torch_overlimit',
  water = 'water',
  waste_production = 'waste_production',
  waste_solid = 'waste_solid',
}

export interface IStatementAttachment {
  id: number;
  name: string;
  file_name: string;
  extension: string;
  has_thumb: boolean;
  has_pdf: boolean;
  size: number;
  created_at: string;
  updated_at: string;
  download_link: string;
  thumb_link: string;
  pdf_link: string;
  formatted_size: string;
}

export interface IStatementListItem {
  applicant_information: IStatementApplicantInformation;
  control_date: string;
  id: number;
  type: EStatementModel;
  result_comment: string;
  sent_at: string;
  status: IStatementNextStatus;
  status_code: EStatementStatusCode;
  status_id: number; // it's here only because backend doesn't fill status if status_id not set in 'fields' parameter
  is_imported: boolean;
  mg: {
    collection: string; // requests | reports
    id: string; // mongo_Id
    service: string; // payment_negative_impact
    url: string; // /api/prototype/requests/payment_negative_impact/${mongo_Id}
  };
}

export type IStatementImportJobStatus = IJobStatus<{ request_id: number }>;
export type IStatementExportJobStatus = IJobStatus<{ link: string }>;

export interface IStatementError {
  message: string;
  action?: () => void;
}

export enum ETotalsGetStatus {
  get_data = 'get_data',
  get_status_link = 'get_status_link',
}
