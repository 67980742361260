import {Component, Input, OnInit} from '@angular/core';
import {IFormInfo} from 'src/app/providers/_models/entity.model';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() isEnabled: string;

  constructor() {
  }

  ngOnInit() {
  }
}
