import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { takeUntil, filter } from 'rxjs/operators';
import { componentDestroyed, getDateFromInputValue } from 'src/app/providers/_utils/utils';
import { IFormInfo, EClassSize, LabelPositionType, GosUslugiType } from 'src/app/providers/_models/entity.model';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { HeaderService } from 'src/app/providers/_services/header.service';

@Component({
  selector: 'app-bid-status',
  templateUrl: './bid.status.component.html',
  styleUrls: ['./bid.status.component.scss'],
})
export class BidStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() isEnabled: boolean;
  public options = [];
  public select = '';
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public sizeClass = EClassSize.full; // full / half / third
  public labelPosition = LabelPositionType.left; // left / top / inside /doNot
  public statusDict = [];
  public isCabinet = true;

  private options$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public control$: BehaviorSubject<any>;
  get dateValue(): Date {
    return getDateFromInputValue(this.formsInfo.controlsInfo[this.key].additionInfo);
  }

  constructor(private dictionaryService: DictionaryService, private headerService: HeaderService, private router: Router) {}

  ngOnInit(): void {
    this.isCabinet = !!(this.router.url.indexOf('cabinet') + 1);

    combineLatest(this.control$, this.options$)
      .pipe(
        filter(data => data.every(d => !!d)),
        takeUntil(componentDestroyed(this)),
      )
      .subscribe(([value, options]) => {
        console.log(value, options);
        const item = options.find(x => '' + x.code === '' + value);
        if (item) {
          this.select = item.description ? item.description : item.full_name ? item.full_name : item.name;
        } else {
          this.select = '-';
        }
        console.log(this.select);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formsInfo && this.formsInfo && changes.key && this.key) {
      if (this.formsInfo.controlsInfo[this.key].sizeClass) {
        this.sizeClass = this.formsInfo.controlsInfo[this.key].sizeClass;
      }
      if (this.formsInfo.controlsInfo[this.key].labelPosition) {
        this.labelPosition = this.formsInfo.controlsInfo[this.key].labelPosition;
      }
      const fromResponse =
        this.formsInfo && this.formsInfo.formGroup && this.formsInfo.formGroup.controls.next_statuses
          ? this.formsInfo.formGroup.controls.next_statuses.value
          : null;

      if (fromResponse && fromResponse.length) {
        this.options = fromResponse;
        this.options$.next(this.options);
      } else {
        const dictKey = this.formsInfo.controlsInfo[this.key].dictKey || this.key;

        if (this.dictionaryService[dictKey]) {
          this.dictionaryService[dictKey]
            .pipe(
              filter(x => !!x),
              takeUntil(componentDestroyed(this)),
            )
            .subscribe(res => {
              this.options = res;
              this.options$.next(this.options);
            });
        }
      }

      this.control$ = new BehaviorSubject<any>(
        this.formsInfo && this.formsInfo.formGroup ? this.formsInfo.formGroup.controls.status_code.value : null,
      );

      this.formsInfo.formGroup.controls.status_code.valueChanges.pipe(takeUntil(componentDestroyed(this))).subscribe(status_code => {
        this.control$.next(status_code);
        this.headerService.changeStatus.next(status_code);
      });
    }
  }

  get classColor(): any {
    if (this.formsInfo && this.formsInfo.formGroup) {
      const find = this.options.find(item => item.code === this.formsInfo.formGroup.controls[this.key].value);
      return find;
    }
    return null;
  }
}
