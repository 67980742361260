import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { takeUntil, filter } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { IFormInfo, LabelPositionType, EClassSize } from 'src/app/providers/_models/entity.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radiobox',
  templateUrl: './radiobox.component.html',
  styleUrls: ['./radiobox.component.scss'],
})
export class RadioboxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() isEnabled: boolean;
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public sizeClass = EClassSize.full; // full / half / third
  public labelPosition = LabelPositionType.top; // left / top / inside /doNot;
  public options = [];
  public select = '';

  constructor(private dictionaryService: DictionaryService) { }

  ngOnDestroy(): void { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.formsInfo && this.formsInfo && changes.key && this.key) {
      const info = this.formsInfo.controlsInfo[this.key];
      const dictKey = info.dictKey || this.key;

      if (this.dictionaryService[dictKey]) {
        this.dictionaryService[dictKey]
          .pipe(
            filter(x => !!x),
            takeUntil(componentDestroyed(this))
          )
          .subscribe((res) => {

            this.options = (res || []).slice();

            if (info.selectClearItem) {
              this.options.unshift({
                ...info.selectClearItem,
              });
            }

          });
      }

    }

  }
}
