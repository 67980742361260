import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EClassSize, IControlsInfo, LabelPositionType } from '../../../../providers/_models/entity.model';
import { REQUEST_STATUS_DRAFT } from 'src/app/constants/request-statuses.constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
})
export class CheckboxControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;

  get control(): AbstractControl {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  public random = Math.random();
  public sizeClass = EClassSize.full;
  public labelPosition = LabelPositionType.top;
  public LabelPositionType = LabelPositionType;

  private subscription: Subscription;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.info) {
      this.sizeClass = (this.info && this.info.sizeClass) || EClassSize.full;
      this.labelPosition = (this.info && this.info.labelPosition) || LabelPositionType.top;

      if (this.info && this.info.onClick && this.control) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }

        this.subscription = this.control.valueChanges.subscribe(value => this.info.onClick(this.fGroup, value));
      }
    }

    if (simpleChanges.isEnabled) {
      if (this.isEnabled) {
        this.fGroup.controls[this.key].enable({ emitEvent: false });
      } else {
        this.fGroup.controls[this.key].disable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
