import { PipesModule } from 'src/app/providers/_pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { ComponentsModule } from 'src/app/components/components.module';


@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    PipesModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderComponentModule { }
