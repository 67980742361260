import { EStatementStatusCode } from 'src/app/interfaces/statement/statement.interface';

export const REQUEST_STATUS_DRAFT = { id: 1, code: EStatementStatusCode.DRAFT, name: 'Черновик' };
export const REQUEST_STATUS_FROM_EPGU = { id: 2, code: EStatementStatusCode.FROM_EPGU, name: 'Заявка из ЕПГУ' };
export const REQUEST_STATUS_SELECT_DOER = { id: 3, code: EStatementStatusCode.SELECT_DOER, name: 'Назначение исполнителя' };
export const REQUEST_STATUS_REVIEW = { id: 4, code: EStatementStatusCode.REVIEW, name: 'Отправлено на рассмотрение' };
export const REQUEST_STATUS_IN_APPROVAL = { id: 5, code: EStatementStatusCode.IN_APPROVAL, name: 'На согласовании' };
export const REQUEST_STATUS_ACCEPTED = { id: 6, code: EStatementStatusCode.ACCEPTED, name: 'Принято' };
export const REQUEST_STATUS_REJECTED = { id: 7, code: EStatementStatusCode.REJECTED, name: 'Отказ' };
export const REQUEST_STATUS_EXPIRED = { id: 8, code: EStatementStatusCode.EXPIRED, name: 'Не актуальна - подана корректировка' };

export const STATUS_NAMES = {
  [REQUEST_STATUS_DRAFT.code]: REQUEST_STATUS_DRAFT.name,
  [REQUEST_STATUS_FROM_EPGU.code]: REQUEST_STATUS_FROM_EPGU.name,
  [REQUEST_STATUS_SELECT_DOER.code]: REQUEST_STATUS_SELECT_DOER.name,
  [REQUEST_STATUS_REVIEW.code]: REQUEST_STATUS_REVIEW.name,
  [REQUEST_STATUS_IN_APPROVAL.code]: REQUEST_STATUS_IN_APPROVAL.name,
  [REQUEST_STATUS_ACCEPTED.code]: REQUEST_STATUS_ACCEPTED.name,
  [REQUEST_STATUS_REJECTED.code]: REQUEST_STATUS_REJECTED.name,
  [REQUEST_STATUS_EXPIRED.code]: REQUEST_STATUS_EXPIRED.name,
};
