import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'padding'})
export class PaddingPipe implements PipeTransform {
    transform(item: any, length: number, character: string = ' '): string {
      let s = item !== undefined ? item + '' : '';
      while (s.length < length) { s = character + s; }
      return s;
    }
}
