import { Type } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SectionCustomFormComponent } from '../../components/section-custom-form/section-custom-form.component';
import { IDictionaryItem } from '../../interfaces/dictionary.interface';
import { ITableModel } from './table.model';
import { EStatementType } from '../../interfaces/statement/statement.interface';

export interface IRequestButton {
  label: string;
  tooltip: string;
  disabled?: boolean;
  action: () => void;
}

export interface IDADATAResponse {
  suggestions: IDADATASuggestion[];
}

export interface IDADATASuggestion {
  value: string;
  unrestricted_value: string;
  data: {
    kpp: string;
    capital: { type: string; value: number };
    management: {
      name: string;
      post: string;
      disqualified: boolean;
    };
    founders: any[];
    managers: {
      inn: string;
      fio: {
        surname: string;
        name: string;
        patronymic: string;
        gender: string;
        source: string;
        qc: string;
      };
      post: string;
      hid: string;
      type: string;
    }[];
    branch_type: string;
    branch_count: number;
    source: null;
    qc: null;
    hid: string;
    type: string;
    state: {
      status: string;
      actuality_date: number;
      registration_date: number;
      liquidation_date: null;
    };
    opf: {
      type: string;
      code: string;
      full: string;
      short: string;
    };
    name: {
      full_with_opf: string;
      short_with_opf: string;
      latin: string;
      full: string;
      short: string;
    };
    inn: string;
    ogrn: string;
    okpo: string;
    okato: string;
    okved: string;
    okveds: {
      main: boolean;
      type: string;
      code: string;
      name: string;
    }[];
    authorities: any;
    documents: {
      fts_registration: any;
      pf_registration: any;
      sif_registration: any;
      smb: any;
    };
    licenses: any[];
    finance: {
      tax_system: string;
      income: number;
      expense: number;
      debt: string;
      penalty: string;
    };
    address: {
      value: string;
      unrestricted_value: string;
      data: any;
    };
    phones: null;
    emails: null;
    ogrn_date: number;
    okved_type: string;
    employee_count: number;
  };
}

export enum EInputType {
  color = 'color', // Виджет для выбора цвета.
  date = 'date', // Поле для выбора календарной даты.
  datetime = 'datetime', // Указание даты и времени.
  'datetime-local' = 'datetime-local', // Указание местной даты и времени.
  email = 'email', // Для адресов электронной почты.
  number = 'number', // Ввод чисел.
  boolean = 'boolean', // Ввод true/false.
  numberAndDash = 'text', // Ввод чисел и тире
  optionalNumber = 'optional-number', // Ввод чисел или -
  range = 'range', // Ползунок для выбора чисел в указанном диапазоне.
  search = 'search', // Поле для поиска.
  tel = 'tel', // Для телефонных номеров.
  time = 'time', // Для времени.
  url = 'url', // Для веб-адресов.
  month = 'month', // Выбор месяца.
  week = 'week', // Выбор недели.
}
export interface IFormInit {
  getForm(entity?: IBid): IFormInfo;
}
export enum ETypeOfSystem {
  'cabinet' = 'cabinet',
  'ptc-goscontrol' = 'ptc-goscontrol',
  'pto-uonvos' = 'pto-uonvos',
}
export const ENameOfSystem = {
  'pto-uonvos': 'ПТО УОНВОС',
  'ptc-goscontrol': 'ПТК Госконтроль',
  cabinet: 'Личный кабинет Природопользователя',
  'operators-page': 'Операторам ЭДО и разработчикам ПО: Форматы отчетов и требования к ЭП',
};

export const ETypeOfPage = {
  reports: 'reports',
  requests: 'requests',
  faq: 'faq',
  onvos: 'onvos',
  objects: 'objects',
  history: 'history',
  chat: 'chat',
  documents: 'documents',
};

export const ENameOfEntity = {
  requests: 'Реестр заявлений',
  ker: 'Заявления КЭР',
  pni: 'Декларации о плате за НВОС', // it's used in ksv
  payment_negative_impact: 'Декларация о плате за негативное воздействие на окружающую среду', // it's used in cabinet
  reports: 'Реестр отчетов',
  onvos: 'Реестр объектов НВОС',
  documents: 'Реестр документов',
  faq: 'FAQ',
  repformsros: '',
};

export const ENameOfPageShot = {
  requests: 'Заявление',
  reports: 'Отчет',
  onvos: 'Объекты НВОС',
  documents: 'Документ',
};

export const ENameOfPageLong = {
  requests: 'Мои Заявления',
  reports: 'Мои Отчеты',
  onvos: 'Мои Объекты',
  documents: 'Мои Документы',
};

export interface ITypeExportImport {
  value: string;
  label: string;
}

export const ETypeExportImport = {
  exporter: { value: 'exporter', label: 'Экспортер' },
  importer: { value: 'importer', label: 'Импортер' },
};

export const ETypeOfAddress = {
  Legal: 'legal',
  Physical: 'physical',
  Post: 'post',
};

export const EPositionOfEmployee = {
  CEO: 'ceo',
  Accountant: 'chief_accountant',
};

export const EClassSize = {
  full: '',
  half: 'row_flex_half',
  third: 'row_flex_third',
};

export const LabelPositionType = {
  top: 'top',
  left: 'left',
  leftNowrap: 'leftNowrap',
  inside: 'inside',
  doNot: 'doNot',
  onlyValue: 'onlyValue',
};

export const ButtonOnBottom = [
  { value: 'history', label: 'История обработки заявления', count: null },
  { value: 'chat', label: 'Сообщения', count: null },
  // {value: 'result_documents', label: 'Документы', count: null},
  { value: 'files', label: 'Файлы', count: null },
  { value: 'bid', label: 'Межведомственные запросы', count: null },
];

export const GosUslugiType = {
  cites: 'cites',
  nvos: 'nvos',
  turnkk: 'turnkk',
  preykk: 'preykk',
  airemissions: 'airemissions',
  airnormative: 'airnormative',
  econorm: 'econorm',
  gee: 'gee',
  ker: 'ker',
  payment_negative_impact: 'payment_negative_impact',
};

export enum ONVOSGosUslugiType {
  onvos_registration = 'onvos_registration',
  onvos_updating = 'onvos_updating',
  onvos_deregistration = 'onvos_deregistration',
  env = 'env',
  gee = 'gee',
  ker = 'ker',
}

export const ONVOSGosUslugi = environment.production
  ? {}
  : {
    onvos_registration: {
      title: 'Включение объекта негативного воздействия на окружающую среду в реестр',
      description: 'Заявление на включение объекта НВОС в реестр.',
      term_days: '10',
      cost: '',
    },
    onvos_updating: {
      title: 'Исправление описок, опечаток и арифметических ошибок в сведениях об объекте НВОС',
      description: 'Заявление на исправление описок, опечаток и арифметических ошибок в сведениях об объекте НВОС.',
      term_days: '10',
      cost: '',
    },
    onvos_deregistration: {
      title: 'Снятие с государственного учета объекта НВОС',
      description: 'Заявление на снятие с государственного учета объекта НВОС.',
      term_days: '10',
      cost: '',
    },
    env: {
      title: 'Декларация о воздействии на окружающую среду',
      description: 'Декларация о воздействии на окружающую среду',
      term_days: '10',
      cost: '',
    },
    gee: {
      title: 'Проведение государственной экологической экспертизы',
      description: `Заявление на организацию и проведение государственной экологической экспертизы федерального уровня.`,
      term_days: '10',
      cost: '',
    },
  };

const declaration = {
  title: 'Декларация о плате за негативное воздействие на окружающую среду',
  description: 'Декларация о плате за негативное воздействие на окружающую среду',
  term_days: '',
  cost: '',
};

export const GosUslugi = {
  [EStatementType.PAYMENT_NVOS_REQUEST]: { ...declaration },
  ...(environment.production
    ? {}
    : {
      // DONE
      cites: {
        title: 'Разрешение СИТЕС',
        description: `Оформление заявления на выдачу разрешения на вывоз из Российской Федерации и ввоз в
    Российскую Федерацию видов дикой фауны и флоры, находящихся под угрозой исчезновения, их частей или деривантов,
    подпадающих под действие Конвенции о международной торговле видами дикой фауны и флоры, находящимися под угрозой исчезновения,
    кроме осетровых видов рыб.`,
        term_days: '30',
        cost: '200000',
      },
      nvos: {
        title: 'Заявление о постановке объекта НВОС на государственный учет',
        description: 'Заявление на постановку объекта НВОС на государственный учет.',
        term_days: '10',
        cost: '',
      },
      gee: {
        title: 'Проведение государственной экологической экспертизы',
        description: `Заявление на организацию и проведение государственной экологической экспертизы федерального уровня.`,
        term_days: '10',
        cost: '',
      },
      turnkk: {
        title: `Разрешение (распорядительная лицензия) на оборот диких животных, принадлежащих к видам, занесенным в Красную книгу
    Российской Федерации`,
        description: `Разрешение на оборот диких животных, занесенных в Красную книгу.`,
        term_days: '30',
        cost: '',
      },
      preykk: {
        title: `Разрешение на добывание объектов животного и растительного мира, занесенных в Красную книгу Российской Федерации`,
        description: `Разрешение на добывание объектов животного и растительного мира, занесенных в Красную книгу.`,
        term_days: '30',
        cost: '',
      },
      airemissions: {
        title: 'Разрешение на выбросы вредных (загрязняющих) веществ в атмосферный воздух (за исключением радиоактивных веществ)',
        description: 'Заявление на получения разрешения на выбросы вредных веществ.',
        term_days: '30',
        cost: '200000',
      },
      airnormative: {
        title:
          'Установление нормативов выбросов вредных (загрязняющих) веществ в атмосферный воздух (за исключением радиоактивных веществ)',
        description: 'Заявление на установление нормативов выбросов вредных веществ.',
        term_days: '30',
        cost: '200000',
      },

      econorm: {
        title: 'Заключение о соответствии экологическим нормам',
        description: ` Выдача заключений о соответствии экологическим нормам и требованиям производственных и складских помещений организаций,
    осуществляющих деятельность, связанную с производством и оборотом этилового спирта, алкогольной и спиртсодержащей продукции.`,
        term_days: '20',
        cost: '',
      },

      ker: {
        title: 'Комплексное экологическое разрешение',
        description: 'Комплексное экологическое разрешение',
        term_days: '30',
        cost: '600000',
      },
    }),
};

export const GosUslugiAll = {
  ...GosUslugi,
  ...Object.keys(EStatementType)
    .filter(key => {
      return ('' + EStatementType[key]).indexOf('' + EStatementType.PAYMENT_NVOS_REQUEST) === 0;
    })
    .reduce((obj, key) => {
      obj[EStatementType[key]] = { ...declaration };
      return obj;
    }, {}),
};

export const ReportsType = {
  report_2_tp_air: 'report_2_tp_air',
  report_2_tp_waste: 'report_2_tp_waste',
  report_2_tp_reclamation: 'report_2_tp_reclamation',
  pek: 'pek',
  report_3_category: 'report_3_category',
  env: 'env',
};

export enum ONVOSReportsType {
  report_2_tp_air = 'report_2_tp_air',
  report_2_tp_reclamation = 'report_2_tp_reclamation',
  pek = 'pek',
  report_3_category = 'report_3_category',
}

export const Reports: { [key: string]: { title: string; description: string } } = {
  ...{
    report_2_tp_air: {
      title: 'Отчет 2ТП Воздух',
      description: `Сведения об охране атмосферного воздуха (отчёт по форме 2-ТП (воздух))`,
    },
    report_2_tp_waste: {
      title: 'Отчет 2ТП Отходы',
      description: `Сведения об образовании, обработке, утилизации, обезвреживании размещении отходов
        производства и потребления (отчёт по форме 2-ТП (отходы)`,
    },
    report_2_tp_reclamation: {
      title: 'Отчет 2ТП Рекультивация',
      description: `Сведения о рекультивации земель, снятии и использовании плодородного слоя почвы`,
    },
    pek: {
      title: 'Отчет ПЭК',
      description: `Отчет о производственном экологическом контроле`,
    },
    env: {
      title: 'Декларация о воздействии на окружающую среду',
      description: `Декларация о воздействии на окружающую среду`,
    },
  },
  ...(environment.production
    ? {}
    : {
      report_3_category: {
        title: 'Отчет объекта III-категории',
        description: `Сведения об образовании, использовании (утилизации), обезвреживании,
      о размещении отходов на объектах негативного воздействия III-категории`,
      },
    }),
};

export enum EType {
  status = 'status',
  select = 'select',
  text = 'text',
  label = 'label',
  date = 'date',
  dateView = 'dateView',
  fuzzySearch = 'fuzzySearch',
  textArea = 'textArea',
  address = 'address',
  radiobox = 'radiobox',
  input = 'input',
  checkbox = 'checkbox',
  country = 'country',
  empty = 'empty',
  cites_objects = 'cites_objects',
  attachments_files = 'attachments_files',
  map = 'map',
  autocomplete_okved2 = 'autocomplete_okved2',
  autocomplete_okpd2 = 'autocomplete_okpd2',
  autocomplete_inn = 'autocomplete_inn',

  dictionary_autocomplete = 'dictionary_autocomplete',

  paymentReceipt = 'paymentReceipt',
  incomingNumber = 'incomingNumber',

  applicantButtons = 'applicantButtons',
  documentExpiration = 'documentExpiration',
  searchText = 'searchText',
  sectionName = 'sectionName',
  sectionComponent = 'sectionComponent',

  template = 'template',
  scrollTable = 'scrollTable',
  dialog = 'dialog',
  info = 'info',
  contextMenu = 'contextMenu',
}

export interface IControlsInfo {
  initConfig?: { value: any; disabled?: boolean };
  defaultValue?: any;
  typeOfControl?: string;
  inputType?: EInputType;
  minValue?: number;
  decimalPlaces?: number;
  isShowControl?: { isInnOrg?: Observable<boolean>; isNotDuplicate?: Observable<boolean> };

  order?: number;
  row?: number;
  col?: number;

  additionInfo?: string;
  sizeClass?: string;

  labelValue?: string;
  subLabelValue?: string;
  labelPosition?: string;
  dialog?: {
    title: string;
    trueLabel?: string;
    falseLabel?: string;
    showPrompt?: (f: FormGroup, newStatus: boolean) => string;
  };

  hasNotice?: boolean;

  dictKey?: string;
  searchKey?: string;
  sendKey?: string;
  onClick?: (a?: any, b?: any) => void;
  saveJSON?: boolean;
  initFilter?: (f: FormGroup, item: IDictionaryItem) => boolean; // apply only when init options
  filters?: (f: FormGroup, item: IDictionaryItem) => boolean;
  subFormInfo?: ITableFormInfo;
  isShow?: { nameOfMarker: string; valueForCheck: any };

  dictionaryFormatItem?: (item: IDictionaryItem, formGroup?: FormGroup) => string;
  isDisabled?: (f: FormGroup) => boolean;
  onSelectAutocomplete?: (formGroup: FormGroup, item: IDictionaryItem | any) => void;
  checkBeforeSelectItem?: (formGroup: FormGroup, item: IDictionaryItem | any) => boolean; // true - success
  formInfo?: IFormInfo;
  minSearchChars?: number;
  selectClearItem?: IDictionaryItem;
  formComponent?: Type<SectionCustomFormComponent>;
  extraOptions?: { [key: string]: any };

  uniqueKeyForSaveJSON?: string;
  uniqueKeyForSaveName?: string;

  textMask?: string;
  textMaskPattern?: any;
  upperCase?: 'input' | 'blur';
  withoutControl?: boolean;
  template?: (f: FormGroup, index: number) => string | SafeHtml;
  withTime?: boolean;
  controlToDate?: (val: string) => Date;
  dateToControl?: (date: Date) => string;
}

export interface IForm {
  controls: { [key: string]: any };
  controlsInfo: IMapControlsInfo;
  formComponent?: Type<SectionCustomFormComponent>;
}

export interface IFormInfo {
  controlsInfo: IMapControlsInfo;
  formGroup: FormGroup;
  orderedKeys?: string[];
  rowsKeys?: string[][];
  onUpdateSection?: (formInfo: IFormInfo) => string[];
  onSaveSection?: (formInfo: IFormInfo) => void;
  onToggleSection?: (formInfo: IFormInfo, open: boolean) => void;
  modalLabel?: string;
  skipCheckValidation?: (formsInfo?: IMapFormInfo) => boolean;
  formComponent?: Type<SectionCustomFormComponent>;
  useCustomSave?: (formsInfo?: IFormInfo) => boolean;
  formGroupValueChange?: (fg: FormGroup) => void;
}

export interface IMapControlsInfo {
  [key: string]: IControlsInfo;
}

export interface IMapFormInfo {
  [key: string]: IFormInfo;
}

export interface IInitFormObject extends IControlsInfo {
  uniqueKey: string;
  typeOfControl?: EType;
  labelValue?: string;
  subLabelValue?: string;
  initConfig?: { value: any; disabled?: boolean };
  defaultValue?: any;
  order?: number;
  row?: number;
  col?: number;
  additionInfo?: string;
  sizeClass?: string;
  labelPosition?: string;
  hasNotice?: boolean;
  validators?: Array<Validators>;
  initFilter?: (f: FormGroup, item: IDictionaryItem) => boolean; // apply only when init options
  filters?: (fg: FormGroup, item: IDictionaryItem) => boolean;
  dictKey?: string;
  onClick?: (a?: any, b?: any, c?: any) => any;
  onUpdateSection?: (formInfo: IFormInfo) => string[];
  formComponent?: Type<SectionCustomFormComponent>;
  withoutControl?: boolean;
}

export interface ITableForm extends IForm {
  controlsInfo: IMapTableControlsInfo;
}

export interface ITableFormColumnMap {
  left?: (string | ITableFormColumnMapItem)[];
  scroll?: (string | ITableFormColumnMapItem)[];
  right?: (string | ITableFormColumnMapItem)[];
}

export interface ITableFormColumnMapItem {
  title: string;
  columns: (string | ITableFormColumnMapItem)[];
}

export enum ETablePaginationSide {
  BOTTOM = 'bottom',
  BOTH = 'both',
}

export interface ITableFormInfo extends IFormInfo {
  controls: { [key: string]: any };
  controlsInfo: IMapTableControlsInfo;
  columnKeyMap: ITableFormColumnMap;
  addItemLabel: string;
  rowFormGroupValueChange?: (formGroup: FormGroup) => void;
  subFormInfo?: IFormInfo;
  totalRow?: ITableFormTotalRow;
  type?: string;
  service_request_id?: string;
  service_request_type?: string;
  section_name?: string;
  isEnabledTable?: { isInnOrg?: Observable<boolean> };
  isEnabledColumn?: { isInnOrg?: Observable<boolean> };
  rowsPerPage?: number;
  paginationSide?: ETablePaginationSide;
}

export interface IMapTableControlsInfo {
  [key: string]: ITableControlsInfo;
}

export interface ITableFormTotalRow {
  title: string;
  columns: ITableFormTotalColumn[];
}

export interface ITableFormTotalColumn {
  uniqueKey: string;
  operator: ETableFormTotalOperator;
}

export enum ETableFormTotalOperator {
  SUM = 'SUM',
  AVG = 'AVG',
  MIN = 'MIN',
  MAX = 'MAX',
}

export interface ITableControlsInfo extends IInitFormObject {
  columnWidth?: number;
  preTemplate?: ETableControlPreTemplate;
}

export enum ETableControlPreTemplate {
  ORDER = 'ORDER',
  REMOVE = 'REMOVE',
  EDIT = 'EDIT',
  REMOVE_OUTPUT = 'REMOVE_OUTPUT',
  EDIT_OUTPUT = 'EDIT_OUTPUT',
  VALID = 'VALID',
}

export interface IEntity {
  id: number;
  name?: string;
  getTable?: () => ITableModel;
  getForm?: () => void;
  getFilters?: () => void;
  created_at?: string;
  updated_at?: string;
}

export interface IUser extends IEntity {
  full_name: string;
  surname: string;
  name: string;
  patronymic: string;
  passport?: string;
  inn?: string;
  photo?: string;
  photos?: string[];
  email?: string;
}

export interface IApplicant extends IUser {
  citizen_country_id: number;
  country_id: number;
  address: string;
  zip_code: string;
  legal_agent: boolean;
  complete?: boolean;
  country: ICountry;
  short_name_organization?: string; // TODO Create IApplicantInformation
  post_address_oktmo?: string; // TODO Create IApplicantInformation
  municipality?: { name: string }; // TODO Create IApplicantInformation
}

export interface IOrganization extends IEntity {
  federal_district_id: number;
  region_ids: number[];
  name: string;
}

export interface ICountry {
  id: number;
  code: string;
  name: string;
}

export interface IDictItem {
  id: number;
  rpn_service_form_id: number;
  code: string;
  name: string;
}

export interface ITemplate {
  id: number;
  name: string;
  code: string;
  file: string;
  sort_order: number;
  created_at: string;
  updated_at: string;
}

interface IBidData extends IEntity {
  service_request_id: number;
  importer_ru: string;
  importer_en: string;
  importer_country_id: number;
  importer_address_ru: string;
  importer_address_en: string;
  importer_agent_ru: string;
  importer_agent_en: string;
  exporter_ru: string;
  exporter_en: string;
  exporter_country_id: number;
  exporter_address_ru: string;
  exporter_address_en: string;
  exporter_agent_ru: string;
  exporter_agent_en: string;
  special_conditions_ru: string;
  special_conditions_en: string;
  cites_recommendation_attachments: string[];
  cites_recommendation_attachments_files: any[];
  contract_copy_attachments: string[];
  contract_copy_attachments_files: any[];
  legal_mining_attachments: string[];
  legal_mining_attachments_files: any[];
  owner_confirmation_attachments: string[];
  owner_confirmation_attachments_files: any[];
  captivity_confirmation_attachments: string[];
  captivity_confirmation_attachments_files: any[];
  birth_confirmation_attachments: string[];
  birth_confirmation_attachments_files: any[];
  importer_permission_attachments: string[];
  importer_permission_attachments_files: any[];
  advanced_documents_attachments: string[];
  advanced_documents_attachments_files: any[];
  importer_country: ICountry;
  exporter_country: ICountry;
  signed_request: any;
}
export interface IBid extends IEntity {
  region_id: number;
  territory_org_id: number;
  user_id: number;
  doer_id: number;
  status_id: number;
  declaration_document_type?: number;
  next_statuses: Array<any>;
  status_code: string;
  reject_text: string;
  report_year?: string;
  term_days: number;
  control_date: string;
  cost: number;
  payed: boolean;
  payed_bills_attachments: string[];
  payed_bills_attachments_files: any[];
  payed_at: string;
  from_epgu: boolean;
  sent_at: string;
  registered_at: string;
  done_at: string;
  result_comment: string;
  complete: boolean;
  cites_objects_count?: number;
  number: string;
  doer: IUser;
  user: IUser;
  applicant_information?: IApplicant;
  territory_org: IOrganization;
  status: IDictItem;
  form_type?: IEntity;
  object_id?: string;
  hasSigned?: boolean;
  main_data?: IBidData;
  version?: string;
  created_by_ksv?: boolean;
  ksv_all_sections?: boolean;
  updateVersionIsNotCompleted?: boolean;
  declaration_document_type_disabled?: boolean;
  parent_of_correction_request_id?: string;
  child_of_correction_request_id?: string;
  parent_service_request_id?: string;
  signed_request: any;
  document_receiving_info?: {
    id: number;
    service_request_id: number;
    get_personally: boolean;
    country_id: number;
    address: string;
    zip_code: string;
    legal_agent: boolean;
    complete?: boolean;
    created_at: string;
    updated_at: string;
    country: ICountry;
  };
  result_document?: {
    id: 1;
    service_request_id: number;
    number: string;
    safety_mark: string;
    status_id: number;
    status_code: string;
    expiration_dateless: boolean;
    expiration_date: string;
    territory_org_id: number;
    issuer_name: string;
    issue_date: string;
    importer: string;
    exporter: string;
    special_conditions: string;
    signer_name: string;
    file: string;
    complete?: boolean;
    created_at: string;
    updated_at: string;
  };
}

export class CBid implements IBid {
  'id' = null;
  'region_id' = null;
  'territory_org_id' = null;
  'user_id' = null;
  'doer_id' = null;
  'status_id' = null;
  'reject_text' = null;
  'next_statuses' = null;
  'status_code' = '';
  'number' = '';
  'report_year' = null;
  'term_days' = null;
  'control_date' = null;
  'cost' = null;
  'payed' = false;
  'payed_bills_attachments' = null;
  'payed_bills_attachments_files' = null;
  'payed_at' = null;
  'from_epgu' = true;
  'sent_at' = null;
  'registered_at' = null;
  'done_at' = null;
  'result_comment' = '';
  'created_at' = null;
  'updated_at' = null;
  'cites_objects_count' = 0;
  'object_id' = null;
  'complete' = false;
  'hasSigned' = false;

  'doer' = {
    id: null,
    full_name: '',
    surname: '',
    name: '',
    patronymic: '',
  };

  'user' = {
    id: null,
    full_name: '',
    surname: '',
    name: '',
    patronymic: '',
    passport: null,
    inn: '',
    photo: null,
    photos: null,
    email: '',
    created_at: null,
    updated_at: null,
  };
  'territory_org' = null;
  'status' = null;
  'signed_request' = null;

  constructor(item: IBid) {
    if (!!item) {
      for (const key in item) {
        if (item.hasOwnProperty(key) && item[key] !== undefined) {
          this[key] = item[key];
        }
      }
    }
  }
}

export interface IDocument extends IEntity {
  id: number;
  service_request_id: number;
  number: string;
  safety_mark: string;
  status_id: number;
  status_code: string;
  reject_text: string;
  template_id: number;
  letter_template_id: number;
  expiration_dateless: boolean;
  expiration_date: string;
  territory_org_id: number;
  issuer_name: string;
  issue_date: string;
  importer: string;
  exporter: string;
  special_conditions: string;
  signer_name: string;
  file: string;
  complete: boolean;
  created_at: string;
  updated_at: string;
  audits_count: number;
  cites_form: IBid;
  form_type: IEntity;
  is_editable: boolean;
  letter_template: ITemplate;
  status: IDictItem;
  template: ITemplate;
  territory_org: IOrganization;
  next_statuses: Array<any>;
  result_file: {
    created_at: string;
    download_link: string;
    extension: string;
    file_name: string;
    formatted_size: string;
    has_pdf: boolean;
    has_thumb: boolean;
    id: number;
    name: string;
    pdf_link: string;
    size: number;
    thumb_link: string;
    updated_at: string;
  };
}

export interface IFaq {
  id?: number;
  topic: string;
  description: string;
  body: string;
  group_id: number;
  user_id: number;
  root_id: number;
}

export interface IFaqGroupItem {
  id?: number;
  topic: string;
  description: string;
}

export const PAYMENT_NEGATIVE_IMPACT_VERSION_08_03_2020 = '2020-03-08';

export const ENTITY_VERSION_LABELS = {
  [PAYMENT_NEGATIVE_IMPACT_VERSION_08_03_2020]: 'версия с 08.03.2020',
};

export const ENTITY_LAST_VERSIONS = {
  [GosUslugiType.payment_negative_impact]: PAYMENT_NEGATIVE_IMPACT_VERSION_08_03_2020,
};
