import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { EClassSize, IFormInfo, LabelPositionType } from 'src/app/providers/_models/entity.model';
import { IDictionaryItem } from 'src/app/interfaces/dictionary.interface';

@Component({
  selector: '[context-menu-filter]',
  templateUrl: './context-menu-filter.component.html',
  styleUrls: ['./context-menu-filter.component.scss']
})
export class ContextMenuFilterComponent implements OnInit, OnChanges, OnDestroy {
  public static NULL_ITEM = {
    id: null,
    name: 'Все'
  };

  @Input() formsInfo: IFormInfo;
  @Input() key: string;

  get control(): AbstractControl {
    return this.formsInfo.formGroup ? this.formsInfo.formGroup.controls[this.key] : null;
  }

  public random = Math.random();
  public sizeClass = EClassSize.full;
  public labelPosition = LabelPositionType.top;
  public LabelPositionType = LabelPositionType;
  public items: IDictionaryItem[] = [];
  public currentItem: IDictionaryItem = ContextMenuFilterComponent.NULL_ITEM;

  constructor(private dictionaryService: DictionaryService) { }

  ngOnDestroy(): void { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formsInfo && this.formsInfo && changes.key && this.key) {
      const info = this.formsInfo.controlsInfo[this.key];
      const dictKey = info.dictKey || this.key;

      if (this.dictionaryService[dictKey]) {
        this.dictionaryService[dictKey]
          .pipe(
            filter(x => !!x),
            takeUntil(componentDestroyed(this))
          )
          .subscribe(res => {
            this.items = (res || []).slice();

            if (info.selectClearItem) {
              this.items.unshift({
                ...info.selectClearItem
              });
            }

            this.items.unshift(ContextMenuFilterComponent.NULL_ITEM);
          });
      }
    }
  }

  setValue(item: IDictionaryItem) {
    this.control.setValue(item.id);
    this.currentItem = item;
  }
}
