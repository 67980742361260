import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { IFormInfo, LabelPositionType, EClassSize } from 'src/app/providers/_models/entity.model';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-requisites-component',
  templateUrl: './requisites.component.html',
  styleUrls: ['./requisites.component.scss']
})
export class RequisitesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;
  @Input() isEnabled: boolean;
  public random = Math.random();
  public LabelPositionType = LabelPositionType;
  public sizeClass = EClassSize.full; // full / half / third
  public labelPosition = LabelPositionType.left; // left / top / inside /doNot
  public options = [];
  public select = '';
  public selectValue = '';

  constructor(
    private dictionaryService: DictionaryService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formsInfo && changes.formsInfo.currentValue && changes.key && changes.key.currentValue) {
      if (this.formsInfo.controlsInfo[this.key].sizeClass) {
        this.sizeClass = this.formsInfo.controlsInfo[this.key].sizeClass;
      }
      if (this.formsInfo.controlsInfo[this.key].labelPosition) {
        this.labelPosition = this.formsInfo.controlsInfo[this.key].labelPosition;
      }
    }
  }
}
