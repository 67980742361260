import { REQUEST_STATUS_DRAFT } from 'src/app/constants/request-statuses.constants';
import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { ERoles, IInformationMe } from 'src/app/providers/_models/TokenInfo';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { HeaderService } from 'src/app/providers/_services/header.service';
import { takeUntil, switchMap, debounceTime } from 'rxjs/operators';
import { componentDestroyed } from 'src/app/providers/_utils/utils';
import { GosUslugi, ENameOfPageShot, Reports } from 'src/app/providers/_models/entity.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { messageCloseAnimation } from '../../providers/_animations/message-close.animation';
import { environment } from 'src/environments/environment';
import { EStatementStatusCode } from 'src/app/interfaces/statement/statement.interface';
import { IHeaderConfig } from 'src/app/interfaces/header/header.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [messageCloseAnimation],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() config: IHeaderConfig;
  ERoles = ERoles;
  user: IInformationMe;
  company: any = {};
  id: string;
  type: string;
  name: string;
  // public scrollYOffset = 0;
  public triggerCM = false;
  public ENameOfPageShot = ENameOfPageShot;
  public GosUslugi = GosUslugi;
  public Reports = Reports;
  public isEditabled = false;
  public isCabinet = false;
  public status = REQUEST_STATUS_DRAFT.code;
  public notifications = [];
  private fetchNotify$;

  get currentDate() {
    return new Date();
  }
  get alertDateStop() {
    return environment.ALERT_STOP_TIME ? new Date(environment.ALERT_STOP_TIME) : null;
  }
  public alertText = environment.ALERT_MESSAGE;

  // TODO This code execute ngOnChanges on templates
  // @HostListener('window:scroll', ['$event']) doSomething(event) {
  //   this.scrollYOffset = window.pageYOffset;
  // }
  @HostListener('document:click', ['$event']) closeCM(event) {
    this.triggerCM = false;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private svcRestService: SvcRestService,
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.isCabinet = !!(this.router.url.indexOf('cabinet') + 1);

    this.isEditabled = this.authenticationService.changeEditMode.getValue();

    this.headerService.selectItem.pipe(takeUntil(componentDestroyed(this))).subscribe(res => {
      this.id = res ? res.id : null;
      this.type = res ? res.type : null;
      this.name = res ? res.name : null;
    });

    this.headerService.changeStatus.subscribe(res => (this.status = res as EStatementStatusCode));

    this.headerService.changeNotifications.pipe(takeUntil(componentDestroyed(this))).subscribe(res => (this.notifications = res));

    this.fetchNotify$ = this.svcRestService.fetchNotifications();
    this.authenticationService
      .getInfoMe()
      .pipe(takeUntil(componentDestroyed(this)))
      .pipe(
        switchMap(res => {
          this.user = res || {};
          try {
            this.company = JSON.parse(localStorage.getItem('current_org'));
          } catch (err) {}
          return this.fetchNotify$;
        }),
      )
      .subscribe((res: any) => this.headerService.changeNotifications.next(res.data));

    // interval(30000).pipe(
    //   takeUntil(componentDestroyed(this)),
    //   switchMap(
    //     res => this.svcRestService.fetchNotifications()
    //   )
    // ).subscribe((res: any) => this.headerService.changeNotifications.next(res.data) );
  }

  markReadNotifications(id: string): void {
    if (id) {
      this.notifications[id].read_at = new Date().toISOString();
      this.svcRestService
        .markReadNotifications([this.notifications[id].id])
        .pipe(debounceTime(1000))
        .subscribe((res: any) => {
          this.notifications = res.data;
          this.headerService.changeNotifications.next(this.notifications);
        });
    } else {
      const allUnReadId = this.notifications.map(x => x.id);
      this.svcRestService.markReadNotifications(allUnReadId).subscribe((res: any) => {
        this.notifications = res.data;
        this.headerService.changeNotifications.next(this.notifications);
      });
    }
  }

  public logout(): void {
    if (this.user && this.user.esia_authorized && confirm('Выйти из учетной записи ESIA?')) {
      this.authenticationService
        .logout(true)
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();
    } else {
      this.authenticationService
        .logout()
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe();
    }
  }

  public changeCompany(): void {
    localStorage.removeItem('current_org');
    this.router.navigate(['/company']);
  }

  changeEditMode(event): void {
    this.isEditabled = event.target.checked;
    this.authenticationService.changeEditMode.next(this.isEditabled);
  }

  toggleEditMode(): void {
    this.isEditabled = !this.isEditabled;
    this.authenticationService.changeEditMode.next(this.isEditabled);
  }

  openCM(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.triggerCM = !this.triggerCM;
  }

  changeStatus(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.triggerCM = false;
  }

  scrollUp(): void {
    window.scrollTo(0, 0);
  }

  openChat(): void {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    // this.headerService.openButtonOnBottom.next('chat');
    this.router.navigate(['../menu/chat'], { relativeTo: this.route.firstChild });
  }

  public redirectTo(href: string): void {
    window.open(href, '_self');
  }
}
