import {Component, Input, OnInit} from '@angular/core';
import {IFormInfo} from '../../providers/_models/entity.model';

@Component({
  selector: 'app-section-custom-form',
  templateUrl: './section-custom-form.component.html',
  styleUrls: ['./section-custom-form.component.scss']
})
export class SectionCustomFormComponent implements OnInit {

  @Input() formInfo: IFormInfo;
  @Input() type: string;
  @Input() isEnabled: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
