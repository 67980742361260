import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IFormInfo } from 'src/app/providers/_models/entity.model';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent implements OnInit {
  @Input() formsInfo: IFormInfo;
  @Input() key: string;

  constructor() {}

  ngOnInit() {}
}
