import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'leaflet';
import 'leaflet-contextmenu';
import 'leaflet.markercluster';
import 'zone.js';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

akitaConfig({
  resettable: true,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch();
