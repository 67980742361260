import {Component, Input, OnInit} from '@angular/core';
import {IFormInfo} from 'src/app/providers/_models/entity.model';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {

  @Input() formsInfo: IFormInfo;
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() isEnabled: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }
}
