import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationPipe } from './pagination.pipe';
import { PaginationComponent } from './pagination.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    PaginationPipe,
    PaginationComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports: [
    PaginationComponent
  ]
})
export class PaginationComponentModule { }
