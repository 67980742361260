import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { JSON_stringify } from '../_utils/utils';
// tslint:disable-next-line: no-string-literal
const cadesplugin = window['cadesplugin'];
const CADESCOM_CADES_BES = 1;
const CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
const CAPICOM_CERTIFICATE_FIND_TIME_VALID = 9;

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  private selectCertificate$ = new BehaviorSubject(null);

  constructor() { }

  set selectCertificate(data: { value: string; label: string }) {
    this.selectCertificate$.next(data);
  }

  get selectCertificate(): { value: string; label: string } {
    return this.selectCertificate$.getValue();
  }

  public fetchCertList() {
    return new Promise((resolve, reject) => {
      let isPluginLoaded = false;
      let isPluginWorked = false;
      let isPluginEnabled = false;
      cadesplugin.async_spawn(
        function* (args) {
          try {
            const oAbout = yield cadesplugin.CreateObjectAsync('CAdESCOM.About');
            isPluginLoaded = true;
            isPluginEnabled = true;
            let CurrentPluginVersion = oAbout.PluginVersion;
            if (typeof CurrentPluginVersion === 'undefined') {
              CurrentPluginVersion = oAbout.Version;
            }

            const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
            isPluginWorked = true;

            yield oStore.Open();
            const CertificatesObj = yield oStore.Certificates;
            const Count = yield CertificatesObj.Count;
            const certSelectOption: { value: string; label: string; status: string }[] = [];
            if (Count === 0) {
              throw new Error('Certificate not found: ');
            } else {
              for (let i = 1; i <= Count; i++) {
                const cert = yield CertificatesObj.Item(i);
                const privateKey = yield cert.HasPrivateKey();
                const SubjectName = yield cert.SubjectName;
                const SerialNumber = yield cert.SerialNumber;
                let isValid = false;
                try {
                  const Validator = yield cert.IsValid();
                  isValid = yield Validator.Result;
                } catch (e) {
                  // console.log(e);
                }
                const certFromDate = yield new Date(cert.ValidFromDate);
                const certTillDate = yield new Date(cert.ValidToDate);
                const Now = new Date();
                let status = '';
                if (Now < certFromDate) {
                  status = `Статус: <span style="color:red; font-weight:bold; font-size:16px"><b>Срок действия не наступил</b></span>`;
                } else if (Now > certTillDate) {
                  status = `Статус: <span style="color:red; font-weight:bold; font-size:16px"><b>Срок действия истек</b></span>`;
                } else if (!privateKey) {
                  status = `Статус: <span style="color:red; font-weight:bold; font-size:16px">
                      <b>Нет привязки к закрытому ключу</b></span>`;
                } else if (!isValid) {
                  status = `Статус: <span style="color:red; font-weight:bold; font-size:16px">
                      <b>Ошибка при проверке цепочки сертификатов</b></span>`;
                } else {
                  status = `Статус: <b> Действителен<b>`;
                }
                certSelectOption.push({
                  value: SubjectName + ', SN=' + SerialNumber,
                  label: SubjectName.split(',')
                    .find((item: string) => item.indexOf('CN=') + 1)
                    .split('=')[1],
                  status,
                });
              }
              args[0](certSelectOption);
            }
          } catch (err) {
            if (!(isPluginLoaded && isPluginEnabled)) {
              err = `КриптоПро ЭЦП Browser plug-in не установлен.
                  Нажмите Ок для перехода на страницу "Проверка работы КриптоПро ЭЦП Browser plug-in"`;
              if (window.confirm(err)) {
                const a = document.createElement('a');
                a.href = 'https://www.cryptopro.ru/sites/default/files/products/cades/demopage/simple.html';
                a.target = '_blank';
                a.click();
              }
            } else if (!isPluginWorked) {
              err = 'КриптоПро ЭЦП Browser plug-in не был разрешен.';
            }
            args[1](err);
          }
        },
        resolve,
        reject,
      );
    });
  }

  private SignCreate(certSubjectName, dataToSign) {
    return new Promise((resolve, reject) => {
      cadesplugin.async_spawn(
        function* (args) {
          try {
            const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
            yield oStore.Open();
            const CertificatesObj = yield oStore.Certificates;
            let oCertificates = yield CertificatesObj.Find(CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME, args[0]);
            oCertificates = yield oCertificates.Find(CAPICOM_CERTIFICATE_FIND_TIME_VALID);
            const Count = yield oCertificates.Count;
            if (Count === 0) {
              throw new Error('');
            }
            const oCertificate = yield oCertificates.Item(Count);
            const oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
            yield oSigner.propset_Certificate(oCertificate);
            const oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
            yield oSignedData.propset_Content(dataToSign);
            const sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES);
            yield oStore.Close();

            args[2](sSignedMessage);
          } catch (err) {
            // console.log(err);
            args[3]({
              title: 'Не удалось подписать данные. Сертификат невалидный.',
              message: cadesplugin.getLastError(err),
            });
          }
        },
        certSubjectName,
        dataToSign,
        resolve,
        reject,
      );
    });
  }

  public signByCertificate(data): Observable<any> {
    const certNameObj = this.selectCertificate$.getValue() || { value: '', label: '' };
    const a = JSON_stringify(data, false);
    console.log(a);
    const thenable = this.SignCreate(certNameObj.label, a);
    return from(thenable);
  }
}
