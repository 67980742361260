import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EClassSize, IControlsInfo, LabelPositionType } from '../../../../providers/_models/entity.model';
import { AutocompleteService } from '../../../../providers/_services/autocomplete.service';
import { IAutocompleteSuggest } from '../../../../providers/_models/autocomplete.suggest.model';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, map } from 'rxjs/operators';
import { Subscription, of, forkJoin } from 'rxjs';
import { componentDestroyed } from 'src/app/providers/_utils/utils';

@Component({
  selector: 'app-dadata-autocomplete-control',
  templateUrl: './dadata-autocomplete-control.component.html',
  styleUrls: ['./dadata-autocomplete-control.component.scss'],
})
export class DadataAutocompleteControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type = { type: 'address', label: 'Адрес', subType: null };
  @Input() fGroup: FormGroup;
  @Input() key: string;
  @Input() info: IControlsInfo;
  @Input() inTable: boolean;
  @Input() isEnabled: boolean;
  @Input() fieldSizeClass = 'rpn-input-group__field_md';
  @Input() debounceTime = 2000;
  expiryTimeLive = 30;
  @Output() changeSelectItem = new EventEmitter();

  get control(): AbstractControl {
    return this.fGroup ? this.fGroup.controls[this.key] : null;
  }

  get canEdit(): boolean {
    if (!this.isEnabled) {
      return false;
    }

    if (this.info && this.info.isDisabled && this.fGroup) {
      return !this.info.isDisabled(this.fGroup);
    }

    return true;
  }

  get showError() {
    return this.control && this.control.invalid && this.canEdit;
  }

  random = Math.random();
  sizeClass = EClassSize.full;
  labelPosition = LabelPositionType.top;
  LabelPositionType = LabelPositionType;

  suggestItems: { data: IAutocompleteSuggest; unrestricted_value: string; value: string }[];
  suggestToggle = false;
  suggestSubscription: Subscription;
  statOfSuggest = null;

  constructor(private changeDetectorRef: ChangeDetectorRef, private autocompleteService: AutocompleteService) { }

  ngOnInit() {
    try {
      this.statOfSuggest = JSON.parse(localStorage.getItem('stat_of_suggest'));
    } catch (err) { }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.info) {
      this.sizeClass = (this.info && this.info.sizeClass) || EClassSize.full;
      this.labelPosition = (this.info && this.info.labelPosition) || LabelPositionType.top;
    }

    if (simpleChanges.fGroup || simpleChanges.key) {
      if (this.suggestSubscription) {
        this.suggestSubscription.unsubscribe();
      }

      if (this.control) {
        this.control.valueChanges
          .pipe(
            debounceTime(this.debounceTime),
            distinctUntilChanged(),
            filter(val => val && val.length >= this.getMinChars()),
            switchMap(data => {
              if (data) {
                return this.autocompleteService.getAddressSuggest(data, this.type.type);
              } else {
                return of({ suggestions: [] });
              }
            }),
            takeUntil(componentDestroyed(this)),
          )
          .subscribe(({ suggestions }) => {
            this.suggestItems = suggestions;
            this.suggestToggle = !!suggestions.length;
            this.changeDetectorRef.markForCheck();
          });
      }
    }
  }

  ngOnDestroy() { }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    this.suggestToggle = false;
  }

  public selectItem(event, item: any): void {
    const format = this.getFormatSelected() || (suggestion => suggestion.value);

    this.control.setValue(format(item), { emitEvent: false });
    this.control.markAsDirty();
    this.changeToggle(event, false);

    if (this.info.onSelectAutocomplete) {
      this.info.onSelectAutocomplete(this.fGroup, item);
    }

    this.changeSelectItem.emit(item);
    this.changeDetectorRef.markForCheck();
  }

  public changeToggle(event, toggle: boolean): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.suggestToggle && toggle && !this.suggestItems && this.control.value && this.control.value.length >= this.getMinChars()) {
      this.autocompleteService.getAddressSuggest(this.control.value, this.type.type);
    }

    this.suggestToggle = toggle;
  }

  public getMinChars(): number {
    if (this.type.type === 'party' && this.type.subType === 'inn') {
      return 4;
    }

    return 4;
  }

  public getFormatSelected(): (suggestion: any) => string {
    if (this.type.type === 'party' && this.type.subType === 'inn') {
      return suggestion => {
        return (suggestion.data && suggestion.data.inn) || '';
      };
    }

    if (this.type.type === 'okved2') {
      return suggestion => {
        return (suggestion.data && suggestion.data.kod) || '';
      };
    }

    return;
  }
}
