import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { AuthGuard } from '../providers/_guards/auth.guard';
import { OperatorsComponent } from './operators/operators.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: 'rpn',
    component: MainComponent,
    children:
      environment.isPTC === 1
        ? [
            { path: '', redirectTo: 'ptc-goscontrol', pathMatch: 'full' },
            {
              path: 'ptc-goscontrol',
              loadChildren: () => import('./ptc-goscontrol/ptc-goscontrol.component.module').then(m => m.PTCGosControlComponentModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'operators-page',
              canActivate: [AuthGuard],
              component: OperatorsComponent,
            },
            { path: '**', redirectTo: '/page-not-found' },
          ]
        : [
            { path: '', redirectTo: 'cabinet', pathMatch: 'full' },
            {
              path: 'cabinet',
              loadChildren: () => import('./cabinet/cabinet.page.component.module').then(m => m.CabinetPageComponentModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'operators-page',
              canActivate: [AuthGuard],
              component: OperatorsComponent,
            },
            { path: '**', redirectTo: '/page-not-found' },
          ],
  },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
