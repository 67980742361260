import { BehaviorSubject } from 'rxjs';
import { EDictionaries } from 'src/app/interfaces/dictionary.interface';

export const DicItem: {
  [key: string]: {
    dictURL: string;
    dictItemURL?: string;
    dictionary$: BehaviorSubject<any>;
    loading: boolean;
    expiryDate: number;
    filters?: { [key: string]: any };
  };
} = {
  status_id: {
    dictURL: '/api/svc/nsi/statuses',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  applicants: {
    dictURL: '/api/svc/cites/nsi/applicants',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  result_document_statuses: {
    dictURL: '/api/svc/cites/nsi/result_document_statuses',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  pollutants: {
    dictURL: '/api/svc/nsi/pollutants',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  [EDictionaries.pollutants_with_taxes]: {
    // dictURL: '/api/svc/nsi/pollutants',
    dictURL: '/api/svc/nsi/pollutant-types-group/2/with-taxes',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  [EDictionaries.air_pollutants_with_taxes]: {
    // dictURL: '/api/svc/nsi/air-pollutants',
    dictURL: '/api/svc/nsi/pollutant-types-group/1/with-taxes',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  air_pollutants: {
    dictURL: '/api/svc/nsi/air-pollutants',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
    filters: {
      is_active: true,
    },
  },
  territory_org_id: {
    dictURL: '/api/svc/nsi/orgs',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  posts: {
    dictURL: '/api/svc/nsi/posts',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  region: {
    dictURL: '/api/svc/nsi/regions',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  region_allowed: {
    dictURL: '/api/svc/nsi/regions/allowed',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  report_template_recultivation: {
    dictURL: '/api/svc/xls-export?search=2tprecl',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  report_template_waste: {
    dictURL: '/api/svc/xls-export?search=2tpwaste',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  report_template_air: {
    dictURL: '/api/svc/xls-export?search=2tpair',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  doer_id: {
    dictURL: '/api/svc/cites/nsi/doers',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  country_id: {
    dictURL: '/api/svc/nsi/countries',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  cites_target_id: {
    dictURL: '/api/svc/cites/nsi/targets',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  cites_source_id: {
    dictURL: '/api/svc/cites/nsi/sources',
    dictionary$: new BehaviorSubject(null),
    loading: false,
    expiryDate: 0,
  },
  letter_template_id: {
    dictURL: '/api/svc/cites/nsi/result_document_letter_templates',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  template_id: {
    dictURL: '/api/svc/cites/nsi/result_document_templates',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  state_services: {
    dictURL: '/api/svc/nsi/state_services',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  okpds: {
    dictURL: '/api/svc/nsi/okpds',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  okopfs: {
    dictURL: '/api/svc/nsi/okopfs',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  measureUnits: {
    dictURL: '/api/svc/nsi/measure-units',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  measureUnitsByGroupId_5: {
    dictURL: '/api/svc/nsi/measure-units?filter[measure_units_group_id]=5',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  [EDictionaries.wasteHazardClasses]: {
    dictURL: '/api/svc/nsi/waste-hazard-classes',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  heatEnergyTypes: {
    dictURL: '/api/svc/nsi/heat-energy-types',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  [EDictionaries.fkkos]: {
    dictURL: '/api/svc/nsi/fkkos',
    dictItemURL: '/api/svc/nsi/fkko',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  my_onvos: {
    dictURL: '/api/svc/onv/my',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  faq_group: {
    dictURL: '/api/svc/support/faq/group',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  federal_districts: {
    dictURL: '/api/svc/nsi/federal-districts',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  onv_not_registered_reasons: {
    dictURL: '/api/svc/nsi/onv-not-registered-reasons',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
  onvos_permitting_document_types: {
    dictURL: '/api/svc/nsi/onvos-permitting-document-types',
    loading: false,
    dictionary$: new BehaviorSubject(null),
    expiryDate: 0,
  },
};
