import { REQUEST_STATUS_DRAFT } from 'src/app/constants/request-statuses.constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ETypeOfPage,
  ETypeOfSystem,
  GosUslugi,
  GosUslugiType,
  IDADATAResponse,
  IDADATASuggestion,
  IFaq,
  ONVOSGosUslugi,
} from '../_models/entity.model';
import { CitesSectionName } from 'src/app/sections/bid/cites.edit/cites.form.models';
import { IMessage } from '../_models/TokenInfo';
import { createImageFromBlob, endOfDay } from '../_utils/utils';
import { IDictionaryItem } from '../../interfaces/dictionary.interface';
import { DicItem } from './dictionary.items';
import { ISvcRestHttpParams } from 'src/app/interfaces/backend/backend-http-params.interface';
import { LoadingSpinnerService } from 'src/app/modules/loading-spinner/loading.spinner.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class SvcRestService {
  private changeDocumentTable$ = new BehaviorSubject(null);
  private changeHistoryTable$ = new BehaviorSubject(null);
  private changeFAQ$ = new BehaviorSubject(null);
  private changeReportsTable$ = new BehaviorSubject(null);
  private changeBidTable$ = new BehaviorSubject(null);
  private changeOnvosTable$ = new BehaviorSubject(null);

  constructor(private http: HttpClient, private loadingService: LoadingSpinnerService) {}

  private setHttpParams(parameters: ISvcRestHttpParams, api?: string): HttpParams {
    let params = new HttpParams();
    ['initParams', 'pagination'].forEach(item => {
      if (parameters && parameters[item] && Object.keys(parameters[item]).length) {
        const a = parameters[item];
        if (a && Object.keys(a).length) {
          for (const filterName in a) {
            if (a.hasOwnProperty(filterName)) {
              params = params.append(filterName, a[filterName]);
            }
          }
        }
      }
    });

    if (parameters && parameters['download']) {
      params = params.append('download', parameters['download']);
    }

    Object.keys(parameters.order_by || {})
      .filter(key => key.indexOf('order_by[') > -1)
      .forEach(key => {
        params = params.append(key, parameters.order_by[key]);
      });

    if (parameters && parameters.filters && Object.keys(parameters.filters).length) {
      for (const filterName in parameters.filters) {
        if (parameters.filters.hasOwnProperty(filterName)) {
          if (filterName && filterName.indexOf('not_send_filter[') === -1) {
            if (
              filterName.indexOf('filter_except[') === 0 ||
              filterName.indexOf('filter[') === 0 ||
              filterName.indexOf('filter_json[') === 0 ||
              filterName.indexOf('filter_between[') === 0 ||
              filterName.indexOf('scope[') === 0 ||
              filterName.indexOf('order_by[') === 0
            ) {
              params = params.append(filterName, parameters.filters[filterName]);
            } else {
              switch (filterName) {
                case 'is_active':
                  params = params.append(`filter[${filterName}]`, parameters.filters[filterName]);
                  break;
                case 'search':
                  params = params.append(`${filterName}`, parameters.filters[filterName]);
                  break;
                case 'sent_at':
                  const sent_from = parameters.filters.sent_at ? new Date(parameters.filters.sent_at).toISOString() : null;
                  const sent_to = parameters.filters.sent_at_to ? endOfDay(new Date(parameters.filters.sent_at_to)).toISOString() : null;

                  if (sent_from && sent_to) {
                    params = params.append(`filter_date_between[${filterName}]`, `${sent_from}...${sent_to}`);
                  }

                  break;
                case 'sent_at_to':
                  break;
                case 'issue_date':
                  params = params.append(
                    `filter_between[${filterName}]`,
                    `${parameters.filters[filterName] + (parameters.filters.sent_at_to ? '...' + parameters.filters.sent_at_to : '')}`,
                  );
                  break;
                case 'issue_date_to':
                  break;
                case 'id':
                  if (api === 'svc' && !isNaN(parameters.filters[filterName])) {
                    params = params.append(`filter[${filterName}]`, parameters.filters[filterName]);
                  }
                  if (api === 'prototype') {
                    params = params.append(`filter[_${filterName}]`, parameters.filters[filterName]);
                  }
                  break;
                case 'inn':
                  if (api === 'svc' && !isNaN(parameters.filters[filterName])) {
                    params = params.append(`filter[${filterName}]`, parameters.filters[filterName]);
                  }
                  if (api === 'prototype') {
                    params = params.append(`filter[applicant_information.${filterName}]`, parameters.filters[filterName]);
                  }
                  break;
                default: {
                  params = params.append(`filter[${filterName}]`, parameters.filters[filterName]);
                }
              }
            }
          }
        }
      }
    }

    if (parameters && parameters.relations) {
      params = params.append('relations', parameters.relations);
    }

    return params;
  }

  public fetchStatusLogs(id: number, mongoType: typeof ETypeOfPage): Observable<any> {
    if (id) {
      if (mongoType) {
        return this.http.get(`/api/v2/requests/${mongoType}/${id}/status-logs?appends=user`);
      } else {
        return this.http.get(`/api/v2/requests/${id}/status-logs?appends=user`);
      }
    } else {
      return of(null);
    }
  }

  public getChangeByEssenceName(EssenceName: string): BehaviorSubject<any> {
    switch (EssenceName) {
      case ETypeOfPage.reports: {
        return this.changeReportsTable$;
      }
      case ETypeOfPage.requests: {
        return this.changeBidTable$;
      }
      case ETypeOfPage.onvos: {
        return this.changeOnvosTable$;
      }
      case ETypeOfPage.documents: {
        return this.changeDocumentTable$;
      }
      case ETypeOfPage.history: {
        return this.changeHistoryTable$;
      }
      case ETypeOfPage.faq: {
        return this.changeFAQ$;
      }
      default: {
        return null;
      }
    }
  }

  public fetchPageByEssenceName(EssenceName: string, params, type?: string, id?: string, systemType?: ETypeOfSystem) {
    switch (EssenceName) {
      case ETypeOfPage.documents: {
        if (id) {
          return this.fetchResultDocumentsByCitesFormID(type, id, params);
        }
        return this.fetchResultDocuments(params);
      }
      case ETypeOfPage.history: {
        if (id) {
          return this.fetchResultDocumentsByCitesFormID(type, id, params);
        }
        return this.fetchResultDocuments(params);
      }
      case ETypeOfPage.requests: {
        return this.fetchBids(params);
      }
      case ETypeOfPage.reports: {
        return this.fetchReports(type, params);
      }
      case ETypeOfPage.onvos: {
        if (systemType && systemType === ETypeOfSystem.cabinet) {
          return this.fetchOnvosMy(params);
        } else {
          return this.fetchOnvos(params);
        }
      }
      case ETypeOfPage.faq: {
        return this.fetchFAQList(params);
      }
      default: {
        return of(null);
      }
    }
  }

  public fetchDictionary(dictApi: string, odj?: { [key: string]: any }): Observable<any> {
    return this.http.get(`${dictApi}`, { params: this.setHttpParams(odj) });
  }

  public fetchDownloadByUrl(url: string, odj?: { [key: string]: any }, api?: string): Observable<any> {
    return this.http.get(`${url}`, { params: this.setHttpParams(odj, api) });
  }

  public fetchBlobByUrl(url: string, odj?: { [key: string]: any }, api?: string): Observable<any> {
    return this.http.get(`${url}`, { params: this.setHttpParams(odj, api), responseType: 'blob' as 'json' });
  }

  fetchDictionaryItems(dictKey: string, odj?: { [key: string]: any }): Observable<IDictionaryItem[]> {
    return this.fetchDictionaryItemsWithPagination(dictKey, odj).pipe(map(res => (res as any).data as IDictionaryItem[]));
  }

  fetchDictionaryItemsWithPagination(dictKey: string, odj?: { [key: string]: any }): Observable<{ data: IDictionaryItem[] }> {
    const url = (DicItem[dictKey] && `${DicItem[dictKey].dictURL}`) || null;

    if (!url) {
      return throwError(`${dictKey} not found`);
    }

    return this.http.get<{ data: IDictionaryItem[] }>(url, { params: this.setHttpParams(odj) });
  }

  fetchDictionaryItem(dictKey: string, id: string | number): Observable<IDictionaryItem> {
    const url = (DicItem[dictKey] && `${DicItem[dictKey].dictItemURL}/${id}`) || null;

    if (!url) {
      return throwError(`${dictKey} not found`);
    }

    return this.http.get<IDictionaryItem>(url);
  }

  fetchByURL<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  fetchByURLWithSetHTTPParams<T>(url: string, options: ISvcRestHttpParams = {}): Observable<T> {
    return this.http.get<T>(url, { params: this.setHttpParams(options) });
  }

  postByURL<T>(url: string, body: any = {}, options = {}): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  patchByURL<T>(url: string, body: any = {}, options = {}): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  deleteByURL<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }

  // BIDS
  private fetchBids(odj?: { [key: string]: any }): Observable<any> {
    return this.http.get(`/api/v2/requests`, { params: this.setHttpParams(odj, 'svc') });
  }

  public fetchBidByID(type: string, id: string, pagination?: { [key: string]: string }): Observable<any> {
    if (type === GosUslugiType.cites) {
      return this.http.get(`/api/svc/${type}/${id}`, { params: this.setHttpParams({ pagination }) }).pipe(
        map((res: any) => {
          res.status_code = res.status_code || REQUEST_STATUS_DRAFT.code;
          return res;
        }),
      );
    } else {
      return this.http.get(`/api/prototype/requests/${type}/${id}`, { params: this.setHttpParams({ pagination }) }).pipe(
        map((res: any) => {
          this.fixOkveds(res);
          res.status_code = res.status_code || REQUEST_STATUS_DRAFT.code;
          const a = GosUslugi[type] || ONVOSGosUslugi[type] || { term_days: 30, cost: 0 };
          res.cost = a.cost;
          res.term_days = a.term_days;
          return res;
        }),
      );
    }
  }

  public createBid(type?: string, data: any = {}): Observable<any> {
    data = { ...data, ...{ status_id: REQUEST_STATUS_DRAFT.id, status_code: REQUEST_STATUS_DRAFT.code } };
    return this.updateBidByID(type, null, data);
  }

  public updateBidByID(type: string, id: string, body: any = {}): Observable<any> {
    delete body.id;
    delete body.updated_at;
    delete body.created_at;
    delete body.service;

    if (!id || id === 'new') {
      if (type === GosUslugiType.cites) {
        return this.http.post(`/api/svc/${type}`, body);
      } else {
        return this.http.post(`/api/prototype/requests/${type}`, body);
      }
    } else if (id && id !== 'new') {
      if (type === GosUslugiType.cites) {
        return this.http.post(`/api/svc/${type}/${id}`, body);
      } else {
        return this.http.post(`/api/prototype/requests/${type}/${id}`, body);
      }
    }
    return of(null);
  }

  public getPrintBidUrl(type: string, id: string): string {
    return `/api/prototype/requests/${type}/${id}/docx`;
  }

  // REPORTS
  private fetchReports(type: string, odj?: { [key: string]: any }): Observable<any> {
    return this.http.get(`/api/prototype/reports${type ? '/' + type : ''}`, { params: this.setHttpParams(odj, 'prototype') }).pipe(
      map((res: any) => {
        if (res && res.data && res.data.length) {
          res.data.map(x => {
            x.status_code = res.status_code || REQUEST_STATUS_DRAFT.code;
            return x;
          });
        }
        return res;
      }),
    );
  }

  public fetchReportByID(type: string, id: string, pagination?: { [key: string]: string }): Observable<any> {
    return this.http.get(`/api/prototype/reports/${type}/${id}`, { params: this.setHttpParams({ pagination }) }).pipe(
      map((res: any) => {
        this.fixOkveds(res);
        res.status_code = res.status_code || REQUEST_STATUS_DRAFT.code;
        return res;
      }),
    );
  }

  private fixOkveds(res: any) {
    const okveds = res && res.applicant_information && res.applicant_information.okveds;
    if (okveds && !Array.isArray(okveds)) {
      try {
        res.applicant_information.okveds = JSON.parse(okveds);
      } catch (e) {}
    }
  }

  public createReport(type?: string, data: any = {}): Observable<any> {
    data = { ...data, ...{ status_id: REQUEST_STATUS_DRAFT.id, status_code: REQUEST_STATUS_DRAFT.code } };
    return this.updateReportByID(type, null, data ? data : {});
  }

  public updateReportByID(type: string, id: string, body: any = {}): Observable<any> {
    delete body.id;
    delete body.updated_at;
    delete body.created_at;
    delete body.service;

    if (!id || id === 'new') {
      return this.http.post(`/api/prototype/reports/${type}`, body);
    } else if (id && id !== 'new') {
      return this.http.post(`/api/prototype/reports/${type}/${id}`, body);
    }
  }

  public updateBidSection(name: string, type: string, id: string, body: any = {}): Observable<any> {
    switch (name) {
      case CitesSectionName.serviceReceivingDocumentMethod:
        return this.updateBidSectionMethod(type, id, 'document_receiving_info', body);
      case CitesSectionName.applicantInformation:
        return this.updateBidSectionMethod(type, id, 'applicant_information', body);
      case CitesSectionName.serviceReceivingInformation:
        return this.updateBidSectionMethod(type, id, 'main_data', body);
      default:
        return this.updateBidSectionMethod(type, id, name, body);
    }
  }

  private updateBidSectionMethod(type: string, id: string, section: string, body: any): Observable<any> {
    if (id) {
      if (type === GosUslugiType.cites) {
        return this.http.post(`/api/svc/${type}/${id}/${section}`, body);
      } else {
        return this.http.post(`/api/prototype/requests/${type}/${id}/${section}`, body);
      }
    }
    return of(null);
  }

  public updateReportSection(name: string, type: string, id: string, body: any): Observable<any> {
    switch (name) {
      case CitesSectionName.serviceReceivingDocumentMethod:
        return this.updateReportSectionMethod(type, id, 'document_receiving_info', body);
      case CitesSectionName.applicantInformation:
        return this.updateReportSectionMethod(type, id, 'applicant_information', body);
      case CitesSectionName.serviceReceivingInformation:
        return this.updateReportSectionMethod(type, id, 'main_data', body);
      default:
        return this.updateReportSectionMethod(type, id, name, body);
    }
  }

  private updateReportSectionMethod(type: string, id: string, section: string, body: any): Observable<any> {
    if (id) {
      return this.http.post(`/api/prototype/reports/${type}/${id}/${section}`, body);
    }
    return of(null);
  }

  public deleteBidByID(key: string, type: string, id: string): Observable<any> {
    if (confirm('Вы действительно хотите удалить?')) {
      if (type === GosUslugiType.cites) {
        return this.http.delete(`/api/svc/${type}/${id}`).pipe(tap(() => this.changeBidTable$.next(null)));
      } else {
        return this.http.delete(`/api/prototype/${key}/${type}/${id}`).pipe(tap(() => this.changeBidTable$.next(null)));
      }
    } else {
      return of(null);
    }
  }

  public deleteReportByID(key: string = ETypeOfPage.reports, type: string, id: string): Observable<any> {
    if (confirm('Вы действительно хотите удалить?')) {
      return this.http.delete(`/api/prototype/${key}/${type}/${id}`).pipe(tap(() => this.changeReportsTable$.next(null)));
    } else {
      return of(null);
    }
  }

  public deleteDocumentBidByID(type: string, id: string): Observable<any> {
    if (confirm('Вы действительно хотите удалить?')) {
      if (type === GosUslugiType.cites) {
        return this.http.delete(`/api/svc/${type}/result_document/${id}`).pipe(tap(() => this.changeDocumentTable$.next(null)));
      } else {
        return this.http
          .delete(`/api/prototype/requests/${type}/result_document/${id}`)
          .pipe(tap(() => this.changeDocumentTable$.next(null)));
      }
    } else {
      return of(null);
    }
  }

  // DOCUMENTS
  private fetchResultDocumentsByCitesFormID(type: string, id: string, odj?: { [key: string]: any }): Observable<any> {
    if (type === GosUslugiType.cites) {
      return this.http.get(`/api/svc/${type}/${id}/result_documents`, { params: this.setHttpParams(odj) });
    } else {
      return this.http.get(`/api/prototype/requests/${type}/${id}/result_documents`, { params: this.setHttpParams(odj) });
    }
  }

  private fetchResultDocuments(obj?: { [key: string]: any }): Observable<any> {
    return this.http.get(`/api/svc/cites/result_documents`, { params: this.setHttpParams(obj) });
  }

  public fetchResultDocumentsByID(id: string, pagination?: { [key: string]: string }): Observable<any> {
    return this.http.get(`/api/svc/cites/result_document/${id}`, { params: this.setHttpParams({ pagination }) });
  }

  public generateResultDocumentsAuto(formId: string, data): Observable<any> {
    return this.http.post(`/api/svc/cites/${formId}/result_documents`, data);
  }

  public generateResultDocument(formId: string, data): Observable<any> {
    return this.http.post(`/api/svc/cites/${formId}/result_document`, data);
  }

  public updateDocumentBidByID(id: string, body: any): Observable<any> {
    return this.http.post(`/api/svc/cites/result_document/${id}`, body);
  }

  public generateResultFileByDocumentID(id: string, body: any): Observable<any> {
    return this.http.post(`/api/svc/cites/result_document/${id}/file`, body);
  }

  public getResultFileByDocumentID(id: string, body: any): Observable<any> {
    return this.http.get(`/api/svc/cites/result_document/${id}/file`);
  }

  public getCopyOfRequestOrReport(place: 'requests' | 'reports', type: string, id: string | number) {
    const url = `/api/prototype/${place}/${type}/${id}/copy`;
    return this.fetchByURL(url);
  }

  // ONVOS
  private fetchOnvos(odj?: { [key: string]: any }): Observable<any> {
    return this.http.get(`/api/svc/onv`, { params: this.setHttpParams(odj) }).pipe(
      map((res: any) => {
        res.data.map(x => (x.service = ETypeOfPage.onvos));
        return res;
      }),
    );
  }

  private fetchOnvosMy(odj?: { [key: string]: any }): Observable<any> {
    return this.http.get(`/api/svc/onv/my`, { params: this.setHttpParams(odj) }).pipe(
      map((res: any) => {
        res.data.map(x => (x.service = ETypeOfPage.onvos));
        return res;
      }),
    );
  }

  public fetchOnvosObjectByID(id: string): Observable<any> {
    return this.http.get(`/api/svc/onv/${id}`);
  }

  public creatOnvosObject(body: any): Observable<any> {
    delete body.id;
    delete body.updated_at;
    delete body.created_at;
    delete body.service;

    return this.http.post(`/api/prototype/onvos/objects/`, body);
  }

  public updateOnvosObjectByID(id: string, body: any = {}): Observable<any> {
    delete body.id;
    delete body.updated_at;
    delete body.created_at;
    delete body.service;

    return this.http.post(`/api/prototype/onvos/objects/${id}`, body);
  }

  public deleteOnvosObjectByID(id: string): Observable<any> {
    if (confirm('Вы действительно хотите удалить?')) {
      return this.http.delete(`/api/prototype/onvos/objects/${id}`).pipe(tap(() => this.changeOnvosTable$.next(null)));
    } else {
      return of(null);
    }
  }

  // OTHER
  public downloadFile(uri: string, fileName: string): void {
    this.loadingService.createSpinner();

    this.http.get(uri, { responseType: 'blob' }).subscribe(
      blob => {
        saveAs(blob, fileName);
      },
      error => {},
      () => {
        this.loadingService.dismissSpinner();
      },
    );
  }

  public applicationVersion() {
    return this.http.get('/api/version');
  }

  public sentMessage(body: IMessage): Observable<any> {
    return this.http.post(`/api/auth/messages`, body);
  }

  public fetchSentMessages(): Observable<any> {
    return this.http.get(`/api/auth/messages/sent`);
  }

  public fetchReceivedMessages(): Observable<any> {
    return this.http.get(`/api/auth/messages/received`);
  }

  public getBarcode(value: string): Observable<any> {
    return this.http.get(`/api/common/bar-code?code=${value}`, { responseType: 'blob' }).pipe(switchMap(data => createImageFromBlob(data)));
  }

  public getQRcode(value: string): Observable<any> {
    return this.http.get(`/api/common/qr-code?code=${value}`, { responseType: 'blob' });
  }

  public fetchNotifications(): Observable<any> {
    return this.http.get(`/api/auth/notifications`);
  }

  public markReadNotifications(arrayOfID: string[]): Observable<any> {
    return this.http.post(`/api/auth/notifications/read`, arrayOfID);
  }

  public fetchDADATAOrganizationInfo(inn: string): Observable<IDADATASuggestion> {
    const d = { query: inn };
    return this.http.post<IDADATAResponse>(`${environment.ADDRESS_SUGGEST_URL}/suggestions/api/4_1/rs/suggest/party`, d).pipe(
      filter(result => !!result && !!result.suggestions && !!result.suggestions.length),
      map((data: IDADATAResponse) => data.suggestions[0]),
    );
  }

  public fetchFAQList(pagination?: { [key: string]: string }): Observable<any> {
    return this.http.get(`/api/svc/support/faq`, { params: this.setHttpParams(pagination) });
  }

  public fetchFAQById(id: number): Observable<IFaq> {
    return this.http.get(`/api/svc/support/faq/${id}`) as Observable<IFaq>;
  }

  // ConsolidateReport (without waste)
  public startConsolidateReportFileCreating(fileUrl, item): Observable<any> {
    return this.http.post(fileUrl, item, {
      responseType: 'blob' as 'json',
    });
  }

  public getConsolidateReport(fileUrl, item): Observable<any> {
    return this.http.post<any>(fileUrl, item, {
      responseType: 'blob' as 'json',
    });
  }

  // ConsolidateWasteReport
  public startConsolidateWasteReportFileCreating(fileUrl, item): Observable<any> {
    return this.http.post(fileUrl, item);
  }

  public getConsolidateWasteReport(fileUrl): Observable<any> {
    return this.http.get<any>(fileUrl, {
      responseType: 'blob' as 'json',
    });
  }

  public postPaymentCharge(id): Observable<any> {
    return this.http.post(`/api/svc/payment-nvos/request/${id}/charge`, {});
  }
}
