import {animate, style, transition, trigger} from '@angular/animations';

export const messageCloseAnimation = trigger('messageCloseAnimation', [
  // route 'enter' transition
  transition(':leave', [

    // styles at start of transition
    style({opacity: 1}),

    // animation and styles at end of transition
    animate('0.8s', style({opacity: 0, height: 0})),
  ]),
]);
