import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENameOfSystem, ENameOfEntity, ENameOfPageLong } from 'src/app/providers/_models/entity.model';

export enum BreadCrumbType {
  number = 'number',
  string = 'string',
}

export interface IBreadCrumb {
  'value': any;
  'type'?: BreadCrumbType;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() crumbs: Array<IBreadCrumb> = [];
  public ENameOfPage = ENameOfPageLong;
  public urlParts;
  public id;
  public breadcrumbs = [];
  public BreadCrumbType = BreadCrumbType;

  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;

    if (!this.crumbs.length && this.id) {
      this.crumbs = [{value: this.id, type: BreadCrumbType.number}];
    }

    this.urlParts = this.route.snapshot.pathFromRoot.reduce((path1, path2) => {
      return path1.concat(path2.url.filter(x => x && x.path).map(x => x.path));
    }, []);

    this.breadcrumbs = [
      {label: 'Главная', path: '/'},
      {label: ENameOfSystem[this.urlParts[1]], path: `/${this.urlParts[0]}/${this.urlParts[1]}`},
      {
        label: ENameOfEntity[this.urlParts[2]],
        path:  `/${this.urlParts[0]}/${this.urlParts[1]}/${this.urlParts[2]}`
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.number) {
    }

  }

  isNumberType(one: IBreadCrumb) {
    return one.type === BreadCrumbType.number || !one.type;
  }
}
