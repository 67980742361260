import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

// custom validator to check that two fields match
export const PasswordConfirmValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const pwd: AbstractControl = control.get('password');
    const pwdConf: AbstractControl = control.get('password_confirmation');

    if (pwd && pwdConf) {
        return (pwd.value === pwdConf.value) ? null : { 'identityRevealed': true };
    }
    return { 'identityRevealed': true };
};
