import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SvcRestService } from './svc.rest.service';
import { catchError, debounceTime, expand, finalize, map, switchMap, takeWhile } from 'rxjs/operators';
import { EReportBuilderNumberCondition, EReportBuilderStringCondition } from '../../interfaces/report-builder.interface';
import { DicItem } from './dictionary.items';
import { IFaqGroupItem, Reports, ReportsType, GosUslugiType, GosUslugi } from '../_models/entity.model';
import { EDictionaries, IDictionaryItem } from 'src/app/interfaces/dictionary.interface';
import {
  REQUEST_STATUS_ACCEPTED,
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_EXPIRED,
  REQUEST_STATUS_IN_APPROVAL,
  REQUEST_STATUS_REVIEW,
  REQUEST_STATUS_REJECTED,
} from 'src/app/constants/request-statuses.constants';
import {
  REASON_OF_NOT_REGISTRATION_OBJECT_BUILDING,
  REASON_OF_NOT_REGISTRATION_DECLARATION_PROCESSING,
  REASON_OF_NOT_REGISTRATION_OBJECT_TRANSFERRED,
  REASON_OF_NOT_REGISTRATION_BEFORE_6_MONTH,
  REASON_OF_NOT_REGISTRATION_CONSERVATION,
  REASON_OF_NOT_REGISTRATION_LIQUIDATION,
  REASON_OF_NOT_REGISTRATION_OTHER,
} from 'src/app/constants/dictionary/reason_of_not_registration.constants';
import { DICTIONARY_YES_NO_NULL_NO, DICTIONARY_YES_NO_NULL_YES } from 'src/app/constants/dictionary/yes_no_null.constants';

const expiryTimeLive = 60; // min
export const DOCUMENT_TYPE_10 = { id: 10, name: 'Утверждающий документ не требуется' };

function fillYears(startYear: number): any[] {
  const dt = new Date();
  const years = [];

  for (let i = startYear; i <= dt.getFullYear(); i++) {
    years.push({ id: i, name: i });
  }

  return years;
}

const EnumItem: {
  [key: string]: { dictionary$: BehaviorSubject<any> };
} = {
  report_years: {
    dictionary$: new BehaviorSubject(fillYears(2016)),
  },
  status_request_id: {
    dictionary$: new BehaviorSubject([
      REQUEST_STATUS_DRAFT,
      REQUEST_STATUS_REVIEW,
      REQUEST_STATUS_IN_APPROVAL,
      REQUEST_STATUS_ACCEPTED,
      REQUEST_STATUS_REJECTED,
      REQUEST_STATUS_EXPIRED,
    ]),
  },
  status_report_id: {
    dictionary$: new BehaviorSubject([REQUEST_STATUS_DRAFT, REQUEST_STATUS_REVIEW, REQUEST_STATUS_ACCEPTED]),
  },
  declaration_document_type: {
    dictionary$: new BehaviorSubject([
      { id: 1, code: 'draft', name: 'Первичный' },
      { id: 2, code: 'in_progress', name: 'Уточненный' },
    ]),
  },
  legal_agent: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Нет' },
      { id: true, name: 'Да' },
    ]),
  },
  non_resident: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Нет' },
      { id: true, name: 'Да' },
    ]),
  },
  nvos_oopt: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Нет' },
      { id: true, name: 'Да' },
    ]),
  },
  gee_oopt: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Нет' },
      { id: true, name: 'Да' },
    ]),
  },
  yes_no: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Нет' },
      { id: true, name: 'Да' },
    ]),
  },
  yes_no_inversion: {
    dictionary$: new BehaviorSubject([
      { id: true, name: 'Нет' },
      { id: false, name: 'Да' },
    ]),
  },
  yes_no_digit: {
    dictionary$: new BehaviorSubject([
      { id: 0, name: 'Нет' },
      { id: 1, name: 'Да' },
    ]),
  },
  yes_no_null: {
    dictionary$: new BehaviorSubject([DICTIONARY_YES_NO_NULL_NO, DICTIONARY_YES_NO_NULL_YES]),
  },
  payed: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Не оплачено' },
      { id: true, name: 'Оплачено' },
    ]),
  },
  from_epgu: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Лично' },
      { id: true, name: 'На Web сайте' },
    ]),
  },
  get_personally: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Заказное письмо' },
      { id: true, name: 'Выдача на руки заявителю' },
    ]),
  },
  report_detail_by_option: {
    dictionary$: new BehaviorSubject([
      { id: 'economic_activity', name: 'Виды экономической деятельности' },
      { id: 'regions', name: 'Территория - регионы' },
      { id: 'areas', name: 'Территория - районы' },
    ]),
  },
  report_detail_1_level: {
    dictionary$: new BehaviorSubject([
      { id: 'danger', name: 'Класс опасности' },
      { id: 'fkkos', name: 'Вид отходов' },
      { id: 'okved', name: 'ОКВЭД (1 ур)' },
      { id: 'okved_2', name: 'ОКВЭД (2 ур)' },
      { id: 'federal_district', name: 'Федеральный округ' },
      { id: 'regions', name: 'Регион' },
      { id: 'municipality', name: 'Район' },
    ]),
  },
  report_detail_2_level: {
    dictionary$: new BehaviorSubject([
      { id: 'danger', name: 'Класс опасности' },
      { id: 'fkkos', name: 'Вид отходов' },
      { id: 'okved', name: 'ОКВЭД (1 ур)' },
      { id: 'okved_2', name: 'ОКВЭД (2 ур)' },
      { id: 'federal_district', name: 'Федеральный округ' },
      { id: 'regions', name: 'Регион' },
      { id: 'municipality', name: 'Район' },
      { id: null, name: '-' },
    ]),
  },
  attachment_number: {
    dictionary$: new BehaviorSubject([
      { id: 'I', name: 'I' },
      { id: 'II', name: 'II' },
      { id: 'III', name: 'III' },
    ]),
  },
  size_okopf: {
    dictionary$: new BehaviorSubject([
      { id: 'I', name: 'I' },
      { id: 'II', name: 'II' },
      { id: 'III', name: 'III' },
    ]),
  },
  unit: {
    dictionary$: new BehaviorSubject([
      { id: 'pc', name: 'Шт.' },
      { id: 'l', name: 'Литр' },
      { id: 't', name: 'Тонна' },
      { id: 'm3', name: 'Кубический метр' },
      { id: 'kg', name: 'Килограмм' },
    ]),
  },
  nvos_type: {
    dictionary$: new BehaviorSubject([
      { id: 'Постановка', name: 'Постановка' },
      { id: 'Актуализация', name: 'Актуализация' },
      { id: 'Корректировка', name: 'Корректировка' },
      { id: 'Удаление', name: 'Удаление' },
    ]),
  },
  expiration_dateless: {
    dictionary$: new BehaviorSubject([
      { id: false, name: 'Бессрочно' },
      { id: true, name: 'Срочно' },
    ]),
  },
  report_select_type: {
    dictionary$: new BehaviorSubject([
      { id: 'sum_data', name: 'Сводные данные' },
      { id: 'balance', name: 'Баланс' },
    ]),
  },
  nvos_object_type: {
    dictionary$: new BehaviorSubject([
      // {id: 'Point', name: 'Точечный'},
      // {id: 'Линейный', name: 'Линейный'},
      { id: 'MultiPolygon', name: 'Область' },
    ]),
  },
  type_stationary_source_emissions: {
    dictionary$: new BehaviorSubject([
      { id: 'Организованный', name: 'Организованный' },
      { id: 'Неорганизованный', name: 'Неорганизованный' },
      { id: 'Точечный', name: 'Точечный' },
      { id: 'Линейный', name: 'Линейный' },
      { id: 'Площадной', name: 'Площадной' },
    ]),
  },
  declared_object_category: {
    dictionary$: new BehaviorSubject([
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
    ]),
  },
  declared_object_category_with_null: {
    dictionary$: new BehaviorSubject([
      { id: null, name: 'Отсутствует' },
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
    ]),
  },
  level_control: {
    dictionary$: new BehaviorSubject([
      { id: 'I', name: 'I' },
      { id: 'II', name: 'II' },
      { id: 'III', name: 'III' },
    ]),
  },
  report_year: {
    dictionary$: new BehaviorSubject([{ id: '2019', name: '2019' }]),
  },
  risk_category: {
    dictionary$: new BehaviorSubject([
      { id: 'Высокая', name: 'Высокая' },
      { id: 'Значительная', name: 'Значительная' },
      { id: 'Не значительная', name: 'Не значительная' },
    ]),
  },
  registry_type: {
    dictionary$: new BehaviorSubject([
      { id: 'Federal', name: 'Федеральный' },
      { id: 'Regional', name: 'Региональный' },
    ]),
  },
  calculation_method_type: {
    dictionary$: new BehaviorSubject([
      { id: 'Агрегирование', name: 'Агрегирование' },
      { id: 'Дифференциация', name: 'Дифференциация' },
    ]),
  },
  document_type: {
    dictionary$: new BehaviorSubject([
      // see PaymentNegativeImpactObjectsComponent.getDocumentTypeOptions
      { id: 1, name: 'Комплексное экологическое разрешение' },
      { id: 2, name: 'Декларация о воздействии на окружающую среду' },
      { id: 3, name: 'Отчет ПЭК (об организации и о результатах осуществления производственного экологического контроля)' },
      { id: 4, name: 'Разрешение на выброс ЗВ в атмосферу стационарными источниками' },
      { id: 5, name: 'Разрешение на сброс ЗВ в окружающую среду (водные объекты)' },
      { id: 6, name: 'Документ об утверждении нормативов образования отходов и лимитов на их размещение' },
      { id: 7, name: 'Реквизиты отчетности об образовании, утилизации, обезвреживании, о размещении отходов' },
      { id: 8, name: 'Расчеты НДВ (для выбросов)' },
      { id: 9, name: 'Расчеты НДС (для стоков)' },
      { id: 0, name: 'Период без утвержденных нормативов' }, // 0 - it's not error
      DOCUMENT_TYPE_10,
    ]),
  },
  coefficient_kot: {
    dictionary$: new BehaviorSubject([
      { id: '1', name: '1' },
      { id: '2', name: '2' },
    ]),
  },
  coefficient_kisp: {
    dictionary$: new BehaviorSubject([
      { id: '0', name: '0' },
      { id: '1', name: '1' },
    ]),
  },
  coefficient_k: {
    dictionary$: new BehaviorSubject([
      { id: '1', name: '1' },
      { id: '120', name: '120' },
    ]),
  },
  coefficient_kvo: {
    dictionary$: new BehaviorSubject([
      { id: '0.5', name: '0.5' },
      { id: '1', name: '1' },
    ]),
  },
  coefficient_kod: {
    dictionary$: new BehaviorSubject([
      { id: '0', name: '0' },
      { id: '1', name: '1' },
    ]),
  },
  coefficient_kpo: {
    dictionary$: new BehaviorSubject([
      { id: '0.3', name: '0.3' },
      { id: '1', name: '1' },
    ]),
  },
  coefficient_kst: {
    dictionary$: new BehaviorSubject([
      { id: '0.33', name: '0.33' },
      { id: '0.49', name: '0.49' },
      { id: '0.5', name: '0.5' },
      { id: '0.67', name: '0.67' },
      { id: '1', name: '1' },
    ]),
  },
  report_builder_number_conditions: {
    dictionary$: new BehaviorSubject<any>([
      { id: EReportBuilderNumberCondition.EQUAL, name: 'Равно' },
      { id: EReportBuilderNumberCondition.NOT_EQUAL, name: 'Не равно' },
      { id: EReportBuilderNumberCondition.GREATER, name: 'Больше' },
      { id: EReportBuilderNumberCondition.LESS, name: 'Меньше' },
      { id: EReportBuilderNumberCondition.GREATER_OR_EQUAL, name: 'Больше или равно' },
      { id: EReportBuilderNumberCondition.LESS_OR_EQUAL, name: 'Меньше или равно' },
    ]),
  },
  report_builder_string_conditions: {
    dictionary$: new BehaviorSubject<any>([
      { id: EReportBuilderStringCondition.EQUAL, name: 'Равно' },
      { id: EReportBuilderStringCondition.CONTAINS, name: 'Текст содержит' },
      { id: EReportBuilderStringCondition.START_WITH, name: 'Текст начинается' },
      { id: EReportBuilderStringCondition.END_WITH, name: 'Текст заканчивается' },
    ]),
  },
  event_groups: {
    dictionary$: new BehaviorSubject<any>([
      { id: 3, name: 'совершенствование технологических процессов (включая переход на другие виды топлива, сырья и др.)' },
      { id: 5, name: 'строительство и ввод в действие новых пылегазоочистных установок и сооружений' },
      { id: 7, name: 'повышение эффективности существующих очистных установок (включая их модернизацию, реконструкцию и ремонт)' },
      { id: 9, name: 'ликвидация источников загрязнения' },
      { id: 11, name: 'перепрофилирование предприятия (цеха, участка) на выпуск другой продукции' },
      { id: 13, name: 'прочие мероприятия' },
    ]),
  },
  reason_of_not_registration: {
    dictionary$: new BehaviorSubject<any>([
      REASON_OF_NOT_REGISTRATION_OBJECT_BUILDING,
      REASON_OF_NOT_REGISTRATION_DECLARATION_PROCESSING,
      REASON_OF_NOT_REGISTRATION_OBJECT_TRANSFERRED,
      REASON_OF_NOT_REGISTRATION_BEFORE_6_MONTH,
      REASON_OF_NOT_REGISTRATION_CONSERVATION,
      REASON_OF_NOT_REGISTRATION_LIQUIDATION,
      REASON_OF_NOT_REGISTRATION_OTHER,
    ]),
  },
  report_types: {
    dictionary$: new BehaviorSubject<any>(
      Object.keys(ReportsType)
        .filter(key => !!Reports[key])
        .map(key => {
          return { id: key, name: Reports[key].title };
        }),
    ),
  },
  request_types: {
    dictionary$: new BehaviorSubject<any>(
      Object.keys(GosUslugiType)
        .filter(key => !!GosUslugi[key])
        .map(key => {
          return { id: key, name: GosUslugi[key].title };
        }),
    ),
  },
};

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(private svcRestService: SvcRestService) {}

  public getDictionary(itemName: string) {
    if (!DicItem[itemName].loading) {
      DicItem[itemName].loading = true;
      const filters = DicItem[itemName].filters || {};
      let value: any = DicItem[itemName].dictionary$.getValue();
      let expiryDate: number = DicItem[itemName].expiryDate;
      if (!value || expiryDate < Date.now()) {
        try {
          const obj = JSON.parse(localStorage.getItem(itemName));
          value = obj.data;
          expiryDate = +obj.expiryDate;
        } catch (err) {
          value = null;
        }
        if (!value || expiryDate < Date.now()) {
          let page = 1;
          let allFinished = false;
          let result = [];

          const loadNewBatch$ = (p: number) => {
            return of(allFinished).pipe(
              switchMap((finished: boolean) => {
                return finished
                  ? throwError('All Finished')
                  : this.svcRestService.fetchDictionary(DicItem[itemName].dictURL, {
                      filters,
                      pagination: { per_page: '50', page: p.toString() },
                    });
              }),
              map(({ data, current_page, last_page }) => {
                result = [...result, ...data];
                allFinished = current_page >= last_page || current_page !== p;
                return of(allFinished);
              }),
              catchError(() => throwError('Finished')),
              finalize(() => {
                expiryDate = Date.now() + expiryTimeLive * 60 * 1000;
                if (result.length) {
                  localStorage.setItem(itemName, JSON.stringify({ data: result, expiryDate }));
                }
                DicItem[itemName].dictionary$.next(result);
                DicItem[itemName].expiryDate = expiryDate;
                DicItem[itemName].loading = false;
              }),
            );
          };

          loadNewBatch$(page)
            .pipe(
              debounceTime(1000),
              expand(() => loadNewBatch$(++page)),
              takeWhile(() => !allFinished),
            )
            .subscribe();
        } else {
          DicItem[itemName].dictionary$.next(value);
          DicItem[itemName].expiryDate = expiryDate;
          DicItem[itemName].loading = false;
        }
      } else {
        DicItem[itemName].loading = false;
      }
    }
    return DicItem[itemName].dictionary$;
  }

  get country_id(): BehaviorSubject<any> {
    return this.getDictionary('country_id');
  }
  get applicants(): BehaviorSubject<any> {
    return this.getDictionary('applicants');
  }
  get importer_country_id(): BehaviorSubject<any> {
    return this.getDictionary('country_id');
  }
  get exporter_country_id(): BehaviorSubject<any> {
    return this.getDictionary('country_id');
  }
  get birth_country_id(): BehaviorSubject<any> {
    return this.getDictionary('country_id');
  }
  get export_country_id(): BehaviorSubject<any> {
    return this.getDictionary('country_id');
  }
  get citizen_country_id(): BehaviorSubject<any> {
    return this.getDictionary('country_id');
  }
  get status_id(): BehaviorSubject<any> {
    return this.getDictionary('status_id');
  }
  get result_document_statuses(): BehaviorSubject<any> {
    return this.getDictionary('result_document_statuses');
  }
  get territory_org_id(): BehaviorSubject<any> {
    return this.getDictionary('territory_org_id');
  }
  get pollutants(): BehaviorSubject<any> {
    return this.getDictionary('pollutants');
  }
  get pollutants_with_taxes(): BehaviorSubject<any> {
    return this.getDictionary(EDictionaries.pollutants_with_taxes);
  }
  get air_pollutants(): BehaviorSubject<any> {
    return this.getDictionary('air_pollutants');
  }
  get air_pollutants_with_taxes(): BehaviorSubject<any> {
    return this.getDictionary(EDictionaries.air_pollutants_with_taxes);
  }
  get region(): BehaviorSubject<any> {
    return this.getDictionary('region');
  }
  get report_template_recultivation(): BehaviorSubject<any> {
    return this.getDictionary('report_template_recultivation');
  }
  get report_template_waste(): BehaviorSubject<any> {
    return this.getDictionary('report_template_waste');
  }
  get report_template_air(): BehaviorSubject<any> {
    return this.getDictionary('report_template_air');
  }
  get region_allowed(): BehaviorSubject<any> {
    return this.getDictionary('region_allowed');
  }
  get doer_id(): BehaviorSubject<any> {
    return this.getDictionary('doer_id');
  }
  get signer_name(): BehaviorSubject<any> {
    return this.getDictionary('doer_id');
  }
  // get form_type_id(): BehaviorSubject<any> { return this.getDictionary('form_type_id'); }
  get cites_target_id(): BehaviorSubject<any> {
    return this.getDictionary('cites_target_id');
  }
  get cites_source_id(): BehaviorSubject<any> {
    return this.getDictionary('cites_source_id');
  }
  get letter_template_id(): BehaviorSubject<any> {
    return this.getDictionary('letter_template_id');
  }
  get template_id(): BehaviorSubject<any> {
    return this.getDictionary('template_id');
  }
  get state_services(): BehaviorSubject<any> {
    return this.getDictionary('state_services');
  }
  get okpds(): BehaviorSubject<any> {
    return this.getDictionary('okpds');
  }
  get okopfs(): BehaviorSubject<any> {
    return this.getDictionary('okopfs');
  }
  get measureUnits(): BehaviorSubject<any> {
    return this.getDictionary('measureUnits');
  }
  get measureUnitsByGroupId_5(): BehaviorSubject<any> {
    return this.getDictionary('measureUnitsByGroupId_5');
  }
  get wasteHazardClasses(): BehaviorSubject<any> {
    return this.getDictionary('wasteHazardClasses');
  }
  get federal_districts(): BehaviorSubject<any> {
    return this.getDictionary('federal_districts');
  }
  get onv_not_registered_reasons(): BehaviorSubject<any> {
    return this.getDictionary('onv_not_registered_reasons');
  }
  get onvos_permitting_document_types(): BehaviorSubject<any> {
    return this.getDictionary('onvos_permitting_document_types');
  }
  get faq_group(): Observable<any> {
    return this.getDictionary('faq_group').pipe(
      map(items => {
        items = items || [];
        const sourceFaqGroup = items as IFaqGroupItem[];

        return sourceFaqGroup.map(group => {
          return {
            id: group.id,
            name: group.topic,
          };
        }) as IDictionaryItem[];
      }),
    );
  }

  get wasteHazardClasses_declaration(): Observable<any> {
    return this.getDictionary(EDictionaries.wasteHazardClasses).pipe(
      map(items => {
        if (!items) {
          return items;
        } else {
          return (items as any[]).map(item => {
            if (item.name === 'V') {
              return {
                ...item,
                name: '',
              };
            } else {
              return item;
            }
          });
        }
      }),
    );
  }

  get wasteHazardClasses_env_air(): Observable<any> {
    return this.getDictionary(EDictionaries.wasteHazardClasses).pipe(
      map(items => {
        let dictItems = [{ id: null, name: '-' }];

        dictItems = dictItems.concat((items || []).filter(item => ['I', 'II', 'III', 'IV'].indexOf(item.name) > -1));

        return dictItems;
      }),
    );
  }

  get wasteHazardClasses_env(): Observable<any> {
    return this.getDictionary(EDictionaries.wasteHazardClasses).pipe(
      map(items => {
        let dictItems = [];

        dictItems = dictItems.concat((items || []).filter(item => ['I', 'II', 'III', 'IV', 'V'].indexOf(item.name) > -1));

        return dictItems;
      }),
    );
  }

  get heatEnergyTypes(): BehaviorSubject<any> {
    return this.getDictionary('heatEnergyTypes');
  }
  get fkkos(): BehaviorSubject<any> {
    return this.getDictionary(EDictionaries.fkkos);
  }

  get payed(): BehaviorSubject<any> {
    return EnumItem.payed.dictionary$;
  }
  get legal_agent(): BehaviorSubject<any> {
    return EnumItem.legal_agent.dictionary$;
  }
  get non_resident(): BehaviorSubject<any> {
    return EnumItem.non_resident.dictionary$;
  }
  get from_epgu(): BehaviorSubject<any> {
    return EnumItem.from_epgu.dictionary$;
  }
  get get_personally(): BehaviorSubject<any> {
    return EnumItem.get_personally.dictionary$;
  }
  get report_detail_by_option(): BehaviorSubject<any> {
    return EnumItem.report_detail_by_option.dictionary$;
  }
  get report_select_type(): BehaviorSubject<any> {
    return EnumItem.report_select_type.dictionary$;
  }
  get report_detail_1_level(): BehaviorSubject<any> {
    return EnumItem.report_detail_1_level.dictionary$;
  }
  get report_detail_2_level(): BehaviorSubject<any> {
    return EnumItem.report_detail_2_level.dictionary$;
  }
  get attachment_number(): BehaviorSubject<any> {
    return EnumItem.attachment_number.dictionary$;
  }
  get unit(): BehaviorSubject<any> {
    return EnumItem.unit.dictionary$;
  }
  get expiration_dateless(): BehaviorSubject<any> {
    return EnumItem.expiration_dateless.dictionary$;
  }
  get nvos_object_type(): BehaviorSubject<any> {
    return EnumItem.nvos_object_type.dictionary$;
  }
  get size_okopf(): BehaviorSubject<any> {
    return EnumItem.size_okopf.dictionary$;
  }
  get okopfs_code(): BehaviorSubject<any> {
    return EnumItem.okopfs.dictionary$;
  }
  get type_stationary_source_emissions(): BehaviorSubject<any> {
    return EnumItem.type_stationary_source_emissions.dictionary$;
  }
  get nvos_oopt(): BehaviorSubject<any> {
    return EnumItem.nvos_oopt.dictionary$;
  }
  get nvos_type(): BehaviorSubject<any> {
    return EnumItem.nvos_type.dictionary$;
  }
  get gee_oopt(): BehaviorSubject<any> {
    return EnumItem.gee_oopt.dictionary$;
  }
  get yes_no(): BehaviorSubject<any> {
    return EnumItem.yes_no.dictionary$;
  }
  get yes_no_inversion(): BehaviorSubject<any> {
    return EnumItem.yes_no_inversion.dictionary$;
  }
  get yes_no_digit(): BehaviorSubject<any> {
    return EnumItem.yes_no_digit.dictionary$;
  }
  get yes_no_null(): BehaviorSubject<any> {
    return EnumItem.yes_no_null.dictionary$;
  }
  get declared_object_category(): BehaviorSubject<any> {
    return EnumItem.declared_object_category.dictionary$;
  }
  get declared_object_category_with_null(): BehaviorSubject<any> {
    return EnumItem.declared_object_category_with_null.dictionary$;
  }
  get risk_category(): BehaviorSubject<any> {
    return EnumItem.risk_category.dictionary$;
  }
  get level_control(): BehaviorSubject<any> {
    return EnumItem.level_control.dictionary$;
  }
  get report_year(): BehaviorSubject<any> {
    return EnumItem.report_year.dictionary$;
  }
  get registry_type(): BehaviorSubject<any> {
    return EnumItem.registry_type.dictionary$;
  }
  get status_request_id(): BehaviorSubject<any> {
    return EnumItem.status_request_id.dictionary$;
  }
  get status_report_id(): BehaviorSubject<any> {
    return EnumItem.status_report_id.dictionary$;
  }
  get status_report(): BehaviorSubject<any> {
    return EnumItem.status_report.dictionary$;
  }
  get declaration_document_type(): BehaviorSubject<any> {
    return EnumItem.declaration_document_type.dictionary$;
  }
  get event_groups(): BehaviorSubject<any> {
    return EnumItem.event_groups.dictionary$;
  }
  get reason_of_not_registration(): BehaviorSubject<any> {
    return EnumItem.reason_of_not_registration.dictionary$;
  }
  get report_types(): BehaviorSubject<any> {
    return EnumItem.report_types.dictionary$;
  }
  get request_types(): BehaviorSubject<any> {
    return EnumItem.request_types.dictionary$;
  }
  get document_types(): BehaviorSubject<any> {
    return EnumItem.document_type.dictionary$;
  }
  get calculation_method_types(): BehaviorSubject<any> {
    return EnumItem.calculation_method_type.dictionary$;
  }
  get coefficient_kot(): BehaviorSubject<any> {
    return EnumItem.coefficient_kot.dictionary$;
  }
  get coefficient_kisp(): BehaviorSubject<any> {
    return EnumItem.coefficient_kisp.dictionary$;
  }
  get coefficient_k(): BehaviorSubject<any> {
    return EnumItem.coefficient_k.dictionary$;
  }
  get coefficient_kvo(): BehaviorSubject<any> {
    return EnumItem.coefficient_kvo.dictionary$;
  }
  get coefficient_kod(): BehaviorSubject<any> {
    return EnumItem.coefficient_kod.dictionary$;
  }
  get coefficient_kpo(): BehaviorSubject<any> {
    return EnumItem.coefficient_kpo.dictionary$;
  }
  get coefficient_kst(): BehaviorSubject<any> {
    return EnumItem.coefficient_kst.dictionary$;
  }
  get report_years(): BehaviorSubject<any> {
    return EnumItem.report_years.dictionary$;
  }

  public getByID(entity: string, id: string | number | boolean, dictKey?: string): { [key: string]: any } {
    const key = dictKey || entity;
    const a = this[key];
    if (a) {
      const b = a.getValue() || [];
      const find = b.find(item => item.id === id);
      if (!!find) {
        return find;
      }
    }
    return { id: '', name: '-', full_name: '-', description: '-' };
  }
}
